import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import { reloadProfile, showHeader, showFooter, pageTitle, showLoader, reloadAccountsDB, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';

import DepartmentFormSamples from '../../components/popups/DepartmentFormSamples';
import FieldInput from '../../components/FieldInput';
import PasswordInput from '../../components/PasswordInput';
import { blockWordsAndEmailsValidation } from '../../helpers/VerificationUtilities';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';
import MobileSelection from '../../components/MobileSelection';
import NoRecordAvailable from '../../components/common/NoRecordAvailable';

const FormDepartment = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const defaultLocationUtil = Utilities.defaultLocation();

    const defaultFormValues = {
        department_id: 0,
        department_name: '',
        department_email: '',
        department_password: '',
        department_password_confirm: '',
        department_members: '',
        default_message: '',
        default_msg_status: 0,
        form_status: 0,
        form_status_name: '',
        country_code: parseInt(defaultLocationUtil.country_id), //0,
        contact_number: '',
        type: 1
    };

    let companyProfileGlobal = {};

    const formStatusOption = Utilities.formTypes();

    const [listProfiles, setListProfiles] = useState([]);

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [formAction, setFormAction] = useState('add');
    const [showTeamList, setShowTeamList] = useState(false);
    const [companyProfile, setCompanyProfile] = useState([]);
    const [profileDetail, setProfileDetail] = useState([]);
    const [domainName, setDomainName] = useState('');

    const [memberIDs, setMemberIDs] = useState([]);

    const [memberNames, setMemberNames] = useState('');
    const [teamAdmin, setTeamAdmin] = useState([]);

    const [showMessageOption, setShowMessageOption] = useState(false);
    const [showPasswordChecker, setShowPasswordChecker] = useState(false);
    const [showPasswordConfirmChecker, setShowPasswordConfirmChecker] = useState(false);

    const [formStatusHold, setFormStatusHold] = useState({});

    const [showFormPopUp, setShowFormPopUp] = useState(false);
    const handleCloseFormPopUp = () => setShowFormPopUp(false);


    const [countries, setCountries] = useState([]);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0, showPasswordCheckerBool = false, showPasswordConfirmCheckerBool = false;

        if (fieldName === 'department_name') {
            const email_value = Utilities.removeSpecialChar(value);
            formValuesTemp = { ...formValuesTemp, ...{ department_name: value, department_email: email_value } };
        } else if (fieldName === 'department_email') {
            formValuesTemp = { ...formValuesTemp, ...{ department_email: value } };
        } else if (fieldName === 'department_password') {
            showPasswordCheckerBool = true;
            formValuesTemp = { ...formValuesTemp, ...{ department_password: value } };

        } else if (fieldName === 'department_password_confirm') {
            showPasswordConfirmCheckerBool = true;
            formValuesTemp = { ...formValuesTemp, ...{ department_password_confirm: value } };

        } else if (fieldName === 'department_members') {
            formValuesTemp = { ...formValuesTemp, ...{ department_members: value } };
        } else if (fieldName === 'default_message') {
            formValuesTemp = { ...formValuesTemp, ...{ default_message: value } };
        } else if (fieldName === 'default_msg_status') {
            value = parseInt(formValuesTemp.default_msg_status) === 1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ default_msg_status: value } };
        } else if (fieldName === 'contact_number') {

            let contact_number = value.replace(' ', '');
            if (!Utilities.isEmpty(contact_number) && !Utilities.contactNumberValidate(contact_number, 1).status) {
                jQuery('[name="' + fieldName + '"]').closest('.form-group').addClass('error');
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ contact_number: parseInt(value) || '' } };
        }

        if (errorCounter === 0) {
            jQuery('[name="' + fieldName + '"]').closest('.form-group').removeClass('error');
        }

        setShowPasswordChecker(showPasswordCheckerBool);
        setShowPasswordConfirmChecker(showPasswordConfirmCheckerBool);
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeLandline = (passValues, inputName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ country_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }


    const handleFormType = (detail) => {
        const type = parseInt(detail.value);

        if (type === 0) {
            setupFormType(detail);
        } else {
            setFormStatusHold(detail);
            setShowFormPopUp(true);
        }
    }

    const setupFormType = (detail = {}) => {

        let formValuesTemp = formValues;
        let formStatusHoldTemp = formStatusHold;

        if (Object.keys(detail).length > 0) {
            formStatusHoldTemp = detail;
        }

        formValuesTemp = { ...formValuesTemp, ...{ form_status: parseInt(formStatusHoldTemp.value), form_status_name: formStatusHoldTemp.label } };
        setFormValues(formValuesTemp);
        setShowFormPopUp(false);
    }

    const handeResetFormType = () => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ form_status: 0, form_status_name: '' } };
        setFormValues(formValuesTemp);
    }

    const toggleActiveClass = (e, member) => {
        let memberIDsTemp = [...memberIDs];
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        if (parseInt(memberIDsTemp.length) === 0) {
            memberIDsTemp.push(teamAdmin.account_id.toString());
        }

        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
        setMemberIDs(memberIDsTemp);
    }

    const extractName = () => {

        let memberNamesTemp = '';
        if (memberIDs.length > 0) {
            let memberNamesArry = [];
            Object.entries(listProfiles).map(([key, row]) => {
                if (memberIDs.includes(row.id.toString())) {
                    memberNamesArry.push(row.name);
                }
            });

            memberNamesTemp = memberNamesArry.join(', ');
            memberNamesTemp = (memberNamesTemp.length > 30) ? memberNamesTemp.substring(0, 30) + '...' : memberNamesTemp;
        }

        if (memberIDs.length > 2) {
            memberNamesTemp = memberNamesTemp + ' +' + (parseInt(memberIDs.length) - 2);
        }

        setMemberNames(memberNamesTemp);

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ department_members: memberNamesTemp } };
        jQuery('[name="department_members"]').closest('.form-group').removeClass('error');

        setFormValues(formValuesTemp);
        setShowTeamList(false);
    }

    const submitDepartment = (action = '') => {

        if (!Utilities.isEmpty(formValues.contact_number)) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileActivationDepartmentWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => jQuery('[name="contact_number"]').focus() },
                    { label: 'continue', onClick: () => submitDepartmentProper() }
                ],
                onClickOutside: () => jQuery('[name="contact_number"]').focus(),
                onKeypressEscape: () => jQuery('[name="contact_number"]').focus(),
            });

        } else {
            submitDepartmentProper();
        }

    }

    const submitDepartmentProper = async (action = '') => {

        const button_name = 'btn-submit-department';
        Utilities.elementDisabledStatus(button_name, false);

        const formData = new FormData();
        formData.append('company_id', companyProfile.account_id);

        let errorCounter = 0, responseChecker = false;
        const fieldsChecker = ['department_id', 'department_name', 'department_email'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    jQuery('[name="' + key + '"]').closest('.form-group').addClass('error');
                    errorCounter++;
                } else {

                    if (key === 'department_email') {
                        if (Utilities.checkSpecialChar(formValues.department_email)) {
                            jQuery('[name="' + key + '"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        } else {
                            jQuery('[name="' + key + '"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }
                    } else if (['department_name'].includes(key)) {
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if (responseChecker === false) {
                            jQuery('[name="' + key + '"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        } else {
                            jQuery('[name="' + key + '"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }

                    } else {
                        jQuery('[name="' + key + '"]').closest('.form-group').removeClass('error');
                        formData.append(key, value);
                    }
                }
            } else {
                formData.append(key, value);
            }
        });

        if (!Utilities.isEmpty(formValues.department_password)) {

            /* if ( formValues.department_password.length<=5){
                Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                return false;
            } */
            const passwordValidation = Utilities.passwordValidation(formValues.department_password);
            if (passwordValidation.response === false) {
                jQuery('[name="department_password"]').closest('.form-group').addClass('error');
                return false;
            }

            if (formValues.department_password !== formValues.department_password_confirm) {
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame)
                return false;
            }

            formData.append('department_password', formValues.department_password);
        }

        if (!Utilities.isEmpty(formValues.contact_number) && !Utilities.contactNumberValidate(formValues.contact_number)) {
            jQuery('[name="contact_number"]').closest('.form-group').addClass('error');
            errorCounter++;
        }


        if (errorCounter > 0) { return false; }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.department_name);
        wordsRequestArray.push(formValues.department_email);

        Utilities.elementDisabledStatus(button_name, true);
        let responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
            return false;
        }


        if (!Utilities.isEmpty(formValues.default_message)) {
            wordsRequestArray = [];
            wordsRequestArray.push(formValues.default_message);

            Utilities.elementDisabledStatus(button_name, true);
            responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2,3', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_someWords);
                return false;
            }
        }

        // END RESTRICTED WORDS VALIDATION


        formData.append('country_code', formValues.country_code);
        formData.append('contact_number', formValues.contact_number);

        formData.append('domain_name', domainName);
        formData.append('member_ids', memberIDs.join(','));
        formData.append('form_status', formValues.form_status);

        const utcTime = Utilities.currentUTCTime();
        const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());
        formData.append('create_date', curretTime);

        Utilities.elementDisabledStatus(button_name, true);

        axios
            .post(ApiUrls.departmentAddUpdate, formData)
            .then(response => {

                Utilities.elementDisabledStatus(button_name, false);

                if (response.status === 200) {
                    const dataResponse = response.data;

                    if (parseInt(dataResponse.status) === 1) {

                        dispatch(reloadProfile(Date.now()));
                        dispatch(reloadAccountsDB(2));

                        confirmAlert({
                            title: 'Success',
                            message: `Successully ${formAction === 'add' ? 'created' : 'updated'}.`,
                            overlayClassName: 'success',
                            buttons: [
                                {
                                    label: 'Close',
                                    onClick: () => {
                                        if (formAction === 'add') {
                                            history.push(`/${UrlSlugs.settings_manageDepartments}/${btoa(profileDetail.account_id)}`);
                                        }

                                    }
                                }
                            ]
                        });

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                }

            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                Utilities.elementDisabledStatus(button_name, false);
            });

    }

    const resetDepartment = () => {
        setFormValues(defaultFormValues);

        let memberIDsTemp = [];
        memberIDsTemp.push(teamAdmin.account_id.toString());
        setMemberIDs(memberIDsTemp);
        setMemberNames(teamAdmin.name);

        jQuery('#listing-members > li').removeClass('selected');

        jQuery('#form-department-info .form-group').removeClass('error');

    }

    const getMembers = async (companyID) => {

        if (parseInt(companyID) > 0) {
            await axios
                .post(ApiUrls.companyTeamList, { account_id: companyID })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;

                        if (parseInt(dataResponse.status) === 1) {
                            let memberList = [];

                            Object.entries(dataResponse.list).map(([id, memberdetail]) => {
                                memberList = [...memberList, Utilities.formatProfileDetail(memberdetail)];

                                if (memberdetail.hasOwnProperty('team_is_admin')) {
                                    if (parseInt(memberdetail.team_is_admin) === 1) {
                                        setTeamAdmin(Utilities.formatProfileDetail(memberdetail));

                                        const pathname = window.location.pathname;
                                        let segment = pathname.split('/');
                                        segment = segment.filter(Boolean);

                                        const actionName = segment[2].toString().toLocaleLowerCase();

                                        if (actionName === 'add') {
                                            let memberIDsTemp = memberIDs;
                                            memberIDsTemp.push(memberdetail.account_id.toString());

                                            memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
                                            setMemberIDs(memberIDsTemp);
                                            setMemberNames(memberdetail.name);

                                        }
                                    }
                                }

                            });

                            setListProfiles(memberList);
                        }
                    }
                })
                .catch((err) => {
                    setListProfiles([]);
                });
        }

    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wantDeleteDepartment,
            buttons: [
                { label: 'No', onClick: () => { } },
                { label: 'Yes', onClick: () => { deleteDepartment() } }
            ]
        });
    }

    const deleteDepartment = async () => {
        const id = formValues.department_id;
        if (parseInt(id) > 0) {

            dispatch(showLoader(1));

            await axios
                .post(ApiUrls.departmentDelete, { id: id })
                .then(response => {
                    dispatch(showLoader(0));

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        if (parseInt(dataResponse.status) === 1) {

                            dispatch(reloadProfile(Date.now()));
                            dispatch(reloadAccountsDB(2));

                            history.push(`/${UrlSlugs.settings_manageDepartments}/${btoa(profileDetail.account_id)}`);

                        } else {
                            Utilities.messagePopup('error', errorMsg);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));
                });
        }
    }

    const getDepartmentDetail = async (id) => {
        if (parseInt(id) > 0) {
            await axios
                .post(ApiUrls.departmentGetDetail, { id: id })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        if (parseInt(dataResponse.status) === 1) {

                            const dataDetail = dataResponse.detail;
                            const dataMember = dataResponse.member_detail;

                            let department_members = '';
                            department_members = (dataMember.member_names.length > 30) ? dataMember.member_names.substring(0, 30) + '...' : dataMember.member_names;

                            if (dataMember.list.length > 2) {
                                department_members = department_members + ' +' + (parseInt(dataMember.list.length) - 2);
                            }

                            let form_status = dataDetail.hasOwnProperty('form_status') ? (dataDetail.form_status || 0) : 0;
                            let form_status_name = '';

                            Object.entries(formStatusOption).map(([key, row]) => {
                                if (parseInt(row.value) === parseInt(form_status)) {
                                    form_status_name = row.label;
                                }
                            });

                            const country_code = (dataDetail.hasOwnProperty('country_code') && parseInt(dataDetail.country_code || 0) > 1) ? parseInt(dataDetail.country_code || 0) : parseInt(defaultLocationUtil.country_id || 0);

                            const defaultFormValuesTemp = {
                                ...defaultFormValues,
                                department_id: dataDetail.group_id,
                                department_name: dataDetail.department_name,
                                department_email: dataDetail.department_email.split('@')[0],
                                department_password: '',
                                department_password_confirm: '',
                                department_members: department_members,
                                default_message: dataDetail.default_message,
                                default_msg_status: parseInt(dataDetail.default_msg_status || 0),
                                form_status: parseInt(form_status),
                                form_status_name: form_status_name,
                                type: dataDetail.type,
                                country_code: country_code,
                                contact_number: dataDetail.contact_number,
                            };

                            setFormValues(defaultFormValuesTemp);

                            setMemberNames(department_members);
                            setMemberIDs(dataMember.ids.split(','));


                            companyProfileGlobal = { country_code: country_code };
                            getCountries(companyProfileGlobal);


                            if (!Utilities.isEmpty(dataDetail.default_message)) {
                                setShowMessageOption(true);
                            } else {
                                setShowMessageOption(false);
                            }

                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    const setupCurrentDetail = (reqAccountID) => {
        reqAccountID = parseInt(reqAccountID);

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {
                Object.entries(accountsList).map(([acctid, acctdetail]) => {

                    const account_id = parseInt(acctdetail.account_id);
                    if (account_id === reqAccountID) {

                        let accountProfile = Utilities.formatProfileDetail(acctdetail);
                        setProfileDetail(accountProfile);

                        let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                        setCompanyProfile(profileCompanyTemp);
                        companyProfileGlobal = profileCompanyTemp;

                        getMembers(profileCompanyTemp.account_id);

                        const companyDomainRequest = Utilities.isEmpty(profileCompanyTemp.company_display_name) ? profileCompanyTemp.company_name : profileCompanyTemp.company_display_name;
                        let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                        domainNameTemp = domainNameTemp.toString().toLowerCase();

                        setDomainName(domainNameTemp);

                    }
                });

            }

        }

    }

    const toggleDefaultMessage = () => {

        setTimeout(() => {
            jQuery('textarea#default_message').focus();
        }, 50);


        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ default_message: '', default_msg_status: 1 } };
        setFormValues(formValuesTemp);

        setShowMessageOption(!showMessageOption);

    }

    const clearDefaultMessage = () => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ default_message: '', default_msg_status: 0 } };
        setFormValues(formValuesTemp);

    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }


    // COUTNRIES
    const getCountries = async (setupContact = {}) => {


        await axios
            .post(ApiUrls.country, {})
            .then(async response => {
                if (response.status === 200) {
                    let optionList = [];
                    const countriesList = response.data;

                    let country_names = {};
                    Object.entries(countriesList).map(([key, row]) => {
                        optionList = [...optionList, { value: row.id, label: row.country_name }];
                        country_names[row.id] = row.country_name;

                    });

                    //setCountryList(optionList);
                    setCountries(countriesList);
                    //setCountryNames(country_names);
                }
            })
            .catch((err) => {

            });
    }

    const handleClickUsernameInfo = () => {
        Utilities.messagePopup('', TitlesLabels.alertMessages.useThisToLogin);
    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.departments}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        const pathname = window.location.pathname;
        let segment = pathname.split('/');
        segment = segment.filter(Boolean);

        const actionName = segment[2].toString().toLocaleLowerCase();

        let reqID = match.params.id;
        if (typeof reqID !== 'undefined' && actionName === 'edit') {
            reqID = atob(reqID);
            if (parseInt(reqID) > 0) {
                setFormAction('edit');
                getDepartmentDetail(reqID);
            }

        }


        let reqAccountID = match.params.mid;
        if (typeof reqAccountID !== 'undefined') {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if (reqAccountID > 0) {
                setupCurrentDetail(reqAccountID);
            }
        }


        if (actionName === 'add') {
            const locationSession = Utilities.defaultLocation();
            companyProfileGlobal = { country_code: parseInt(locationSession.country_id ?? 0) };
            getCountries(companyProfileGlobal);

        }


    }, []);


    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            <Link to={`/${UrlSlugs.settings_manageDepartments}/${btoa(profileDetail.account_id)}`}>{TitlesLabels.navigations.manageDepartments}</Link>
                            <div>{companyProfile.hasOwnProperty('name') ? companyProfile.name : ''}</div>
                            <div className="current">{formAction} {TitlesLabels.navigations.departments}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            {(formAction === 'edit' && parseInt(formValues.type) !== 0) && <div className="btn-icon btn-trash" onClick={() => confirmDeletion()}></div>}
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container pt-5">

                    <div className={`member-list-wrap ${!showTeamList && 'd-none'}`}>

                        <div className={` ${Object.keys(listProfiles).length === 0 && 'd-none'} `}>

                            <div className='col-md-9 mx-auto'>
                                <ul id="listing-members" className="ul-global-list list-has-check  mt-4 text-lowercase">
                                    {
                                        Object.entries(listProfiles).map(([key, row]) =>
                                            <li key={key} className={`${((memberIDs.includes(row.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClass(e, row)}>
                                                <div className="profile-wrap">
                                                    {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div> */}
                                                    <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                </div>
                                                <div className='line-height-1'>
                                                    <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{row.name}</div>
                                                    <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>

                            <div className="col-md-5 mx-auto  pt-5 mt-5">
                                <div className="button-group-boolean">
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={() => setShowTeamList(false)}>Cancel</button>
                                    <button type="button" className="btn-theme-black text-lowercase button-has-loader" onClick={() => extractName()}>Save</button>
                                </div>
                            </div>

                        </div>

                        <div className={` ${Object.keys(listProfiles).length > 0 && 'd-none'} `}>
                            <NoRecordAvailable style="text-center text-lowercase no-record-label ">{Utilities.labelMemberAvailable}</NoRecordAvailable>

                            <div className="col-md-4 mx-auto  pt-5 mt-5">
                                <button type="button" className="btn-theme-black text-lowercase" onClick={() => setShowTeamList(false)}>Cancel</button>
                            </div>
                        </div>


                    </div>

                    <div className={`member-list-wrap ${showTeamList && 'd-none'}`} >

                        <form id="form-department-info" className="pt-1 text-lowercase form-theme" autoComplete="off">
                            <input type="hidden" name="department_id" value={formValues.department_id} onChange={(e) => pushValue(e, 'department_id')} autoComplete="off" ></input>

                            <div className={`col-md-9 mx-auto`}>

                                <div className="upload-avatar avatar-wrap pb-5 ">
                                    <div>
                                        {/* <div className={`avatar-preview logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyProfile.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg( companyProfile.logo ) }}>{ Utilities.showHideInitial(companyProfile.logo, companyProfile.company_name) }</div> */}
                                        <CommonProfileLogo classAttr={`avatar-preview logo-size-200 logo-size-sm-200 mx-auto`} profileDetail={{ ...companyProfile, img: companyProfile.logo, name: companyProfile.company_name }} />
                                    </div>
                                </div>

                                <div className="form-col-2 gap-none">

                                    <div className="cursor-notallow">
                                        {
                                            parseInt(formValues.type) === 0 ?
                                                <div className="form-group active">
                                                    <div className="form-control">{formValues.department_name}</div>
                                                    <label className="form-label">Department Name</label>
                                                </div>
                                                :
                                                <FieldInput inputName="department_name" inputLabel="Department Name" inputClass={parseInt(formValues.type) === 0 ? 'cursor-notallow' : ''} inputValue={formValues.department_name} pushValue={pushValue} checkerType="general" />
                                        }

                                    </div>


                                    <div className="form-group optional">
                                        <input type="text" name="department_email" className="form-control text-lowercase cursor-notallow" readOnly placeholder=" " value={formValues.department_email} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)} onChange={(e) => pushValue(e, 'department_email')} autoComplete="new-password" ></input>
                                        <label className="form-label">Department Email</label>
                                        <div className="optional-label d-flex">@{domainName} <span className="input-label-icons cursor-pointer info-bolder-icon" onClick={() => handleClickUsernameInfo()}></span></div>
                                    </div>

                                    <PasswordInput inputName="department_password" inputLabel="Password" inputValue={formValues.department_password} pushValue={pushValue} showChecker={showPasswordChecker} />

                                    <PasswordInput inputName="department_password_confirm" inputLabel="Confirm Password" inputValue={formValues.department_password_confirm} pushValue={pushValue} showChecker={showPasswordConfirmChecker} />

                                    <div className="form-group optional" onClick={() => setShowTeamList(true)}>
                                        <input type="text" name="department_members" className="form-control text-lowercase" placeholder=" " value={memberNames} onChange={(e) => pushValue(e, 'department_members')} autoComplete="off" readOnly ></input>
                                        <label className="form-label">Assign To</label>
                                        <div className="optional-label cursor-pointer">Add/Delete</div>
                                    </div>


                                    {
                                        parseInt(formValues.type) !== 0 &&
                                        <div className={`form-group from-group-input ${!Utilities.isEmpty(formValues.form_status_name) ? 'optional active' : ''}`}>

                                            <Dropdown className="dropdown-toggle-clean dropdown-with-check">
                                                <Dropdown.Toggle variant="dropdown-icon" className="w-100 text-left">{formValues.form_status_name}</Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-clean-option w-100">
                                                    {
                                                        Object.entries(formStatusOption).map(([key, row]) =>
                                                            parseInt(row.value) !== 3 && <Dropdown.Item key={key} onClick={() => handleFormType(row)} className={parseInt(row.value) === parseInt(formValues.form_status) ? 'selected' : ''}>{row.label}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>

                                            </Dropdown>

                                            <label className="form-label">System Form</label>
                                            {!Utilities.isEmpty(formValues.form_status_name) && <div className="optional-label cursor-pointer" onClick={() => handeResetFormType()}>remove</div>}
                                        </div>
                                    }

                                    <div>
                                        <MobileSelection inputValue={formValues.contact_number} countryListProvided={countries} inputLabel="Direct department number" countryCodeProvided={formValues.country_code} inputListingId="landline-input-list" inputName="contact_number" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeLandline} />
                                    </div>


                                    <div>
                                        <div className="form-group active create-default-message has-off-on">

                                            <label className="form-label">create default message</label>
                                            <div className="btn-create-message cursor-pointer form-control" onClick={() => toggleDefaultMessage()}>view</div>

                                            <input type="checkbox" className="checkbox-onoff" value={formValues.default_msg_status} checked={formValues.default_msg_status === 1} onChange={(e) => pushValue(e, 'default_msg_status')} ></input>

                                        </div>

                                        <div className='form-group border-0'>
                                            {showMessageOption && <textarea id="default_message" name="default_message" className="form-control text-tranform-initial bg-gray-important" rows="6" placeholder=" " value={formValues.default_message} onChange={(e) => pushValue(e, 'default_message')}></textarea>}
                                        </div>

                                        {showMessageOption && <div className="mt-n2"><span className="btn-toggle-message cursor-pointer fs-14-important color-theme-green" onClick={() => clearDefaultMessage()}>clear</span></div>}

                                    </div>

                                </div>
                            </div>

                            <div className="col-md-5 mx-auto my-5">
                                {
                                    formAction === 'add' ?
                                        <div className="button-group-boolean mt-3">
                                            <button type="button" className="btn-theme-black text-lowercase" onClick={() => resetDepartment()}>Reset</button>
                                            <button type="button" id="btn-submit-department" className="btn-theme-black text-lowercase button-has-loader" onClick={() => submitDepartment('add')}>Add</button>
                                        </div>
                                        :
                                        <div className="mt-3">
                                            <button type="button" id="btn-submit-department" className="btn-theme-black text-lowercase button-has-loader" onClick={() => submitDepartment('edit')}>Update</button>

                                        </div>
                                }

                            </div>

                        </form>
                    </div>


                </div>
            </div>

            <DepartmentFormSamples handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} formTypeDetail={formStatusHold} setupFormType={setupFormType} />

        </div>
    )
}

export default FormDepartment;