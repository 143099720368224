import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import moment from 'moment';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getNotificationList } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';

import HeaderDailyGlanceGrey from '../../DailyGlance/HeaderDailyGlanceGrey';
import { checkingFollowDailyGlanceStatus, dailyGlanceFollowingCompaniesIds, getListDates } from '../../../helpers/QueryDailyGlance';
import SessionUtilities from '../../../helpers/SessionUtilities';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';


const NotificationList = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [selectedDetail, setSelectedDetail] = useState({});
    const [noticeListing, setNoticeListing] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [showDailyGlanceHeader, setShowDailyGlanceHeader] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getNotifications = async (profile = {}) => {
        //const localDate = moment().format(Utilities.dateTimeServerStr.toString());
        const passval = {
            api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
            account_id: profile.account_id.toString(),
            //date_time: localDate.toString()
        };

        setShowProcessing(true);
        const noticeListResponse = await getNotificationList(passval);
        setShowProcessing(false);

        if (Object.keys(noticeListResponse).length === 0) {
            //Utilities.messagePopup('error', errorMsg );
            console.log(`error: ${errorMsg}`);
            return false;
        }

        if (parseInt(noticeListResponse.success) === 1) {

        }

        if (parseInt(noticeListResponse.success) === 1) {
            setNoticeListing(noticeListResponse.result.beonchat_home_notification.notification_list ?? []);
        } else {
            Utilities.messagePopup('error', noticeListResponse.errorMessage);
        }


    }

    const handleClickSelected = (detail = {}) => {
        setSelectedDetail(detail);
    }

    const listingHtml = () => {
        return <>
            {
                showProcessing &&
                <ul className="ul-global-list">
                    {
                        [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                            <li key={key} className="global-preloader  py-2">
                                <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                <div>
                                    <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                    <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className="bg-gray col-8 pt-3 border-radius-loader"></div>
                                </div>
                            </li>
                        )
                    }
                </ul>
            }

            {
                !showProcessing &&
                <>
                    {
                        Object.keys(noticeListing ?? []).length === 0 ?
                            <NoRecordAvailable>{TitlesLabels.general.noRecordAvailable}</NoRecordAvailable>
                            :
                            <ul id="listing-profiles" className="ul-global-list max-scroll cursor-pointer text-lowercase font-gotham-book fs-16 fs-md-16">
                                {
                                    Object.entries(noticeListing ?? []).map(([key, row]) =>
                                        <li key={key} onClick={() => handleClickSelected(row)} data-keyword={row.company_name} >
                                            <div>
                                                {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.company_logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.company_logo)  }} onClick={ () => previewProfileImage(row.company_logo) }>{ Utilities.showHideInitial(row.company_logo, row.company_name) }</div> */}
                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={{ ...row, img: row.company_logo, name: row.company_name }} />
                                            </div>
                                            <div className='line-height-1'>
                                                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1" data-name={row.company_name}>{row.company_name}</div>
                                                <div className="profile-label  color-theme-grey" data-title={row.company_name}><div className=" ellipsis-1 col-6 px-0">{row.title}</div></div>
                                            </div>
                                            <div className="text-right color-theme-grey  fs-16">{Utilities.formatDateList(row.create_date)}</div>
                                        </li>
                                    )
                                }
                            </ul>
                    }
                </>
            }
        </>
    }

    const selectedDetailHtml = () => {

        if (Object.keys(selectedDetail).length === 0) { return false; }

        return <div className="row form-theme text-lowercase pt-5">

            <div className="col-md-6">
                <div className="global-image-preview bg-gray" style={{ backgroundImage: `url(${selectedDetail.image_path})`, height: 350 }}></div>
            </div>

            <div className='col-md-6 '>
                <div className="form-group active">
                    <label className="form-label">title</label>
                    <div className="form-control height-inherit">{selectedDetail.title}</div>
                </div>

                <div className="form-group active border-0">
                    <label className="form-label">description</label>
                    <div className="form-control height-inherit">{selectedDetail.description}</div>
                </div>
            </div>
        </div>
    }



    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.navigations.notifications}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


        const userAccountDetailTemp = PagesAuthentication.getMasterAccountDetail();
        await getNotifications(userAccountDetailTemp);

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (glanceStatus) {
            setShowDailyGlanceHeader(true);
        }

    }, []);

    return (
        <div className="notification-management">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className={`${Object.keys(selectedDetail).length === 0 ? 'current' : 'cursor-pointer'}`} onClick={() => setSelectedDetail({})}>{TitlesLabels.navigations.notifications}</div>
                            {Object.keys(selectedDetail).length > 0 && <div className="current">{selectedDetail.company_name}</div>}

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(noticeListing).length > 0 && <div className="btn-icon btn-magnify" onClick={() => toggleSeachBox()} title={TitlesLabels.general.search}></div>
                            }
                            <Link to={`/${UrlSlugs.settings_notificationsFollowSettings}`} className="btn-icon btn-comments-plus" title={TitlesLabels.general.followSettings} ></Link>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>


            {showDailyGlanceHeader && <HeaderDailyGlanceGrey />}

            <div className="main-content">

                {
                    Object.keys(noticeListing ?? []).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap d-none  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                                </div>
                                {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                            </div>

                        </div>
                    </div>
                }


                <div className="container pt-4  px-5 pb-5">

                    {Object.keys(selectedDetail).length > 0 ? selectedDetailHtml() : listingHtml()}


                </div>
            </div>
        </div>
    )
}

export default NotificationList;