import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';

import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, passDocumentDetail, passDocumentId  } from '../../actions';
import NoRecordAvailable from '../../components/common/NoRecordAvailable';

const ConversationProfiles = ({match}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;

    const [ listProfiles, setListProfiles ] = useState([]);
    const [ loggedInProfile, setLoggedInProfile ] = useState({});
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ documentRequestedID, setDocumentRequestedID ] = useState(0);
    const [ allowNewChat, setAllowNewChat ] = useState(true);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }


    const toggleMainSearch = ( action = 0 ) => {
        //dispatch( showMainSearch(action) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(action)}`);

        if ( parseInt(documentRequestedID)>0 ){
            dispatch( passDocumentId( documentRequestedID.toString() ) );
        }
    }


    const noConversation = () => {
        setContentLoaderClass('');
        setListProfiles([]);
        setShowEmpty(true);
    }

    const getRecentConversation = ( account ) => {

        setContentLoaderClass('animate');

        let master_account_id = account.account_id;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);
            accountsList = Object.values(accountsList);
            master_account_id = accountsList[0].account_id;
        }
        
        let flag = '1';
        let account_department_id = '0';
        if ( account.hasOwnProperty('group_id') ){
            account_department_id = account.group_id.toString();
            flag = '4';
        }

        const passValues = { 
            api_token : TOKEN.toString(),
            account_id : account.account_id.toString(),
            account_type : account.account_type.toString(),
            master_account_id : master_account_id.toString(),
            flag : flag.toString(),
            group_id : account_department_id.toString(),
       }

        axios
        .post( ApiUrls.bizchatConversationList  , passValues )
        .then( response => {
            
            setContentLoaderClass('');

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){
                    
                    const conversationResult = response.data.result;

                    if ( Object.keys(conversationResult.conversation_list ?? {}).length>0 ){

                        let conversationListingTemp = [];
                        Object.entries(conversationResult.conversation_list).map( ([key, row ]) => {
 
                            row = { ...row, name : row.name.toLowerCase() };
                            let converDetail = row;

                            if ( converDetail.hasOwnProperty('department_name') && !Utilities.isEmpty(converDetail.department_name) ){
                                if ( converDetail.name.toLowerCase()===converDetail.title.toLowerCase() ){
                                    converDetail = { ...converDetail , name : row.name.toLowerCase(), title : row.department_name.toLowerCase()  };
                                }
                            }

                            conversationListingTemp.push(converDetail);
                        });
                        
                        setListProfiles(conversationListingTemp);

                    }else{
                        noConversation();
                    }
                    
                }else{
                    noConversation();
                }
            }else{
                noConversation();
            }

        })
        .catch( (err) => {
            noConversation();
        }); 

    }


    const setupProfile = ( detail = {} ) => {

        if ( parseInt(documentRequestedID)>0 ){
            const passValues = {
                account_id : parseInt(detail.account_id),
                convers_detail : detail,
                document_id : parseInt(documentRequestedID)
            }
            dispatch( passDocumentDetail( passValues ) );
        }
    }


    useEffect( () => {

        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle( TitlesLabels.siteTitles.sendDocument ) );
        dispatch( showHeader(1) );
        dispatch( showFooter(0) );
        
        let docID = match.params.docid;
        if ( typeof docID!=='undefined' ){
            let  documentId = atob(docID);
            setDocumentRequestedID(documentId);

        }else{
            history.push(`/${UrlSlugs.dashboard}`);
        }

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setLoggedInProfile(profileLoggedinTemp);
                getRecentConversation(profileLoggedinTemp);

                // RESTRICT NEW CHAT / REMOVE
                if ( profileLoggedinTemp.hasOwnProperty('flag_handle_status') ){
                    if ( parseInt(profileLoggedinTemp.flag_handle_status)===0 ){
                        setAllowNewChat(false);
                    }
                }else{
                    setAllowNewChat(true);
                } 
            }
        
        }

    }, []);


    return (
        <div className="document-send-page team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.navigations.sendDocument }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() }></div>
                            {  
                                allowNewChat===true && 
                                    <div className="btn-icon btn-contact"  onClick={ () => toggleMainSearch(3) }></div>
                            }
                         </li>
                     </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container">

                        <div className="col-9 mx-auto">

                            <div className="profile-search-wrap d-none">
                                <div className="profile-search-content">
                                    <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                    <div></div>
                                    <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                    <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                </div>
                            </div>
                            
                            { 
                                ! Utilities.isEmpty(contentLoaderClass) &&
                                    <ul className="listing-profiles-preloader item-four mt-4">
                                    {
                                        [...Array(12).keys()].map( ( key ) =>
                                            <li key={key}>
                                                <div className="profile-image"></div>
                                                <div></div>
                                                <div></div>
                                            </li>
                                        )
                                    }
                                    </ul>
                            }

                            {
                                Utilities.isEmpty(contentLoaderClass) &&
                                    showEmpty===false ?
                                        <ul id="listing-profiles" className="listing-profiles item-four mt-4">
                                            {
                                                 Object.entries(listProfiles).map( ([key, row] ) =>
                                                    <li key={key} data-keyword={row.name.toLowerCase()} onClick={ () => setupProfile(row) } >
                                                        <div className="profile-wrap">
                                                            <div className="profile-image-wrap">
                                                                <div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div>
                                                            </div>
                                                            <div className="font-gotham-book-14 ellipsis-1 notranslate" data-name={row.name}>{row.name}</div>
                                                            <div className="profile-label ellipsis-1">{row.title}</div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    :
                                        <NoRecordAvailable style="text-center text-lowercase no-record-label no-chats-label">{ Utilities.labelProfileAvailable }</NoRecordAvailable>
                            }
                            
                            
                        </div>

                </div>
            </div>

       </div>
    )

   
}

export default ConversationProfiles;