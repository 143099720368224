import axios from 'axios';
import ApiUrls from './ApiUrls';


export const glanceQuizQuestions = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {

            //console.log(ApiUrls.glanceQuizQuestions, passval);
            const responseURL = await axios.post(ApiUrls.glanceQuizQuestions, passval);
            //console.log(responseURL);
            if (parseInt(responseURL.status) === 201 && parseInt(responseURL.data.success ?? 0) === 1) {
                responseDetail = responseURL.data.result.glance_quiz_details ?? {};
            }

        } catch (e) {
            console.log(e);
        }
    }

    return responseDetail;
}



export const glanceQuizSubmitAnswer = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            
           //console.log(ApiUrls.glanceQuizQuestions, passval);
           const responseURL = await axios.post(ApiUrls.glanceQuizParticipate, passval);
           //console.log(responseURL);
           if (parseInt(responseURL.status) === 201 && parseInt(responseURL.data.success ?? 0) === 1) {
               responseDetail = responseURL.data.result.glance_quiz_details ?? {};
           }


        } catch (e) {
            console.log(e);
        }
    }

    return responseDetail;
}