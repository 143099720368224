import axios from 'axios';
import ApiUrls from './ApiUrls';
import PagesAuthentication from './PagesAuthentication';
import SessionUtilities from './SessionUtilities';


const dailyGlanceCompanyId = process.env.REACT_APP_notification_followed_company_id ?? '0';



export const checkingFollowDailyGlanceStatus = async (companyIdParam = 0) => {
    companyIdParam = parseInt(companyIdParam ?? 0);
    let glanceStatus = false;

    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
    const result_Db = await dailyGlanceFollowingCompaniesIds({ account_id: userLoginDetail.account_id ?? 0 });

    let companyId = dailyGlanceCompanyId.toString();
    if (companyIdParam > 0) {
        companyId = companyIdParam;
    }

    if (parseInt(result_Db.status ?? 0) === 1 && parseInt(result_Db.count ?? 0) > 0) {
        const companyIdsFollowingArray = result_Db.list ?? [];
        if (companyIdsFollowingArray.includes(companyId.toString())) {
            glanceStatus = true;
        }
    }

    return glanceStatus;

}


export const dailyGlanceFollowingCompaniesIds = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.getDailyGlanceFollowingCompaniesIds, passval);
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
                }
            }

        } catch (e) { }
    }

    return responseDetail;

}

export const dailyGlanceCompaniesExtraDetail = async (passval = {}) => {

    let responseDetail = {};

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.companyOthers, passval);
            if (parseInt(responseURL.status) === 200) {
                //if ( parseInt(responseURL.data.status)===1 ){
                responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
                //}
            }

        } catch (e) { }
    }

    return responseDetail;

}



export const getListDates = async (passval = {}) => {

    let responseDetail = { status: 0 };

    //if ( Object.keys(passval).length>0  ){

    try {
        const responseURL = await axios.post(ApiUrls.getDailyGlanceListDates, passval);
        if (parseInt(responseURL.status) === 200) {
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
            }
        }

    } catch (e) { }
    //}

    return responseDetail;

}

export const getListByDate = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.getDailyGlanceListByDate, passval);
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
                }
            }

        } catch (e) { }
    }

    return responseDetail;

}



export const submitGlanceContacForm = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {

        //console.log('submitGlanceContacForm');
        //console.log(ApiUrls.dailyGlanceContactFormSend);
        //console.log(passval);

        try {
            const responseURL = await axios.post(ApiUrls.dailyGlanceContactFormSend, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data;
            }


        } catch (e) { console.log(e) }
    }

    return responseDetail;
}



export const submitGlanceFeedbackForm = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {

        //console.log('submitGlanceFeedbackForm');
        //console.log(ApiUrls.dailyGlanceFeedbackFormSend);
        //console.log(passval);

        try {
            const responseURL = await axios.post(ApiUrls.dailyGlanceFeedbackFormSend, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data;
            }


        } catch (e) { console.log(e) }
    }

    return responseDetail;
}



export const submitGlanceNewsAttachments = async (formdata) => {
    let responseDetail = { status: 0 };


    try {
        const responseURL = await axios.post(ApiUrls.dailyGlanceFeedbackNewsUploadAttachments, formdata);
        if (parseInt(responseURL.status) === 200) {
            responseDetail = { ...responseDetail, ...responseURL.data };
        }


    } catch (e) { console.log(e) }

    return responseDetail;
}


export const submitGlanceNewsForm = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {

        try {
            //console.log('submitGlanceNewsForm');
            //console.log(ApiUrls.dailyGlanceNewsFormSend);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.dailyGlanceNewsFormSend, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data;
            }


        } catch (e) { console.log(e) }
    }

    return responseDetail;
}


export const submitGlanceEventsForm = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {

        try {
            // console.log('submitGlanceEventsForm');
            // console.log(ApiUrls.dailyGlanceEventsFormSend);
            // console.log(passval);

            const responseURL = await axios.post(ApiUrls.dailyGlanceEventsFormSend, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data;
            }


        } catch (e) { console.log(e) }
    }

    return responseDetail;
}




export const glanceArticleCompanyAds = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            //console.log(ApiUrls.getArticleAds, passval);
            const responseURL = await axios.post(ApiUrls.getArticleAds, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;

}


export const dailyGlancePremiumDailyAds = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            //console.log(ApiUrls.getDailyGlancePremiumDailyAds, passval);
            const responseURL = await axios.post(ApiUrls.getDailyGlancePremiumDailyAds, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;

}


export const dailyGlancePremiumDailyAdsReadStatus = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.getDailyGlancePremiumDailyAdsMarkRead, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;

}


export const glanceNotificationUnreadCounterApi = async (accountId = 0) => {
    //console.log("glanceNotificationUnreadCounterApi");
    accountId = parseInt(accountId ?? 0);
    let counter = 0

    if (accountId > 0) {
        const passval = {
            account_id: accountId.toString()
        }
        const responseDB = await axios.post(ApiUrls.glanceNotificationUnreadCounter, passval);
        // counter = responseDB.data??0;
        // console.log(counter);
    }

    return parseInt(counter);;
}


export const getNotificationListApi = async (accountId = 0) => {
    accountId = parseInt(accountId ?? 0);
    let responseData = [];

    if (accountId > 0) {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: accountId.toString()
        }
        //console.log(ApiUrls.glanceNotificationList, passval);
        const responseURL = await axios.post(ApiUrls.glanceNotificationList, passval);
        //console.log(responseURL);
        if (parseInt(responseURL.data.success ?? 0) === 1) {
            responseData = Object.keys(responseURL.data.result.glance_notification_list).length > 0 ? responseURL.data.result.glance_notification_list : [];
        }

    }

    return responseData;
}


export const getNotificationFeedFlashListApi = async (accountId = 0, groupId = 0) => {
    accountId = parseInt(accountId ?? 0);
    groupId = parseInt(groupId ?? 0);
    let responseData = [];

    if (accountId > 0 && groupId > 0) {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = {
            api_token: TOKEN_SESSION.toString(),
            account_id: accountId.toString(),
            group_id: groupId.toString()
        }
        const responseURL = await axios.post(ApiUrls.glanceNotificationFeedFlashList, passval);
        //console.log(responseURL);
        if (parseInt(responseURL.data.success ?? 0) === 1) {
            responseData = Object.keys(responseURL.data.result.glance_notification_feed_list).length > 0 ? responseURL.data.result.glance_notification_feed_list : [];
        }

    }

    return responseData;
}




export const notificationMaskRead = async (groupId = 0, accountId = 0) => {
    groupId = parseInt(groupId ?? 0);
    accountId = parseInt(accountId ?? 0);
    let status = 0;

    //console.log("notificationMaskRead");

    if (groupId > 0 && accountId > 0) {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: accountId.toString(),
            group_id: groupId.toString()
        }
        //console.log(passval);
        const responseURL = await axios.post(ApiUrls.glanceNotificationListMarkRead, passval);

        if (parseInt(responseURL.data.success ?? 0) === 1) {
            status = 1;
        }
    }

    //console.log(status);
    return status;

}