import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChatUtilities from '../helpers/ChatUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { conversationTeamMembersList } from '../helpers/QueryChatGroups';
import Utilities from '../helpers/Utilities';
import jQuery from 'jquery';
//import UrlSlugs from '../helpers/UrlSlugs';
import ConnectTeamMember from './popups/ConnectTeamMember';
import CommonProfileLogo from './common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';
import QueryUtilities from '../helpers/QueryUtilities';
import UrlSlugs from '../helpers/UrlSlugs';
import NoRecordAvailable from './common/NoRecordAvailable';
import { CicleLoader } from './common/Loaders';


const ChatSettingsCompanyGroupsAuthorisedTeam = (props) => {

    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const conversId = parseInt(props.conversID ?? 0);
    const conversationDetail = ChatUtilities.getAccountConversationDetail(conversId);
    const accessFlag = parseInt(conversationDetail.access_flag ?? 0);
    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);
    const [showEmpty, setShowEmpty] = useState(false);

    const [profileSelected, setProfileSelected] = useState({});
    const [companyProfile, setCompanyProfile] = useState({});

    const [loggedInProfile, setLoggedInProfile] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    const defaultValues = { title: '' };
    const [formValues, setFormValues] = useState(defaultValues);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);

        } else if (fieldName === 'title') {

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }



    const getMembers = async () => {

        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (userLoginDetail.account_id ?? 0).toString(),
            group_id: (conversationDetail.id ?? 0).toString(),
            flag: "1"
        }

        const responseMembers = await conversationTeamMembersList(paramMembers);
        if (Object.entries(responseMembers).length === 0) {
            setShowEmpty(true);
            return false;
        }

        if (parseInt(responseMembers.success) === 0) {
            setShowEmpty(true);
            return false;
        }

        const memberListRes = responseMembers.result.company_group_authorised_team_list.members_list ?? []; //responseMembers.result.conversation_team_members_list.members_list ?? [];
        if (parseInt(memberListRes.length) > 0) {
            setListProfiles(memberListRes);

        } else {
            setShowEmpty(true);
        }


    }

    const handleShow = (profile) => {

        if (parseInt(userLoginDetail.account_id ?? -1) === parseInt(profile.account_id ?? 0)) {
            return false;
        }

        setProfileSelected({
            ...profile,
            logo_url: profile.logo,
            category: profile.title
        });


        if ([3].includes(accessFlag)) {
            setCompanyProfile({
                ...profile,
                name: profile.company_name,
                logo: profile.company_logo,
                category_name: profile.company_category
            });
        } else {
            setCompanyProfile({});
        }

        setShow(true);
    }

    const showChat = async (profile) => {

        const button_name = 'btn-confirm-chat';
        Utilities.elementDisabledStatus(button_name, true);

        const contactRequestValues = ChatUtilities.contactRequest(loggedInProfile, profile, 0, 0);
        const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
        const conversation = conversationDetailRes.result.conversation_details ?? {};

        Utilities.elementDisabledStatus(button_name, false);

        if (Object.entries(conversation).length > 0) {
            let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

            ChatUtilities.saveAccountsConversationDetail(conversation, 'page - chat setting company groups authorized list');
            const urlProvided = `/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`;
            window.location.href = urlProvided;
            //history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);
        }

        //history.push(`/${UrlSlugs.chat}/${btoa( parseInt(profile.account_id) )}`);
    }


    useEffect(() => {
        getMembers();

        const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
        setLoggedInProfile(userLoginDetail ?? {});

    }, []);


    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
            {
                Object.keys(listProfiles).length > 0 ?
                    <>
                        <div className="form-group border-0 bg-white mb-0 border-radius">
                            <input type="text" id="input_search_team" className="form-control text-lowercase bg-gray-important input-search" placeholder=" " autoComplete="off" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} ></input>
                        </div>


                        <ul id="listing-profiles-member" className="list-members company-groups-member-list mt-2 ">
                            {
                                Object.entries(listProfiles).map(([key, row]) =>
                                    <li key={key} data-keyword={row.name.toLowerCase()} className={`${parseInt(userLoginDetail.account_id ?? -1) === parseInt(row.account_id ?? 0) ? 'cursor-notallow' : 'cursor-pointer'}`} onClick={() => handleShow(row)} >
                                        <div>
                                            {/* <div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                            <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                        </div>
                                        <div className='line-height-1-2'>
                                            <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                            <div className="profile-label">{row.title}</div>
                                        </div>
                                        <div className="text-right">
                                            {(parseInt(row.admin_status ?? 0) === 1 && ![3].includes(parseInt(conversationDetail.access_flag ?? 0))) && <span>admin</span>}
                                        </div>
                                    </li>
                                )
                            }
                        </ul>

                        <ConnectTeamMember show={show} handleClose={handleClose} profileSelected={profileSelected} companyProfile={companyProfile} showChat={showChat} />
                    </>
                    :
                    showEmpty === false ? <CicleLoader /> : <NoRecordAvailable style="text-center text-lowercase no-record-label ">{Utilities.labelProfileAvailable}</NoRecordAvailable>
            }
        </div>

    )
}

export default ChatSettingsCompanyGroupsAuthorisedTeam;
