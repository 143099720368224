let siteTitle = process.env.REACT_APP_site_title??'';
siteTitle = siteTitle.toLowerCase();
siteTitle = siteTitle.replaceAll(' ', '-');

let siteUrl = process.env.REACT_APP_site_url;


class UrlSlugs {

    login = `login`
    logout = `logout`
    loginExternal = `https://beonchat.com`
    mainDomainUrl = `https://beonchat.com`

    testinPage = `testingpage`
    uploadLogo = `upload-logo`
    personalInfo = `personal-info`
    personalLogin = `personal-login`
    socialConnect = `social-connect`
    companyInformation = `company-information`

    link_appStore = `https://apps.apple.com/us/app/beonchat/id1626232880`
    link_playStore = `https://play.google.com/store/apps/details?id=com.app.beonchat`
    link_appGallery = `https://appgallery.huawei.com/app/C107748071`

    link_appStorePlay = `http://store.beonchat.com`

    page_directorySearch = `directory-search`
    page_directorySearchDev = `directory-search-dev`
    page_contactSearch = `contact-search`
    page_directoryRegisteredNew = `directory-registered`

    page_termsConditions = `${siteUrl}/help/terms-of-use.php` //`page/terms-conditions`
    page_termsConditionsClean = `${siteUrl}/help/terms-of-use.php`
    page_privacyPolicy = `${siteUrl}/help/privacy-policy.php` //`page/privacy-policy`
    page_disclaimer = `${siteUrl}/help/disclaimer.php`
    page_enduserLicense = `${siteUrl}/help/enduser-licenseagreement.php`
    page_boostCompetition = `${siteUrl}/help/business-boost-competition.php`
    page_siteMap = `https://bizcomplus.com/page/site-map` //`page/site-map`

    page_licenses = `${siteUrl}/help/terms-of-use.php`
    page_licensesClean = `${siteUrl}/help/terms-of-use.php`


    page_helpCenter = `${siteUrl}/help/center.php` //`https://beonchat.com/help-center`
    page_bizcom_about = `${siteUrl}/#why`
    page_bizcom_careers = `https://bizcomplus.com/page/careers`
    page_bizcom_getInTouch = `${siteUrl}/#contact`


    page_beonchat = `https://beonchat.com`
    page_talkToUsUrl = `https://beonchat.com`

    pagePassword_new = `password/new`

    signup_accountType = `signup/account-type`
    signup_persoanlInfo = `signup/personal-info`
    signup_personalCondition = `signup/personal-condition`
    signup_personalLogin = `signup/personal-login`
    signup_verification = `signup/verification`
    signup_uploadLogo = `signup/upload-logo`
    signup_socialConnect = `signup/social-connect`

    signup_companyAccount = `signup/company-account`
    signup_companyInformation = `signup/company-information`
    signup_companyKeywords = `signup/company-keywords`
    signup_companyCondition = `signup/company-condition`
    signup_companyAddress = `signup/company-address`
    signup_companyLogo = `signup/company-logo`
    signup_teamMember = `signup/team-member`


    signup_campaignPersonalInfo = `signup/campaign-personal-info`
    signup_campaignPersonalPassword = `signup/campaign-personal-password`

    signup_campaignPersonalOption = `signup/campaign-personal-option`
    signup_campaignPersonalUser = `signup/campaign-personal-user`


    companyBranch_tabOption = `company-branch`
    companyBranch_informatioDetail = `company-branch/informtion-detail`
    companyBranch_manageBranch = `company-branch/manage-branch`
    companyBranch_companyForm = `company-branches/company-form-branch`


    settings = `settings`
    settings_shareQrCode = `settings/share-qrcode`
    settings_specialMessages = `settings/frequest-message` //`settings/special-messages`
    settings_importantInformation = `settings/important-information`
    settings_linkedDepartments = `settings/linked-departments`
    settings_manageProfile = `settings/manage-profile`
    settings_manageProfileSecurity = `settings/manage-profile-security`
    settings_profileDeactivate = `settings/profile-deactivate`
    settings_linkedProfiles = `settings/linked-profiles`
    settings_linkedProfiles_assign = `settings/linked-profiles/assign`
    settings_divertProfiles_assign = `settings/divert-profiles/assign`
    settings_manageDepartments = `settings/manage-departments`
    settings_department_add = `settings/department/add`
    settings_department_edit = `settings/department/edit`
    settings_manageTeam = `settings/manage-team`
    settings_team_add = `settings/team/add`
    settings_team_edit = `settings/team/edit`
    settings_manageProfileCompany = `settings/manage-profile-company`
    settings_manageWorkProfile = `settings/manage-work-profile`
    settings_manageCompanyGroups = `settings/manage-company-groups`
    settings_manageGotoLinks = `settings/manage-goto-links`
    settings_manageGotoLinksHeadings = `settings/gotolinks/manage-headings`
    settings_manageGotoLinksAddCompany = `settings/gotolinks/add-company`


    settings_bizcomAssist = `settings/${siteTitle}-assist`
    settings_bizcomAssist_termsPrivacy = `settings/${siteTitle}-assist/terms-privacy`
    settings_bizcomAssist_licenses = `settings/${siteTitle}-assist/licenses`

    settings_specialPermissions = `settings/profile-special-permissions`

    settings_followersNotices = `settings/followers-notices`
    settings_notificationManagement = `settings/notification-management`
    settings_notificationSendNotification = `settings/notification-form`

    settings_notificationFollowers = `settings/notification-followers`
    settings_notificationFollowersSuspend = `settings/notification-followers-suspend`
    settings_notificationFollowersRemove = `settings/notification-followers-remove`

    settings_notifications = `settings/notifications`
    settings_notificationsFollowSettings = `settings/notifications-follow-settings`

    deepartment_bulkMessage = `department/bulk-message`

    dashboard = `dashboard`
    dashboardDirectConnect = `dashboard/direct-connect`
    departmentDashboard = `departmentdashboard`
    departmentDashboardDirectConnect = `departmentdashboard/direct-connect`

    chat = `chat`
    chatConversation = `chat-conversation`
    chatGroupsConversation = `chatgroups-conversation`
    switchFromPlus = `switch-from-plus`
    departments = `departments`
    departments_chatViewOnly = `departments/chat-view-only`
    teamMembers = `team-members`
    companyGroups = `company-groups`
    internalChat = `internal-chat`
    documents = `document-library` //`documents`
    sendDocument = `send-document`

    calendarDiary = `appointments-reservations` //`calendar-diary`
    directAccess = `direct-access`
    quickLinksAccess = `quicklinks-access`
    passwordNewReset = `password/new`

    gotoLinks = `goto-links`


    campaign_welcomeMessage = `campaign/welcome`
    campaign_referral = `campaign/referral`
    campaign_downloadApp = `campaign/download-app`
    competition_businessBoost = `competition/business-boost`

    notificationDailyGlance = `daily-glance`
    notificationDailyGlanceArticle = `daily-glance-article`
    notificationDailyGlanceEvents = `daily-glance-events`
    notificationDailyGlanceEventDetail = `daily-glance-event-detail`
    notificationDailyGlanceFeatured = `daily-glance-featured`
    notificationDailyGlanceFeaturedDetail = `daily-glance-featured-detail`
    notificationDailyGlanceSignUps = `daily-glance-signups`

    glanceNotification = `glance-notification`
    glanceQuiz = `glance-quiz`
}


export default new UrlSlugs();

