import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import * as RM from 'ramda';

import ChatUtilities from '../helpers/ChatUtilities';
import Utilities from '../helpers/Utilities';

import { passImageUrl, passQrcodeDetail, passConversDetail as passConversDetailReducer, showLoader } from '../actions';
import QueryChatUtilities from '../helpers/QueryChatUtilities';


import ChatTeamRestrictionRejection from './popups/ChatTeamRestrictionRejection';
import ChatUserCommunicationRejection from './popups/ChatUserCommunicationRejection';
import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { checkMessageIsEligible } from '../helpers/UtilitiesCompanyGroupChats';
import { removeRejectAcceptCompanyCreation } from '../helpers/QueryChatCompanyRequest';
import CompanyCreationRejectPopup from './popups/CompanyCreationRejectPopup';
import { chatWelcomeVideos_links } from '../helpers/Constants';
import VideoPopup from './popups/VideoPopup';
import SessionUtilities from '../helpers/SessionUtilities';
import { CicleLoader } from './common/Loaders';

const ChatConversation = (props) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const defaultProfileLogo = process.env.REACT_APP_default_avatar;
    const sessionLastConversationMessageKey = process.env.REACT_APP_session_last_conversation_message;
    const siteAppTitle = process.env.REACT_APP_site_title
    const imgPath = process.env.REACT_APP_img_folder
    let GLOBAL_RESPONSE = {}, GLOBAL_PASSVAL = {}, GLOBAL_STATUS = 0;

    const isSearch = props.isSearch;
    const ulConversationElem = jQuery('#chat-conversation--history-messages');

    const [conversID, setConversID] = useState(0);
    const [loginAccount, setLoginAccount] = useState({});
    const [workAccount, setWorkAccount] = useState({});
    const [currentConversationDetail, setCurrentConversationDetail] = useState({});
    const [showLoadingMessages, setShowLoadingMessages] = useState(true);
    const [ownerIdConversation, setOwnerIdConversation] = useState(0);
    const [previousConversations, setPreviousConversations] = useState([]);
    const [presentConversations, setPresentConversations] = useState([]);

    const [messagesIdSelected, setMessagesIdSelected] = useState([]);
    const [selectedMessagesDetail, setSelectedMessagesDetail] = useState([]);

    const [showCompanyCreationRejectPopup, setShowCompanyCreationRejectPopup] = useState(false);

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [videoYoutubeCodePopup, setVideoYoutubeCodePopup] = useState('');

    let conversationsDate = [];

    const transferTypesArray = [31, 33];
    const dontShowMessageTypes = [32, 34];

    const excludedOptionArray = [10, 11, 13, 17, 19, 31, 33, 35, 41, 42, 43, 14, 15];
    const multipleOptionArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 15];
    const restrictionMessageTypesArray = [...ChatUtilities.restrictionMessageTypesArray];
    const communicationMessageTypeArray = [41, 42, 43];
    const companyCreationRequestTypesArray = [60, 61, 62, 63];
    const dailyGalnceTypesArray = [65];


    const [messageDataDetail, setmessageDataDetail] = useState({});
    const [showTeamRestrictionRejectPopup, setShowTeamRestrictionRejectPopup] = useState(false);
    const [showUserCommunicationRejectPopup, setShowUserCommunicationRejectPopup] = useState(false);


    const handleCloseVideoPopUp = () => {
        setShowVideoPopup(false);
        setVideoYoutubeCodePopup('');
    }


    const handeFormSubmit = (action = 0, formData = {}, messageData = {}) => {

        formData = { ...formData, action: action.toString() };

        if (action === 0) {
            props.handeFormSubmit({});

        } else if (action === 3) {
            confirmAlert({
                title: '',
                message: `are you sure to wish to cancel ${Utilities.viewFormLabel(formData.type)}?`,
                overlayClassName: '',
                buttons: [
                    { label: 'No', onClick: () => { } },
                    { label: 'yes', onClick: () => { props.handeFormSubmit(formData, {}, messageData); } }
                ]
            });

        } else {
            props.handeFormSubmit(formData, {}, messageData);
        }


    }


    const messageBoxOption = (messageData = {}) => {

        const mainMessage = messageData.message.toString();
        const message_type = parseInt(messageData.message_type);

        if (message_type === 2) {
            if (!Utilities.isEmpty(mainMessage)) {
                dispatch(passImageUrl(mainMessage));
            }
        } else if (message_type === 16) {
            if (!Utilities.isEmpty(mainMessage) && Object.keys(mainMessage).length > 0) {
                dispatch(passQrcodeDetail(messageData));
            }
        }
    }


    const selectMessage = (messageSelectedDetail = {}) => {
        const message_id = parseInt(messageSelectedDetail.doc_id);
        let messagesIdSelectedTemp = messagesIdSelected;

        (message_id > 0 && !(messagesIdSelectedTemp.includes(message_id))) ? messagesIdSelectedTemp.push(message_id) : (messagesIdSelectedTemp = [...Utilities.removeValueInArray(messagesIdSelectedTemp, message_id)]);
        messagesIdSelectedTemp = Utilities.arrayUnique(messagesIdSelectedTemp);

        setMessagesIdSelected(messagesIdSelectedTemp);

        const selectedMessagesDetailTemp = [...selectedMessagesDetail, messageSelectedDetail];
        let selectedMessagesDetailPass = [];

        selectedMessagesDetailTemp.map(detail => {
            const idMsg = parseInt(detail.doc_id);
            if (messagesIdSelectedTemp.includes(idMsg)) {
                selectedMessagesDetailPass.push(detail);
            }
        });

        setSelectedMessagesDetail(selectedMessagesDetailPass);
        props.messagesSelectedDetail(selectedMessagesDetailPass);

    }

    const companyGroupsHtmlContent = (messageData, messageCustom) => {
        let mainMesageHtml = messageCustom.html_message;
        return (
            <>
                <div className={`message-form-title notranslate company-group-status font-gotham-bold fs-16 fs-md-16`} dangerouslySetInnerHTML={{ __html: mainMesageHtml }}></div>
                <div className="form-info-wrap">
                    {messageData.message}

                    <div className="d-flex align-items-center justify-content-center px-4 pt-4">
                        <button type="button" id={`btn-comp-group-access-reject-${messageData.doc_id}`} className="mx-3 btn-circle-reject" onClick={() => props.acceptRejectJoinInvitationCompanyGroups(messageData, 'reject')}><span>reject</span></button>
                        <button type="button" id={`btn-comp-group-access-accept-${messageData.doc_id}`} className="mx-3 btn-circle-accept" onClick={() => props.acceptRejectJoinInvitationCompanyGroups(messageData, 'accept')}><span>accept</span></button>
                    </div>

                </div>
            </>
        )
    }

    const formsHtmlContent = (messageData, messageCustom) => {
        let returnHtml = messageData.message.toString();

        const message_type = parseInt(messageData.message_type);
        const msgFormArray = returnHtml.split('|'); //[ type, detail-json ]

        const currentLoginId = parseInt(loginAccount.account_id);
        const messageAccountId = parseInt(messageData.account_id);

        let class_ttle_array = [];
        if (message_type === 22) {
            class_ttle_array.push('form-status-ammend');
        } else if (message_type === 23) {
            class_ttle_array.push('form-status-success');
        } else if (message_type === 24) {
            class_ttle_array.push('form-status-cancel');
        }

        let messageInfoArray = {};
        let messageInfoListHtml = '';
        let messageInfoButtonsHtml = '';

        if (typeof msgFormArray[1] !== 'undefined') {
            const messageDetailArray = JSON.parse(msgFormArray[1]);
            if (typeof messageDetailArray === 'object') {
                messageInfoArray = messageDetailArray;

                const formType = parseInt(msgFormArray[0] || 0);
                const button_status = parseInt(messageInfoArray.button_status || 0);

                const latestDateDisplay = (messageInfoArray.hasOwnProperty('amendment_date') && !Utilities.isEmpty(messageInfoArray.amendment_date)) ? messageInfoArray.amendment_date : messageInfoArray.date;
                const latestTimeDisplay = (messageInfoArray.hasOwnProperty('amendment_time') && !Utilities.isEmpty(messageInfoArray.amendment_time)) ? messageInfoArray.amendment_time : messageInfoArray.time;

                const oldDateDisplay = (messageInfoArray.hasOwnProperty('amendment_date') && !Utilities.isEmpty(messageInfoArray.amendment_date)) ? messageInfoArray.date : '';
                const oldTimeDisplay = (messageInfoArray.hasOwnProperty('amendment_time') && !Utilities.isEmpty(messageInfoArray.amendment_time)) ? messageInfoArray.time : '';

                if (formType === 1) {
                    messageInfoListHtml = <>
                        <div className="form-info-col">
                            <div>name:</div>
                            <div className="ellipsis-1">{messageInfoArray.name}</div>
                        </div>
                        <div className="form-info-col">
                            <div>date:</div>
                            <div>{Utilities.currentDateFormatted(latestDateDisplay)}</div>
                            {!Utilities.isEmpty(oldDateDisplay) && <div className="label-color-red">{Utilities.currentDateFormatted(oldDateDisplay)}</div>}
                        </div>
                        <div className="form-info-col">
                            <div>time:</div>
                            <div>{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + latestTimeDisplay)}</div>
                            {!Utilities.isEmpty(oldTimeDisplay) && <div className="label-color-red">{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + oldTimeDisplay)}</div>}
                        </div>
                    </>
                } else if (formType === 2) {

                    const latestNoPeopleDisplay = (messageInfoArray.hasOwnProperty('amendment_no_people') && !Utilities.isEmpty(messageInfoArray.amendment_no_people)) ? messageInfoArray.amendment_no_people : messageInfoArray.no_people;
                    const oldNoPeopleDisplay = (messageInfoArray.hasOwnProperty('amendment_no_people') && !Utilities.isEmpty(messageInfoArray.amendment_no_people)) ? messageInfoArray.no_people : '';

                    messageInfoListHtml = <>
                        <div className="form-info-col">
                            <div>date:</div>
                            <div>{Utilities.currentDateFormatted(latestDateDisplay)}</div>
                            {!Utilities.isEmpty(oldDateDisplay) && <div className="label-color-red">{Utilities.currentDateFormatted(oldDateDisplay)}</div>}
                        </div>
                        <div className="form-info-col">
                            <div>time:</div>
                            <div>{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + latestTimeDisplay)}</div>
                            {!Utilities.isEmpty(oldTimeDisplay) && <div className="label-color-red">{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + oldTimeDisplay)}</div>}
                        </div>
                        <div className="form-info-col">
                            <div>seat:</div>
                            <div>{latestNoPeopleDisplay}</div>
                            {!Utilities.isEmpty(oldNoPeopleDisplay) && <div className="label-color-red">{oldNoPeopleDisplay}</div>}
                        </div>
                    </>

                } else if (formType === 3) {
                    messageInfoListHtml = <>
                        <div className="form-info-col">
                            <div>date:</div>
                            <div>{Utilities.currentDateFormatted(latestDateDisplay)}</div>
                            {!Utilities.isEmpty(oldDateDisplay) && <div className="label-color-red">{Utilities.currentDateFormatted(oldDateDisplay)}</div>}
                        </div>
                        <div className="form-info-col">
                            <div>time:</div>
                            <div>{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + latestTimeDisplay)}</div>
                            {!Utilities.isEmpty(oldTimeDisplay) && <div className="label-color-red">{Utilities.returnTimeFormat(messageInfoArray.date + ' ' + oldTimeDisplay)}</div>}
                        </div>
                    </>
                }

                const buttonDefaultHtml = <>
                    <button type="button" id={`btn-submit-form-cancel-${messageData.doc_id}`} className="mx-3  btn-circle-reject" onClick={() => handeFormSubmit(3, messageInfoArray, messageData)}><span>cancel</span></button>
                    <button type="button" id={`btn-view-form-detail-${messageData.doc_id}`} className="mx-3 btn-circle-edit" onClick={() => props.handleViewFormDetail(messageData)}><span>amend</span></button>

                </>

                const buttonTwoOption = <div className="d-flex align-items-center justify-content-center px-4 pt-4">{buttonDefaultHtml}</div>

                const buttonThreeOption = <div className="d-flex align-items-center justify-content-center px-4 pt-4">
                    {buttonDefaultHtml}
                    <button type="button" id={`btn-submit-reply-${messageData.doc_id ?? ''}`} className="mx-3  btn-circle-accept" onClick={() => handeFormSubmit(4, messageInfoArray, messageData)}><span>accept</span></button>
                </div>

                if (formType !== 3 && (button_status !== 3 && button_status !== 0)) {

                    if (currentLoginId === messageAccountId) {

                        if (button_status === 1) {
                            messageInfoButtonsHtml = buttonTwoOption;

                        } else if (button_status === 2) {
                            messageInfoButtonsHtml = buttonThreeOption;
                        }

                    } else {

                        if (button_status === 1) {
                            messageInfoButtonsHtml = buttonThreeOption;

                        } else if (button_status === 2) {
                            messageInfoButtonsHtml = buttonTwoOption;
                        }
                    }

                } else if (formType !== 3 && button_status === 0) {
                    messageInfoButtonsHtml = buttonTwoOption;
                }


                // REMOVE BUTTON OPTION
                if (parseInt(currentConversationDetail.access_status || 0) === 0) {
                    messageInfoButtonsHtml = '';
                }
            }
        }


        let mainMesageHtml = messageCustom.html_message;

        const latestRemarksDisplay = messageInfoArray.hasOwnProperty('amendment_remarks') && !Utilities.isEmpty(messageInfoArray.amendment_remarks) ? messageInfoArray.amendment_remarks : messageInfoArray.remarks;
        const oldRemarksDisplay = messageInfoArray.hasOwnProperty('amendment_remarks') && !Utilities.isEmpty(messageInfoArray.amendment_remarks) ? messageInfoArray.remarks : '';

        returnHtml = <>
            <div className={`message-form-title notranslate message-form-title-${msgFormArray[0]} ${class_ttle_array.join(' ')}`} dangerouslySetInnerHTML={{ __html: mainMesageHtml }}></div>
            {
                Object.entries(messageInfoArray).length > 0 ?
                    <>
                        <div className="form-info-wrap">
                            {!Utilities.isEmpty(messageInfoListHtml) && <div className="pb-2 column-2">{messageInfoListHtml}</div>}
                            {
                                parseInt(msgFormArray[0] || 0) === 3 ?
                                    <>
                                        <div className="form-info-col">
                                            <div>subject:</div>
                                            <div>{messageInfoArray.hasOwnProperty('subject') && messageInfoArray.subject}</div>
                                        </div>

                                        <div className="text-blue-label pt-2">message</div>
                                    </>
                                    :
                                    <div className="text-blue-label">special request</div>
                            }

                            <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(latestRemarksDisplay) }}></div>
                            {!Utilities.isEmpty(oldRemarksDisplay) && <div className="pt-2 label-color-red" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(oldRemarksDisplay) }}></div>}

                            {!Utilities.isEmpty(messageInfoButtonsHtml) && messageInfoButtonsHtml}
                        </div>

                    </>
                    :
                    <div className="text-center pt-3 color-theme-gray form-info-wrap">{TitlesLabels.general.noDetailAvailable}</div>
            }

        </>


        return returnHtml;

    }


    const handleClickGlanceCanceRequest = async (formDetail = {}, msgId = '', isConfirm = 1) => {
        isConfirm = parseInt(isConfirm);

        if (Object.entries(formDetail).length > 0) {

            if (isConfirm === 1) {
                confirmAlert({
                    title: '',
                    message: TitlesLabels.confirmMessages.wishToCancelRequest ?? '',
                    overlayClassName: '',
                    buttons: [
                        { label: 'no', onClick: () => { } },
                        { label: 'yes', onClick: () => handleClickGlanceCanceRequest(formDetail, msgId, 0) }
                    ]
                });

            } else {


                GLOBAL_PASSVAL = {
                    api_token: TOKEN_SESSION.toString(),    // TOKEN_CHAT.toString(),
                    account_id: (loginAccount.account_id ?? 0).toString(),
                    group_id: (formDetail.group_id ?? 0).toString(),
                    convers_id: conversID.toString()
                }

                dispatch(showLoader(1));
                GLOBAL_RESPONSE = await QueryChatUtilities.cancelGlanceRequest(GLOBAL_PASSVAL);
                dispatch(showLoader(0));

                if (Object.keys(GLOBAL_RESPONSE).length === 0) {
                    Utilities.messagePopup('error', errorMsg);
                    return false;
                }

                GLOBAL_STATUS = parseInt(GLOBAL_RESPONSE.success ?? 0);
                if (GLOBAL_STATUS === 0) {
                    Utilities.messagePopup('error', GLOBAL_RESPONSE.errorMessage ?? '');
                    return false;
                }

                Utilities.messagePopup('success', GLOBAL_RESPONSE.successMessage ?? '');

                // REMOVE CANCEL BUTTON
                jQuery(`li#${msgId} .form-info-wrap`).html(ChatUtilities.messageDeletedHtml());
                jQuery(`li#${msgId} .message-wrap`).removeClass('message-glance-request');

            }
        }

    }

    const glanceNewsAndEventsHtml = (key, messageData) => {

        let returnHtml = messageData.message.toString();

        //const message_type = parseInt(messageData.message_type);
        const msgFormArray = returnHtml.split('|');

        const formType = parseInt(msgFormArray[0] ?? 1);
        const formDetail = JSON.parse(msgFormArray[1]);

        const currentLoginId = parseInt(loginAccount.account_id);
        const messageAccountId = parseInt(messageData.account_id);
        const buttonStatus = parseInt(formDetail.button_status ?? 0);
        const formImages = formDetail.image ?? '';


        let awaitingHtml = <></>
        let attachmentHtml = <></>
        let titleForm = 'request recieved';
        let classAttrArray = [];

        if (buttonStatus === 1) {

            awaitingHtml = <div>
                <img src={`${imgPath}/icons/black/circle-hour-glass.svg`} className='waiting-icon' />
                <div>awaiting approval</div>
            </div>

            if (currentLoginId === messageAccountId) {
                titleForm = 'request submitted';
                awaitingHtml = <div className='cursor-pointer' onClick={() => handleClickGlanceCanceRequest(formDetail, messageData.doc_id ?? '')}>
                    <img src={`${imgPath}/icons/red/circle-remove-close.svg`} className='waiting-icon' />
                    <div>cancel</div>
                </div>
            }


            awaitingHtml = <div className='fs-13 fs-md-13 d-flex align-items-center justify-content-center text-center action-button mb-3'>
                {awaitingHtml}
            </div>

        } else if (buttonStatus === 2) {
            titleForm = 'approved';
            classAttrArray.push('text-blue-label');

        } else if (buttonStatus === 3) {
            titleForm = 'rejected';
            classAttrArray.push('text-pink');
        }


        if (!Utilities.isEmpty(formImages)) {

            (formImages.split(',') ?? []).map(image => {
                attachmentHtml = <>{attachmentHtml} <li style={{ backgroundImage: `url('${image}')` }} onClick={() => dispatch(passImageUrl(image))}></li></>
            });

        }


        if (formType === 1) {
            returnHtml = <>
                <div className="form-info-col pb-3">
                    <div className='fs-18'>news no. {formDetail.request_no ?? ''} </div>
                    <div className={`fs-24 font-gotham-bold ${classAttrArray.join(' ')}`}>{titleForm}</div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>title</div>
                    <div>{formDetail.title ?? ''}</div>
                </div>

                <div className='row'>
                    <div className="form-info-col col-6 pb-3">
                        <div className='text-blue-label '>date</div>
                        <div className='fs-18 font-gotham-bold'>
                            {Utilities.currentDateFormatted(formDetail.date ?? '')}
                        </div>
                    </div>

                    <div className="form-info-col col-6 pb-3">
                        <div className='text-blue-label '>time</div>
                        <div className='fs-18 font-gotham-bold'>
                            {Utilities.returnTimeFormat(`${formDetail.date ?? ''} ${formDetail.time ?? ''}`)}
                        </div>
                    </div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>description</div>
                    <div>{formDetail.description ?? ''}</div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>view attachments</div>
                    <ul className='ul-glance-request-attachments mt-2'>{attachmentHtml}</ul>
                </div>


                {awaitingHtml}

            </>
        } else if (formType === 2) {
            returnHtml = <>
                <div className="form-info-col pb-3">
                    <div className='fs-18'>event no. {formDetail.request_no ?? ''} </div>
                    <div className={`fs-24 font-gotham-bold ${classAttrArray.join(' ')}`}>{titleForm}</div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>event name</div>
                    <div>{formDetail.name ?? ''}</div>
                </div>

                <div className='row'>

                    <div className="form-info-col col-6 pb-3">
                        <div className='text-blue-label '>date</div>
                        <div className='fs-18 font-gotham-bold'>
                            {Utilities.currentDateFormatted(formDetail.date ?? '')}
                        </div>
                    </div>

                    <div className="form-info-col col-6 pb-3">
                        <div className='text-blue-label '>time</div>
                        <div className='fs-18 font-gotham-bold'>
                            {Utilities.returnTimeFormat(`${formDetail.date ?? ''} ${formDetail.time ?? ''}`)}
                        </div>
                    </div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>location</div>
                    <div>{formDetail.location ?? ''}</div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>description</div>
                    <div>{formDetail.description ?? ''}</div>
                </div>

                <div className="form-info-col pb-3">
                    <div className='text-blue-label '>view attachments</div>
                    <ul className='ul-glance-request-attachments mt-2'>{attachmentHtml}</ul>
                </div>

                {awaitingHtml}

            </>
        }

        returnHtml = <div className="form-info-wrap line-height-1-3">{returnHtml}</div>

        return returnHtml;
    }



    const toggleMultipleSelection = () => {
        setMessagesIdSelected([])
        setSelectedMessagesDetail([]);
        ulConversationElem.addClass('enable-selection');
    }

    const menuOptionHtml = (messageData) => {

        /* const conversFlag = parseInt(currentConversationDetail.flag || 0);
        if ( conversFlag===6 ){ //remove option if company groups
            return false;
        } */

        // setting last conversation message
        sessionStorage.setItem(
            sessionLastConversationMessageKey,
            JSON.stringify(messageData)
        );

        let returnHtml = '';
        const message_type = parseInt(messageData.message_type);

        const owner_account_id = parseInt(currentConversationDetail.owner_account_id);
        const flag_type = currentConversationDetail.hasOwnProperty('flag') ? parseInt(currentConversationDetail.flag || 0) : 0;

        const deleteOptionHtml = <Dropdown.Item onClick={() => props.deleteMesage(messageData)}>delete</Dropdown.Item>

        if (message_type === 12) {
            if (owner_account_id !== ownerIdConversation) {
                returnHtml = deleteOptionHtml;
            }

        } else if (restrictionMessageTypesArray.includes(message_type)) {
            returnHtml = '';

        } else if (companyCreationRequestTypesArray.includes(message_type)) {
            returnHtml = '';

        } else if (dailyGalnceTypesArray.includes(message_type)) {
            returnHtml = '';

        } else {

            let dropdownItemDownload = '';

            if ([3, 4].includes(message_type)) {
                dropdownItemDownload = <Dropdown.Item href={messageData.message} download >download</Dropdown.Item>
            }

            returnHtml = <Fragment>

                {flag_type !== 5 && <Dropdown.Item onClick={() => props.setupReplyContent(messageData)}>reply</Dropdown.Item>}
                {/* <Dropdown.Item onClick={ () => props.forwardMesageOption( messageData ) }>forward</Dropdown.Item>
                                <Dropdown.Item onClick={ () => props.deleteMesage( messageData ) }>delete</Dropdown.Item> */}
                <Dropdown.Item onClick={() => toggleMultipleSelection()}>forward</Dropdown.Item>
                {dropdownItemDownload}
                <Dropdown.Item onClick={() => toggleMultipleSelection()}>delete</Dropdown.Item>

            </Fragment>

        }

        if (!Utilities.isEmpty(returnHtml)) {
            returnHtml = <Dropdown className="btn-message-option">
                <Dropdown.Toggle variant="dropdown-icon btn-message-option-icon"></Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-chat-option message-option-list">
                    {returnHtml}
                </Dropdown.Menu>
            </Dropdown>
        }

        return returnHtml;
    }

    const requestActionHtml = (messageData) => {

        let returnHtml = '';
        const message_type = parseInt(messageData.message_type);
        const owner_account_id = parseInt(currentConversationDetail.owner_account_id);

        if (message_type === 12) {
            if (owner_account_id === ownerIdConversation) {
                returnHtml = <div className="d-flex align-items-center justify-content-center px-4 pb-4">
                    <button type="button" className="mx-3 btn-circle-reject" onClick={() => props.acceptRejectRequest(2)}><span>reject</span></button>
                    <button type="button" className="mx-3 btn-circle-accept" onClick={() => props.acceptRejectRequest(1)}><span>accept</span></button>
                </div>
            } else {
                returnHtml = <div className="d-flex align-items-center justify-content-center px-4 pb-4">
                    <button type="button" className="mx-3 btn-circle-reject" onClick={() => props.acceptRejectRequest(3)}><span>remove</span></button>
                </div>
            }
        }

        return returnHtml;
    }


    const transferChatMessageHtml = (key, messageData) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = messageData.account_id.toString();
        const message_content = messageData.message.toString();

        let messageContentArray = message_content.split('|');
        messageContentArray = messageContentArray.filter((a) => a);

        const profile_name = messageContentArray[0];
        const transfer_message = messageContentArray[1];
        let transfer_attachment = [];

        if (typeof messageContentArray[2] !== 'undefined') {
            transfer_attachment = JSON.parse(messageContentArray[2]);
        }

        let isInform = 0;
        let transferLabel = 'to';

        if (parseInt(loginAccount.account_type) === 4) {
            const company_id = parseInt(loginAccount.company_detail.account_id);
            if (company_id !== parseInt(message_account_id)) {
                isInform = 1;
            } else {

                if (message_type === 33) {
                    transferLabel = 'from';
                }

            }
        } else {
            isInform = 1;
        }


        return (

            isInform === 1 ?
                <div className="message-item-content text-center pb-4" onClick={() => messageBoxOption(messageData)}>

                    {
                        message_type === 31 ?
                            <Fragment>
                                <div>this matters has been transferred to</div>
                                <div className="font-gotham-bold fs-18 fs-md-18 text-center mb-2">{profile_name}</div>

                            </Fragment>
                            :
                            <Fragment>
                                <div>hi {loginAccount.name},</div>
                                <div className="my-2">please be informed that your correspondence with</div>

                                <div className="font-gotham-bold fs-18 fs-md-18 text-center mb-2">{profile_name}</div>

                                <div>has been transferred to me.<br />i will be in touch shortly.</div>
                            </Fragment>
                    }


                </div>
                :
                <div className="message-item-content" onClick={() => messageBoxOption(messageData)}>
                    <div className="text-center">
                        <div>this chat has been transferred {transferLabel}</div>
                        <div className="font-gotham-bold fs-18 fs-md-18">{profile_name}</div>
                    </div>

                    <div className="my-2 text-center">
                        <div className="mb-2 text-blue-label">message</div>
                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(transfer_message) }}></div>
                    </div>

                    {
                        parseInt(transfer_attachment.length) > 0 &&
                        <ul className={`transfer-attachments`}>
                            {
                                transfer_attachment.map((attachment, key) => (
                                    <li key={key}><a href={attachment.file} target="_blank" className="ellipsis-1">{attachment.name}</a></li>
                                ))
                            }
                        </ul>
                    }

                </div>

        )
    }

    const userCommunicationRejectConfirmation = () => {
        setShowUserCommunicationRejectPopup(showUserCommunicationRejectPopup ? false : true);
    }

    const communicationRequestAcceptReject = async (status = 0, messsage = '') => {
        status = parseInt(status);

        if (status > 0) {

            const button_name = status === 1 ? 'btn-communication-accept' : 'btn-popup-communication-reject'; //'btn-communication-reject';
            //Utilities.elementDisabledStatus(button_name, true );
            dispatch(showLoader(1));

            const passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN_CHAT.toString(),
                account_id: loginAccount.account_id.toString(),
                convers_id: conversID.toString(),
                flag: status.toString(),
                //connect_reason: messsage.toString()
            }

            const responseDB = await QueryChatUtilities.acceptRejectAccessCommunication(passval);

            //Utilities.elementDisabledStatus(button_name, false );
            dispatch(showLoader(0));
            //console.log(responseDB);
            setShowUserCommunicationRejectPopup(false);

            // UPDATE CONVERSATION DETAIL
            //if ( status===1 ){
            const passValues = {
                api_token: TOKEN_SESSION.toString(), // TOKEN_CHAT.toString(),
                account_id: loginAccount.account_id.toString(),
                account_type: loginAccount.account_type.toString(),
                convers_id: conversID.toString(),
                //flag: "1"
            };

            const conversDetail = await QueryChatUtilities.getConversationDetail(passValues);
            if (!RM.isEmpty(conversDetail)) {
                if (parseInt(conversDetail.success) === 1) {
                    const currentConversationDetailTemp = conversDetail.result.conversation_details ?? {};
                    if (Object.entries(currentConversationDetailTemp).length > 0) {
                        ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 6);

                        dispatch(passConversDetailReducer(currentConversationDetailTemp));
                    }
                }
            }

            //}

            jQuery('.message-communication-request-button').remove();
        }
    }


    const companyCreationRequestMessageHtml = (key, messageData) => {

        const message_account_id = parseInt(messageData.account_id ?? 0);
        let [nameOrRequestNo, messageContentValue, messageCompanyName, messageAdditionalName] = (messageData.message || '|').split('|');
        let messageTypeValue = parseInt(messageData.message_type ?? 0), labelNote = '';
        let messageWrapCounterLabel = '';

        let buttonOption = <button type="button" id="btn-creation-cancel" className="mx-3 btn-circle-reject" onClick={() => companyCreationActions(messageData, 3)}><span>cancel</span></button>

        if (message_account_id !== parseInt(loginAccount.account_id)) {
            buttonOption = <>
                <button type="button" id="btn-creation-reject" className="mx-3 btn-circle-reject" onClick={() => companyCreationActions(messageData, -2)}><span>reject</span></button>
                <button type="button" id="btn-creation-accept" className="mx-3 btn-circle-accept" onClick={() => companyCreationActions(messageData, 1)}><span>accept</span></button>
            </>
        }


        // CHANGE ALL REQUEST IF WAS ACCEPTED
        if ([62, 63].includes(messageTypeValue)) {
            setTimeout(() => {
                companyRequestChangeRequestMessage();
            }, 200);

        }

        if ([60, 61, 62, 63].includes(messageTypeValue) && message_account_id !== parseInt(loginAccount.account_id)) {
            if (parseInt(nameOrRequestNo) > 0) {
                messageWrapCounterLabel = <span className='mr-1 request-counter-wrap'>#{nameOrRequestNo}</span>
            }
        }

        if (messageTypeValue === 60) {
            //if ( message_account_id===parseInt(loginAccount.account_id) ){
            labelNote = <div className="label-note-wrap d-flex-center py-1"><div className="label-waiting-reply font-gotham-book fs-16 fs-md-16">{Utilities.labelAwaitingReply}</div></div>
            //}

        } else if (messageTypeValue === 62) {
            labelNote = <div className="label-note-wrap d-flex-center py-1"><div className="label-request-accepted font-gotham-book fs-16 fs-md-16">accepted</div></div>

            ulConversationElem.find(`li div.button-wrap`).remove();


        } else if (messageTypeValue === 63) {
            labelNote = <div className="label-note-wrap d-flex-center py-1"><div className="label-request-rejected font-gotham-book fs-16 fs-md-16">rejected</div></div>
            //messageContentValue = messageData.message;

            ulConversationElem.find(`li div.button-wrap`).remove();
        }



        return <div className={`company-creation-request company-creation-request-${messageTypeValue}`} >
            <div className='font-gotham-bold fs-18  fs-md-18 text-center'>{messageWrapCounterLabel} {TitlesLabels.general.additionalCompanyRequest}</div>

            {labelNote}

            {
                !Utilities.isEmpty(messageCompanyName ?? '') &&
                <div className='message-text'>
                    <div className="text-blue-label pt-2">company name</div>
                    <div className="pt-1">{messageCompanyName ?? ''}</div>
                </div>
            }

            {
                !Utilities.isEmpty(messageAdditionalName ?? '') &&
                <div className='message-text'>
                    <div className="text-blue-label pt-2">{siteAppTitle} consulltant</div>
                    <div className="pt-1">{messageAdditionalName ?? ''}</div>
                </div>
            }

            {
                !Utilities.isEmpty(messageContentValue ?? '') &&
                <div className='message-text'>
                    <div className="text-blue-label pt-2">message</div>
                    <div className="pt-1">{messageContentValue ?? ''}</div>
                </div>
            }

            {
                messageTypeValue === 60 &&
                <div className="button-wrap d-flex align-items-center justify-content-center px-4 pt-4 pb-5">
                    {buttonOption}
                </div>
            }


        </div>
    }


    const companyRequestChangeRequestMessage = () => {
        const htmlElem = jQuery('.company-creation-request-60');
        if (htmlElem.length > 0) {
            htmlElem.find('.button-wrap').length > 0 && htmlElem.find('.button-wrap').addClass('d-none');
            htmlElem.find('.label-note-wrap').length > 0 && htmlElem.find('.label-note-wrap').addClass('d-none');
            htmlElem.find('.request-counter-wrap').length > 0 && htmlElem.find('.request-counter-wrap').addClass('d-none');
        }

    }

    const companyCreationActions = async (messageData = {}, action = 0, rejectMessage = '') => {
        action = parseInt(action);



        // reject
        if (action === -2) {
            setmessageDataDetail(messageData);
            setShowCompanyCreationRejectPopup(true);
            false;
        }

        if (action > 0) {


            const currentConversationDetail = ChatUtilities.getAccountConversationDetail(conversID);

            if (Object.keys(currentConversationDetail ?? {}).length > 0) {

                let passval = {
                    api_token: TOKEN_SESSION.toString(), //TOKEN_CHAT.toString(),
                    account_id: loginAccount.account_id.toString(),
                    company_id: currentConversationDetail.convers_company_id.toString(),
                    convers_id: conversID.toString(),
                    message: action === 2 ? rejectMessage ?? '' : '',
                    flag: action.toString()
                }

                dispatch(showLoader(1));
                const responseDb = await removeRejectAcceptCompanyCreation(passval);
                dispatch(showLoader(0));

                if (Object.keys(responseDb).length > 0) {

                    if (parseInt(responseDb.success) === 1) {

                        //Utilities.messagePopup('error', responseDb.successMessage );
                        //dispatch( passConversDetailReducer(currentConversationDetail) );

                        // ACCEPT REQUEST
                        if (action === 1) {
                            Utilities.messagePopup('success', responseDb.successMessage);

                            // CHANGE REQUEST MESSAGE
                            companyRequestChangeRequestMessage();

                            // REJECT REQUEST
                        } else if (action === 2) {

                            Utilities.messagePopup('success', responseDb.successMessage);

                            // CHANGE REQUEST MESSAGE
                            companyRequestChangeRequestMessage();

                            setShowCompanyCreationRejectPopup(false);


                            // CANCEL REQUEST
                        } else if (action === 3) {
                            const messageWrapElem = ulConversationElem.find(`li#${messageData.doc_id ?? 0} div.message-wrap`);
                            messageWrapElem.removeClass('message-team-restriction');
                            messageWrapElem.find('.company-creation-request').html(ChatUtilities.messageDeletedHtml());

                        }

                        ulConversationElem.find(`li#${messageData.doc_id} div.button-wrap `).remove();

                    } else {
                        Utilities.messagePopup('error', responseDb.errorMessage);

                    }

                } else {
                    Utilities.messagePopup('error', errorMsg);
                }


            }
        }


    }

    const companyCreationActionsRejection = (message = '') => {

        if (!Utilities.isEmpty(message)) {
            companyCreationActions(messageDataDetail, 2, message);
        }

    }

    const handleCompanyRequestionClosePopup = () => {
        setShowCompanyCreationRejectPopup(false);
    }


    const communicationRequestMessageHtml = (key, messageData) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = parseInt(messageData.account_id);
        const message_content = messageData.message.toString();

        let htmlProvided = '';
        const headerLabelHtml = <div className="font-gotham-bold fs-18  fs-md-18 text-center">{Utilities.labelCommnicationRequest}</div>

        if (message_type === 41) {
            const message_array = JSON.parse(message_content);
            const buttoOption = <div className="d-flex align-items-center justify-content-center px-4 py-4">
                {/* <button type="button" id="btn-communication-reject" className="mx-3 btn-circle-reject" onClick={() => userCommunicationRejectConfirmation()}><span>reject</span></button> */}
                <button type="button" id="btn-communication-reject" className="mx-3 btn-circle-reject" onClick={() => communicationRequestAcceptReject(2)}><span>reject</span></button>
                <button type="button" id="btn-communication-accept" className="mx-3 btn-circle-accept" onClick={() => communicationRequestAcceptReject(1)}><span>accept</span></button>
            </div>
            htmlProvided = <div>
                {headerLabelHtml}
                {
                    !Utilities.isEmpty(message_array.reason) &&
                    <>
                        <div className="text-color-blue mt-2">reason</div>
                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(message_array.reason) }}></div>
                    </>
                }
                {(parseInt(message_array.button_status) === 1 && message_account_id !== parseInt(loginAccount.account_id)) && buttoOption}
            </div>

        } else if (message_type === 42) {
            htmlProvided = <div>
                {headerLabelHtml}
                <div className="d-flex-center py-1"><div className="label-request-accepted font-gotham-bold fs-16 fs-md-16">accepted</div></div>
            </div>;
        } else if (message_type === 43) {

            const reasonMessage = message_content.split('{').length > 1 ? JSON.parse(message_content).reason.toString() : '';

            htmlProvided = <div>
                {headerLabelHtml}
                <div className="d-flex-center py-1"><div className="label-request-rejected font-gotham-bold fs-16 fs-md-16">rejected</div></div>
                {
                    !Utilities.isEmpty(reasonMessage) &&
                    <>
                        <div className="text-color-blue mt-2">reason</div>
                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(reasonMessage) }}></div>
                    </>
                }
            </div>;
        }

        return htmlProvided;
    }


    const teamRestrictionConfirmationHtml = (key, messageData) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = parseInt(messageData.account_id);
        const message_content = messageData.message.toString();

        //let profileName = message_account_id!==parseInt(loginAccount.account_id) ? loginAccount.name : passChatProfile.name;
        let htmlProvided = '';

        const headerLabelHtml = <div className="font-gotham-bold fs-18  fs-md-18 text-center">{Utilities.labelCommnicationRequest}</div>

        if (message_type === 37) {
            const message_array = JSON.parse(message_content);
            const buttoOption = <div className="d-flex align-items-center justify-content-center px-4 py-4">
                <button type="button" id="btn-restrict-reject" className="mx-3 btn-circle-reject" onClick={() => teamRestrictionRejectConfirmation()}><span>reject</span></button>
                <button type="button" id="btn-restrict-accept" className="mx-3 btn-circle-accept" onClick={() => teamRestrictionStatusProper(1)}><span>accept</span></button>
            </div>

            htmlProvided = <div className='text-center'>
                {headerLabelHtml}
                {
                    message_account_id === parseInt(loginAccount.account_id) &&
                    <div className="d-flex-center py-1"><div className="label-waiting-reply font-gotham-bold fs-16 fs-md-16">{Utilities.labelAwaitingReply}</div></div>
                }

                {
                    !Utilities.isEmpty(message_array.reason) &&
                    <>
                        <div className="text-color-blue mt-2">reason</div>
                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(message_array.reason) }}></div>
                    </>
                }
                {(parseInt(message_array.button_status) === 1 && message_account_id !== parseInt(loginAccount.account_id)) && buttoOption}
            </div>

        } else if (message_type === 38) {
            htmlProvided = <div>
                {headerLabelHtml}
                <div className="d-flex-center py-1"><div className="label-request-accepted font-gotham-bold fs-16 fs-md-16">accepted</div></div>
            </div>;


        } else if (message_type === 39) {

            const reasonMessage = message_content.split('{').length > 1 ? JSON.parse(message_content).reason.toString() : message_content;

            htmlProvided = <div>
                {headerLabelHtml}
                <div className="d-flex-center py-1"><div className="label-request-rejected font-gotham-bold fs-16 fs-md-16">rejected</div></div>
                {
                    !Utilities.isEmpty(reasonMessage) &&
                    <>
                        <div className="text-color-blue mt-2">reason</div>
                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(reasonMessage) }}></div>
                    </>
                }
            </div>
        }

        return htmlProvided;
    }

    const teamRestrictionRejectConfirmation = () => {
        setShowTeamRestrictionRejectPopup(showTeamRestrictionRejectPopup ? false : true);
    }

    const teamRestrictionStatusProper = async (status = -1, reason = '') => {

        if (status === -1) { return false; }

        const passValuesRes = {
            api_token: TOKEN_SESSION.toString(), //TOKEN_CHAT.toString(),
            account_id: loginAccount.account_id.toString(),
            convers_id: conversID.toString(),
            flag: status.toString(),
            reason: reason.toString()
        };

        const button_name = status === 1 ? 'btn-restrict-accept' : 'btn-confirm-team-rejection';
        //Utilities.elementDisabledStatus(button_name, true );
        dispatch(showLoader(1));

        const responseDB = await QueryChatUtilities.acceptRejectAccessTeamCommunication(passValuesRes);
        //Utilities.elementDisabledStatus(button_name, false );
        dispatch(showLoader(0));
        setShowTeamRestrictionRejectPopup(false);

        if (Object.entries(responseDB).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(responseDB.success) === 0) {
            Utilities.messagePopup('error', responseDB.errorMessage);
            return false;
        }

        ulConversationElem.find('>li .message-restriction-button').remove();

        //if ( status===1 ){
        /* const currentConversationDetail = ChatUtilities.getAccountConversationDetail( conversID );

        let currentConversationDetailTemp = {  ...currentConversationDetail, access_status : '1' };
        ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

        props.setCurrentConversationDetail(currentConversationDetailTemp); */


        const conversDetail = await QueryChatUtilities.getConversationDetail({
            api_token: TOKEN_SESSION.toString(), // TOKEN_CHAT.toString(),
            account_id: loginAccount.account_id.toString(),
            account_type: loginAccount.account_type.toString(),
            convers_id: conversID.toString(),
            //flag: "1"
        });

        if (!RM.isEmpty(conversDetail)) {
            if (parseInt(conversDetail.success) === 1) {
                const currentConversationDetailTemp = conversDetail.result.conversation_details ?? {};
                ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 7);

                dispatch(passConversDetailReducer(currentConversationDetailTemp));
            }
        }

        //}
    }

    const showMessageStartHere = (docDateId = 0) => {
        if (docDateId > 0 && isSearch === true && typeof props.showMessagesFromSearch !== 'undefined') {
            props.showMessagesFromSearch(docDateId);
        }
    }

    const chatWelcomVideoWatch = (videoUrl = '') => {
        if (!Utilities.isEmpty(videoUrl)) {
            videoUrl = videoUrl.split('/').pop();
            setVideoYoutubeCodePopup(videoUrl);
            setShowVideoPopup(true);
        }
    }

    const welcomeMessageHtml = (messageData = {}) => {

        let isGroup = parseInt(messageData.message_type ?? 0) === 11 ? false : true;

        let user_fullname = '';
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if (Object.keys(loginProfileInfo).length > 0) {

                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                user_fullname = profileLoggedinTemp.name;

                if (messageData.hasOwnProperty('account_id')) {
                    if (parseInt(messageData.account_id) !== parseInt(profileLoggedinTemp.account_id)) {
                        //user_fullname = jQuery('.chat-conversation-header .profile-name-wrap .profile-name').text();
                        user_fullname = jQuery('.main-header-content .user-navigate .user-current-navigate #header-profile-name').text();
                    }
                }
            }
        }


        let youtbeLink = `https://www.youtube.com/shorts/gSAedxdzdJU`, siteTitle = siteAppTitle.toLowerCase();
        let iconVideo = <img src={`${imgPath}/icons/green/outline-video-thin.svg`} />

        user_fullname = user_fullname.toLowerCase();
        //let videoLink1 = `https://youtube.com/shorts/LoVV_s3tIEk`, videoLink2 = `https://youtube.com/shorts/PWgsTBhBG4E`;

        let htmlFormatted = <div className="welcome-chat text-lowercase">
            <p>hi {user_fullname} !</p>
            <p>welcome to {siteTitle}.</p>

            <p>{siteTitle} is a business directory and chat app connecting users to businesses, professionals and organisations. please use this chat to communicate with us in providing any constructive suggestions you may have.</p>

            <p>you may find the following videos useful in understanding {siteTitle}</p>

            <ul id="welcome-message-video-option" className="d-flex align-items-center justify-content-center welcome-message-video-option mb-3">
                <li onClick={() => chatWelcomVideoWatch(chatWelcomeVideos_links.search_business)}><div>{iconVideo}<div>searching for a business</div></div></li>
                <li onClick={() => chatWelcomVideoWatch(chatWelcomeVideos_links.create_company)}><div>{iconVideo}<div>creating a business profile</div></div></li>
                <li onClick={() => chatWelcomVideoWatch(chatWelcomeVideos_links.joining_school)}><div>{iconVideo}<div>joining a school group</div></div></li>
            </ul>

            <p>regards from the {siteTitle} team</p>

        </div>


        if (isGroup === true) {
            htmlFormatted = <div className="welcome-chat text-tranform-initial">
                <p>Hi {user_fullname} !</p>
                <p>Welcome to {siteTitle}.</p>

                <p><span style={{ textTransform: 'capitalize !important;' }}>{siteTitle}</span> is a business directory and chat app that has been designed to facilitate greater ease of communication between customers/end users and businesses/services providers. </p>

                <p>We recognize that you have joined as a parent/guardian at your child's school's invitation. Please follow these easy steps to join the school group relevant to your child</p>

                <ul className="pl-3 mb-4" style={{ listStyle: 'decimal' }} >
                    <li className="mb-2">Tap on DIRECTORY on menu bar at the bottom of your screen.</li>
                    <li className="mb-2">On search page, type in the school name and tap on the result which will take you to to the school's {siteTitle} profile page.</li>
                    <li className="mb-2">Tap on CHAT at the bottom and choose GROUPS.</li>
                    <li className="mb-2">From group list choose your child's class group and join with the code the school may have provided.</li>
                </ul>

                <p>If you wish to see a video showing the above process, please tap on this link <a href={youtbeLink} target="_blank">{youtbeLink}</a></p>

                <p>Good luck and use this chat as a support for {siteTitle} matters only. School issues should be communicated directly with the school through its chat profile.</p>

                <p> Welcome to {siteTitle} again</p>
            </div>
        }


        return htmlFormatted;
    }


    const htmlMessage = (key, messageData) => {

        //console.log(messageData);

        const conversFlag = parseInt(currentConversationDetail.flag ?? 0) ?? 0;
        const conversAccessFlag = parseInt(currentConversationDetail.access_flag ?? 0) ?? 0;
        const message_type = parseInt(messageData.message_type || 1);

        if (dontShowMessageTypes.includes(message_type)) { return false; }

        let messageHtmlData = ChatUtilities.formatMessageHTML(ownerIdConversation, messageData, 1, loginAccount);
        const readReplyStatus = parseInt(messageData.reply_message_status || 0);
        //const currentLoginId = parseInt(loginAccount.account_id);
        let mainMesageHtml = messageHtmlData.html_message;


        let messageReplyHTML = '', messageForwardHTML = '', messageAutoReplyHTML = '';
        let class_extra = [], class_extra_li = [], showMessageTime = true;

        const formTypesArray = ChatUtilities.formTypes();
        const formTypesKeysArray = Object.keys(formTypesArray);
        const li_groups_class = 'justify-content-center opacity-075 text-center pt-1 other';


        if (readReplyStatus === 1) {
            const messageReplyData = messageData.reply_quote_details;
            const messageReplyHtmlData = ChatUtilities.formatMessageHTML(ownerIdConversation, messageReplyData, 1);
            messageReplyHTML = messageReplyHtmlData.html_message;

            if (parseInt(messageReplyData.message_type) === 2) {
                const filename = messageReplyData.message.split('/').pop();
                messageReplyHTML = '<div class="d-flex align-items-center">' + messageReplyHTML + '<div class="ml-2 cut-text">' + filename + '</div></div>';
            }
        } else if (readReplyStatus === 2) {
            messageForwardHTML = <div className="message-forwarded-label fs-14 fs-md-14">forwarded</div>
        }

        // conversFlag : 6 - Company Groups
        // conversAccessFlag : 1-admin, 2-team members, 3-members

        /* if ( conversFlag===6 && ![15,16,17,18,20].includes(message_type) ){ // 19, 21
            const messageMemberList = messageData.members_list ?? "";

            if ( ! Utilities.isEmpty(messageMemberList) ){
                const memberListArr = messageMemberList.split(",");
                const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
               
                if ( ! memberListArr.includes( (userLoginDetail.account_id ?? '').toString() ) ){
                    messageHtmlData = {};
                }
            }
        } */


        if ([15, 16].includes(message_type)) {
            if (conversFlag === 6) {
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                /* if ( message_type==16 && ![1].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                } */
            }

        } else if (message_type === 17) {
            if (parseInt(loginAccount.account_id) === parseInt(messageData.account_id)) {
                messageHtmlData = {};
            }

            if (conversFlag === 6) {
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                /* if ( ![2].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                } */
            }

        } else if (message_type === 18) {


            if (conversFlag === 6) {
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                /* if ( ![3].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                } */

            } else {
                class_extra.push('message-warning');
                class_extra_li.push('message-transfer-wrap');
                mainMesageHtml = mainMesageHtml.replace('Gotham, Medium', "Gotham Medium");
            }

        } else if (message_type === 19) {

            if (![6].includes(conversFlag)) {
                class_extra.push('message-success');

            } else {

                /* messageHtmlData = {};
                class_extra_li.push(li_groups_class);
                showMessageTime = false; */
            }

            if (parseInt(messageData.account_id) === 4) {
                class_extra_li.push('message-transfer-wrap');
            }

            mainMesageHtml = mainMesageHtml.replace('Gotham, Medium', "Gotham Medium");

        } else if (message_type === 21) {

            // COMPANY GROUPS - Request to join ( admin only )
            /* if ( conversFlag===6 && ![1].includes(conversAccessFlag) ){
                messageHtmlData = {};
            } */


        } else if (message_type === 31) {
            class_extra.push('transfer-message');
            class_extra.push('transfer-warning');

        } else if (message_type === 33) {
            class_extra.push('transfer-message');
            class_extra.push('transfer-success');

        } else if (message_type === 35) {

            messageAutoReplyHTML = <div className="message-autoreply-label">auto reply</div>
            if (parseInt(loginAccount.account_id) === parseInt(messageData.account_id)) {
                messageHtmlData = {};
            }


        }

        // COMPANY GROUPS CHAT
        if (conversFlag === 6) {
            if (!messageData.hasOwnProperty('account_name') || !messageData.hasOwnProperty('members_list')) {
                messageHtmlData = {};
            } else {
                const boolGroupChatResponse = checkMessageIsEligible(messageData.members_list ?? '', message_type ?? '0', messageData.message ?? '', conversAccessFlag ?? '0');
                if (!boolGroupChatResponse) {
                    messageHtmlData = {};
                } else {
                    // ChatUtilities.companyGroupsChatMessageValidCounter++;
                }
            }
            // console.log(ChatUtilities.companyGroupsChatMessageValidCounter);
        }

        // END COMPANY GROUPS CHAT


        let class_forms = '', class_forms_wrap = [];
        let id_forms = '';

        if (formTypesKeysArray.includes(message_type.toString())) {
            //class_forms = formTypesArray[message_type];
            class_forms_wrap.push('message-department-form');

            if (![6].includes(conversFlag)) {
                const messageFormSplitted = messageData.message.toString().split('|');
                const msgFormId = messageFormSplitted[0];

                if (ChatUtilities.departmentFormsTypes.includes(message_type)) {
                    id_forms = 'message-form-type-' + msgFormId.toString();
                }

            } else {
                class_forms_wrap.push(' ml-0');
            }

        }

        if (restrictionMessageTypesArray.includes(message_type) || companyCreationRequestTypesArray.includes(message_type)) {
            class_forms_wrap.push('message-team-restriction');
        }

        if (communicationMessageTypeArray.includes(message_type)) {
            class_forms_wrap.push('message-communication-request');
        }


        if (dailyGalnceTypesArray.includes(message_type)) {
            class_forms_wrap.push('message-glance-request');
        }

        let hasDateSeparator = '', messageMessengerProfileHtml = '';;

        if (Object.keys(messageHtmlData).length > 0) {
            if (messageData.hasOwnProperty('date') && !Utilities.isEmpty(messageData.date) && typeof messageData.date !== 'undefined' && messageData.date !== null) {

                const localTime = moment.utc(messageData.date);
                let local_date = moment(new Date(localTime)).format('DDMMMYY').toLocaleString();
                local_date = local_date.toLowerCase();

                let messageDate = moment(new Date(localTime)).format(Utilities.dateChatFormatStr.toString()).toLocaleString();

                if (!conversationsDate.includes(local_date)) {

                    const sameDay = moment(messageData.date).isSame(moment(), 'day');
                    if (sameDay === true) { messageDate = 'today'; }

                    hasDateSeparator = <li className="message-date-separator"><span>{messageDate}</span></li>
                    conversationsDate.push(local_date);
                }

                if (isSearch === true) {
                    hasDateSeparator = messageDate;
                }
            }


            if ([2, 6].includes(conversFlag)) {

                let senderLogo = defaultProfileLogo, senderName = '', accountNameAndLogoArray = [];

                if (parseInt(workAccount.account_id ?? 0) === parseInt(messageData.account_id)) {
                    senderName = 'you';//loginAccount.name;
                    senderLogo = workAccount.logo_url;

                } else {
                    if (!Utilities.isEmpty(messageData.account_name ?? '')) {

                        accountNameAndLogoArray = messageData.account_name.split('|');

                        if (accountNameAndLogoArray.length === 1) {
                            senderLogo = accountNameAndLogoArray[0].split('.').length > 1 ? accountNameAndLogoArray[0] : defaultProfileLogo;

                        } else {
                            senderLogo = accountNameAndLogoArray[1].split('.').length > 1 ? accountNameAndLogoArray[1] : defaultProfileLogo;
                            senderName = accountNameAndLogoArray[0];
                        }

                        if (typeof senderLogo.split('/').pop() === 'undefined') {
                            senderLogo = defaultProfileLogo;
                        }

                    }
                }

                //console.log(loginAccount, messageData);
                messageMessengerProfileHtml = <>
                    <div className="sender-profile-logo" style={{ backgroundImage: `url(${senderLogo})` }}></div>
                    {!Utilities.isEmpty(senderName) ? <div className="sender-profile-name font-gotham-bold fs-18 fs-md-16sender-profile-name"><div>{senderName}</div></div> : ''}
                </>

                if ([15, 17].includes(message_type) && [6].includes(conversFlag)) {
                    messageMessengerProfileHtml = '';
                }


                // this is when no "|" in the account name
                if ([6].includes(conversFlag)) {

                    if (!Utilities.isEmpty(messageData.account_name ?? '')) {
                        accountNameAndLogoArray = messageData.account_name.split('|');
                        if (accountNameAndLogoArray.length === 1) {
                            messageMessengerProfileHtml = '';

                            class_extra_li.push(li_groups_class);
                            showMessageTime = false;
                        }
                    }
                }
            }
        }


        let outputHtml = Object.keys(messageHtmlData).length > 0 &&
            <Fragment key={key}>

                {isSearch === false && hasDateSeparator}

                <li key={key} id={messageData.doc_id} className={`fade-in- ${messagesIdSelected.includes(parseInt(messageData.doc_id)) ? 'selected' : ''} ${messageHtmlData.class_names} ${class_extra_li.join('')}`} onClick={() => showMessageStartHere(parseInt(messageData.doc_id))}>

                    {
                        multipleOptionArray.includes(message_type) &&
                        <div className="checkbox-message-option"><input type="checkbox" value={messageData.doc_id} onChange={() => selectMessage(messageData)} checked={messagesIdSelected.includes(parseInt(messageData.doc_id)) ? 'checked' : ''}  ></input></div>
                    }

                    <div className={`message-wrap ${class_forms_wrap.join(' ')} ${messageHtmlData.class_welcome + ' ' + class_extra.join(' ')} `}>

                        {messageForwardHTML}
                        {messageAutoReplyHTML}
                        {messageMessengerProfileHtml}

                        {
                            transferTypesArray.includes(message_type) &&
                            <div className="transfer-header fs-18 fs-md-18">chat transfer</div>
                        }

                        {
                            (
                                message_type > 0 &&
                                !excludedOptionArray.includes(message_type) &&
                                !formTypesKeysArray.includes(message_type.toString()) &&
                                isSearch === false
                            ) &&
                            menuOptionHtml(messageData)
                        }

                        {readReplyStatus === 1 && <div className="message-reply-wrap" dangerouslySetInnerHTML={{ __html: messageReplyHTML }}></div>}

                        <div>
                            {
                                !formTypesKeysArray.includes(message_type.toString()) ?
                                    <>
                                        {
                                            !transferTypesArray.includes(message_type) ?
                                                <>
                                                    {
                                                        parseInt(message_type) === 2 ?
                                                            <div className="message-item-content" onClick={() => messageBoxOption(messageData)}>
                                                                <img src={messageData.message.toString()} width="200" />
                                                            </div>
                                                            :
                                                            [11, 50].includes(parseInt(message_type)) ?
                                                                welcomeMessageHtml(messageData)
                                                                :

                                                                <>
                                                                    {
                                                                        restrictionMessageTypesArray.includes(message_type) ?
                                                                            teamRestrictionConfirmationHtml(key, messageData)
                                                                            :
                                                                            <>
                                                                                {
                                                                                    communicationMessageTypeArray.includes(message_type) ?
                                                                                        communicationRequestMessageHtml(key, messageData)
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                companyCreationRequestTypesArray.includes(message_type) ?
                                                                                                    companyCreationRequestMessageHtml(key, messageData)
                                                                                                    :
                                                                                                    dailyGalnceTypesArray.includes(message_type) ?
                                                                                                        glanceNewsAndEventsHtml(key, messageData)
                                                                                                        :
                                                                                                        <div className="message-item-content" onClick={() => messageBoxOption(messageData)} dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(mainMesageHtml) }}></div>
                                                                                            }
                                                                                        </>

                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                    }
                                                </>
                                                :
                                                transferChatMessageHtml(key, messageData)
                                        }
                                    </>
                                    :
                                    <div id={id_forms} className={`message-item-content message-form-types ${class_forms} `}>
                                        {conversFlag === 6 ? companyGroupsHtmlContent(messageData, messageHtmlData) : formsHtmlContent(messageData, messageHtmlData)}
                                    </div>
                            }


                            {requestActionHtml(messageData)}


                            {showMessageTime && <div className="message-time fs-9 fs-md-10 "><span>{isSearch === true && hasDateSeparator} {messageHtmlData.message_time}</span></div>}

                        </div>

                    </div>

                </li>
            </Fragment>

        if (Object.keys(messageHtmlData).length > 0) {
            if (message_type === 19 && parseInt(messageData.account_id) === parseInt(currentConversationDetail.account_type)) {
                outputHtml = '';
            }
        }

        //ChatUtilities.scrollBottomConversation();
        return outputHtml;
    }


    const scrollToBottom = () => {
        const timer = setTimeout(() => {
            ChatUtilities.scrollBottomConversation();
            clearTimeout(timer);
        }, 100);
    }


    useEffect(() => {
        setPreviousConversations([]);
        setPresentConversations([]);
        setShowLoadingMessages(true);
        setShowCompanyCreationRejectPopup(false);

        setLoginAccount(PagesAuthentication.getUserLoggedinDetail());

        /* let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                setLoginAccount(loginProfileInfo);
            }
        } */

    }, []);


    useEffect(() => {
        setOwnerIdConversation(props.ownerIdConversation);
        //setCurrentConversationDetail(props.currentConversationDetail);

        if ('previousConversations' in props && parseInt(props.previousConversations.length) > 0) {
            setPreviousConversations(props.previousConversations);
        }

        if ('presentConversations' in props && parseInt(props.presentConversations.length) > 0) {
            setPresentConversations(props.presentConversations);
        }

        //setShowLoadingMessages(false);


    }, [props]);



    useEffect(async () => {
        if ('presentConversations' in props && parseInt(props.presentConversations.length) > 0) {
            scrollToBottom();
            ChatUtilities.removeDuplicateMessages();

            setMessagesIdSelected([])
            setSelectedMessagesDetail([]);
            ulConversationElem.removeClass('enable-selection');

            //setShowLoadingMessages(false);
        }

        // MARK AS READ 
        /* if ( ! Utilities.isEmpty(props.requestProfile) ){
            await ChatUtilities.markMessagesRead( parseInt(props.requestProfile.account_id), parseInt(props.conversID) );
        } */

    }, [props.presentConversations]);


    useEffect(() => {
        if (!Utilities.isEmpty(props.bolClearSelectedMessages)) {
            setSelectedMessagesDetail([]);
            props.messagesSelectedDetail([]);
        }
    }, [props.bolClearSelectedMessages]);


    useEffect(() => {
        const conversIDTemp = props.conversID;


        if (parseInt(conversIDTemp) > 0) {
            setConversID(conversIDTemp);

            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversIDTemp);

            if (parseInt(currentConversationDetailTemp.flag) === 2) {

                const accountAssociated = PagesAuthentication.getUsersAssociated();

                let conversValues = props.match.params.coversid;
                let encodedValues = ChatUtilities.decryptChatDetail(conversValues);

                if (encodedValues.hasOwnProperty('internal_account_id') && parseInt(encodedValues.internal_account_id) > 0) {
                    const teamWorkProfile = Object.values(accountAssociated).filter(row => parseInt(row.account_id) === parseInt(encodedValues.internal_account_id))[0] ?? {};
                    setWorkAccount(teamWorkProfile);
                }

            }
            setCurrentConversationDetail(currentConversationDetailTemp);
            ChatUtilities.globalCurrentConversationDetail = currentConversationDetailTemp;

            setShowLoadingMessages(false);
        }

    }, [props.conversID]);


    useEffect(() => {

        const bolUncheckSelectedMessages = props.bolUncheckSelectedMessages;
        if (bolUncheckSelectedMessages === true) {
            setMessagesIdSelected([]);
            props.messagesSelectedDetail([]);
        }

    }, [props.bolUncheckSelectedMessages]);


    return (
        <Fragment>

            {showLoadingMessages === true && <CicleLoader />}

            {
                ('previousConversations' in props) &&
                Object.entries(previousConversations).map(([key, row]) => htmlMessage(key, row))
            }

            {
                ('presentConversations' in props) &&
                Object.entries(presentConversations).map(([key, row]) => htmlMessage(key, row))
            }

            <ChatTeamRestrictionRejection showPopup={showTeamRestrictionRejectPopup} handleTeamRestrictionStatusProper={teamRestrictionStatusProper} handleTeamRestrictionRejectConfirmation={teamRestrictionRejectConfirmation} />

            <ChatUserCommunicationRejection showPopup={showUserCommunicationRejectPopup} handleUserCommunicationStatusProper={communicationRequestAcceptReject} handleUserCommunicationRejectConfirmation={userCommunicationRejectConfirmation} />

            <CompanyCreationRejectPopup showPopup={showCompanyCreationRejectPopup} handleClosePopUp={handleCompanyRequestionClosePopup} handleSubmit={companyCreationActionsRejection} />

            <VideoPopup showPopup={showVideoPopup} handleClosePopUp={handleCloseVideoPopUp} youtubeCode={videoYoutubeCodePopup} />

        </Fragment>
    )
}

export default memo(ChatConversation);
