import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from "../../helpers/TitlesLabels";
import { pageTitle, showLoader } from "../../actions";

import DailyGlanceEventsFeaturedLayout from "./DailyGlanceEventsFeaturedLayout";
import { DailyGlanceArticleLoader, DailyGlanceFeaturedItemTemplate } from "./DailyGlanceArticleWidgets";
import NoRecordAvailable from "../../components/common/NoRecordAvailable";
import { getListFeatured } from "../../helpers/QueryDailyGlanceFeatured";
import { handleClickAdverstisement } from "../../helpers/UtilitiesDailyGlance";
import UrlSlugs from "../../helpers/UrlSlugs";
import { AdsPopUpBody } from "./DailyGlancePopups";
import PagesAuthentication from "../../helpers/PagesAuthentication";
import { glanceArticleCompanyAds } from "../../helpers/QueryDailyGlance";


const DailyGlanceFeaturedDetail = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { articleid } = useParams();

    let widthArticle = 950, result_Db = '', articleIndex = -1;

    const [delayLoader, setDelayLoader] = useState(false);
    const [showListLoader, setShowListLoader] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [articleWContainer, setArticleWContainer] = useState(widthArticle);

    const [showArticleAdsPopup, setShowArticleAdsPopup] = useState(false);
    const [articleAdsDetail, setArticleAdsDetail] = useState({});


    const handleCloseOptionArticleAdsPopUp = () => {
        setArticleAdsDetail({});
        setShowArticleAdsPopup(false);
    }

    const handleClickArticleAdverstisement = async (detail = {}) => {
        if (Object.keys(detail).length > 0) {
            const adsId = parseInt(detail.ads_id ?? 0);
            const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

            if (adsId > 0) {
                dispatch(showLoader(1));
                const result_Db = await glanceArticleCompanyAds({ ads_id: adsId, account_id: profileLoggedin.account_id ?? 0 });
                dispatch(showLoader(0));

                if (parseInt(result_Db.status ?? 0) === 1 && Object.keys(result_Db.detail ?? {}).length > 0) {
                    setArticleAdsDetail(result_Db.detail ?? {});
                    setShowArticleAdsPopup(true);
                }

            }
        }
    }

    const getingEventList = async (idSelected = -1) => {

        setDelayLoader(false);
        setShowListLoader(true);
        result_Db = await getListFeatured({ view_all: 1 });
        setShowListLoader(false);

        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setDelayLoader(true);
                const evetListResult = result_Db.list ?? [];
                setEventList(evetListResult);


                Object.entries(evetListResult).map(([key, row]) => {
                    if ((row.id ?? '').toString() === (idSelected ?? '-1').toString()) {
                        articleIndex = key;
                        if ((row.ads_type ?? 0) == 1) {
                            handleClickArticleAdverstisement(row);
                        }
                    }
                });

                settingUpItems(articleIndex);
            }
        }

    }

    const settingUpItems = (indexDefault = 0) => {
        indexDefault = parseInt(indexDefault);

        //article-main-content
        let widthArticleContent = jQuery('#article-main-content').width();
        widthArticleContent = parseInt(widthArticleContent);
        setArticleWContainer(widthArticleContent);


        const slider = document.querySelector('.slider-items');
        let isDown = false;
        let startX;
        let scrollLeft;

        setTimeout(() => {

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });

            // set index
            if (indexDefault >= 0) {
                slider.scrollLeft = widthArticleContent * indexDefault;
            }

            setDelayLoader(false);

        }, 1000);

    }

    const redrectToGlanceHome = () => {
        history.push(`/${UrlSlugs.settings_notifications}`);
    }


    useEffect(async () => {
        dispatch(pageTitle(`${TitlesLabels.general.events}`));

        try {
            let idSelectedParam = atob(articleid);

            getingEventList(idSelectedParam);

        } catch (e) {
            redrectToGlanceHome();
        }



    }, []);



    return (
        <>
            <DailyGlanceEventsFeaturedLayout menuTitle={TitlesLabels.general.featuredArticles} typeName="featured">
                <div id="article-main-content" className="col-lg-11 mx-auto" >
                    {
                        delayLoader &&
                        <>
                            <DailyGlanceArticleLoader />
                            <div className="ads-image mt-5 global-preloader bg-grey-important"></div>
                        </>
                    }

                    {
                        showListLoader ?
                            <DailyGlanceArticleLoader />
                            :
                            <>
                                {
                                    Object.keys(eventList).length === 0 ?
                                        <NoRecordAvailable />
                                        :
                                        <div className="slider-items" style={{ gridTemplateColumns: `repeat(${eventList.length}, ${articleWContainer}px)`, width: `${articleWContainer}px`, opacity: delayLoader ? 0 : 1 }}>
                                            {
                                                Object.entries(eventList).map(([key, row]) =>
                                                    <div className="slide-item  px-4" style={{ width: `${articleWContainer}px` }} key={key}>
                                                        <div className=" article-content-description">
                                                            <DailyGlanceFeaturedItemTemplate detail={row} displayType={`detail`} key={key} showSlider={true} />
                                                        </div>

                                                        <div className="ads-image mt-5"
                                                            style={{ backgroundImage: `url('${row.ads_image ?? ''}')` }}
                                                            onClick={() => (row.ads_type ?? 0) == 0 ? handleClickAdverstisement(row, 1, dispatch) : handleClickArticleAdverstisement(row)}
                                                        ></div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                }
                            </>
                    }

                </div>
            </DailyGlanceEventsFeaturedLayout>

            <AdsPopUpBody showStatus={showArticleAdsPopup} handleClose={handleCloseOptionArticleAdsPopUp} detail={articleAdsDetail} />
        </>

    )
}

export default DailyGlanceFeaturedDetail;