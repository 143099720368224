import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';

import Utilities from '../helpers/Utilities';
import { getProfilesByCityId } from '../helpers/QueryContacts';

import CityAreaSearch from './popups/CityAreaSearch';
import MainSearchProfile from './popups/MainSearchProfile';
import QueryMasterSearchUtlities from '../helpers/QueryMasterSearchUtlities';
import PagesAuthentication from '../helpers/PagesAuthentication';

import { showHeader, showFooter, pageTitle, passImageUrl } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import CommonProfileLogo from './common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';
import NoRecordAvailable from './common/NoRecordAvailable';


const ContactSearch = ({ match }) => {


    const dispatch = useDispatch();

    //const showPopupContactSearch = useSelector( state => state.showPopupContactSearch);

    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '#'],
        defaultCounter = { 'company': 0, 'user': 0 };

    let lettersAdded = [], scrollXMeasure = 0;
    let counterRow = 0, rowListHeight = 56.75, rowListHeightLarger = rowListHeight + 20, deductHeight = 210, deductHeightLarge = 310;

    const defaultLocation = Utilities.defaultLocation();

    const [lettersAvailable, setLettersAvailable] = useState([]);
    const [selectedLetter, setSelectedLetter] = useState(''); //letters[0]);
    const [profileShowStatus, setProfileShowStatus] = useState(1);

    const [show, setShow] = useState(false);
    const [showCityPopup, setShowCityPopup] = useState(false);
    const [showStatsPopup, setShowStatsPopup] = useState(false);

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    const [gettingProfilesProcessing, setGettingProfilesProcessing] = useState(true);
    const [listProfiles, setListProfiles] = useState([]);
    const [counterProfiles, setCounterProfiles] = useState(defaultCounter);

    const [profileSelected, setProfileSelected] = useState({});

    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);

    const handleCloseStatsPopUp = () => setShowStatsPopup(false);
    const handleShowStatsPopUp = () => setShowStatsPopup(true);


    const handleClose = () => {
        setProfileSelected({});
        setShow(false);
    }

    const handleShow = async (profile) => {
        setProfileSelected(profile);
        setShow(true);
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        searchFilter('');

        if (jQuery('#input_keyword').length > 0) {
            document.getElementById('input_keyword').focus();
        }
    }


    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#profile-listing-contact > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);


        });

        jQuery('#profile-listing-contact > li').filter(function () {
            if (jQuery(this).is(":visible")) {
                jQuery(`#profile-listing-contact > li.letter-row[data-letter="${jQuery(this).attr('data-letter')}"]`).show();
            }
        });


    }


    const switchLetter = (requestLetter = '') => {
        if (!Utilities.isEmpty(requestLetter)) {

            if (!lettersAvailable.includes(requestLetter)) {
                return false;
            }

            requestLetter = requestLetter === '#' ? 'numeric' : requestLetter;

            let targetElem = jQuery('li#letter-contact-' + requestLetter);
            if (targetElem.length > 0) {

                const browserWith = jQuery(window).width();
                if (browserWith > Utilities.largeDeviceStartingPoint) {
                    deductHeight = deductHeightLarge;
                }

                const topScroll = targetElem.offset().top - deductHeight;

                jQuery('html, body').stop().animate({
                    scrollTop: topScroll

                }, 500, '', function () {
                    setSelectedLetter(requestLetter);
                });
            }
        }
    }


    const passCityDetail = (cityDetail = {}) => {
        if (Object.entries(cityDetail).length > 0) {

            let defaultLocationTemp = defaultLocation;

            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if (userLocation !== null) {
                defaultLocationTemp = JSON.parse(userLocation);
            }

            defaultLocationTemp = {
                ...defaultLocationTemp,
                country_name: cityDetail.country_name,
                state_name: cityDetail.state_name,
                city_name: cityDetail.city_name,
                area_name: cityDetail.area_name ?? '',

                country_id: cityDetail.country_id,
                state_id: cityDetail.state_id,
                city_id: cityDetail.city_id,
                area_id: cityDetail.area_id ?? '0',
            };
            setCurrentLocation(defaultLocationTemp);

            gettingProfiles(defaultLocationTemp.city_id);
            setSelectedLetter(letters[0]);
            jQuery('html, body').scrollTop(0);

        }
        handleClosePopUp();
    }

    const gettingProfiles = async (city_id = 0, area_id = 0) => {

        let lettersAvailableTemp = [];
        area_id = parseInt(area_id ?? 0);
        city_id = parseInt(city_id);

        if (city_id > 0) {

            setGettingProfilesProcessing(true);
            const listPorifles = await getProfilesByCityId(city_id, area_id);
            setGettingProfilesProcessing(false);

            if (Object.keys(listPorifles).length > 0) {
                setListProfiles(listPorifles.list ?? []);

                setCounterProfiles(listPorifles.counter ?? defaultCounter);

                if (Object.keys(listPorifles.list).length > 0) {

                    Object.entries(listPorifles.list).map(([key, row]) => {

                        let letter = row.name[0].toLowerCase();

                        if (letter !== ' ') {
                            if (Utilities.isDigit(letter)) {
                                letter = '#';
                            }

                            lettersAvailableTemp.push(letter);
                        }

                    });
                }

                lettersAvailableTemp = Utilities.arrayUnique(lettersAvailableTemp);
                lettersAvailableTemp = lettersAvailableTemp.filter(Boolean);

                setLettersAvailable(lettersAvailableTemp);
                setSelectedLetter(lettersAvailableTemp[0]);


            } else {
                setListProfiles([]);
            }
        }
    }


    const profileListingHtml = (key, row) => {

        counterRow++;

        let letter = row.name[0].toLowerCase();
        let letterId = key;
        let letterLabelRow = letter;

        if (Utilities.isDigit(letter)) {
            letter = 'numeric';
            letterId = 'numeric';
            letterLabelRow = '#';
        }

        let letterRow = '';

        if (!lettersAdded.includes(letter)) {
            lettersAdded.push(letter);
            letterId = letter;

            letterRow = <li key={counterRow} id={`letter-contact-${letterId}`} data-letter={letter} data-inc={counterRow} className="letter-row" data-keyword="">
                <span>{letterLabelRow}</span>
            </li>

            counterRow++;
        }


        const locationAddressFormatted = QueryMasterSearchUtlities.formatAdressByLocationType(1, row);
        let name = parseInt(row.account_type) === 3 ? row.company_name : row.name;
        name = name.trim();

        //const scrollAttr = 56.8175 * (parseInt(key)+1);

        const listRow = <li key={counterRow} data-letter={letter} data-inc={counterRow} data-keyword={name.toLowerCase()} onClick={() => handleShow(row)} >
            <div className="profile-wrap">
                <div className="profile-image-wrap">
                    {/* <div className={`profile-image fs-14-important logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, name) }</div> */}
                    <CommonProfileLogo classAttr={`profile-image fs-14-important logo-size-60 logo-size-md-60`} profileDetail={{ ...row, name: name }} handlePreviewProfileImage={previewProfileImage} preventOnclick={true} />
                </div>
                <div>
                    <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1" data-name={name}>{name}</div>
                    {(row.hasOwnProperty('category') && row.category !== '') && <div className="profile-label color-theme-grey">{row.category}</div>}
                </div>

                <div className="color-theme-grey">
                    {(row.hasOwnProperty('team_company_name') && !Utilities.isEmpty(row.team_company_name)) && <div className="profile-work-label mr-2 notranslate">{row.team_company_name}</div>}
                    <div className="profile-address">{locationAddressFormatted}</div>
                </div>

                <div className="hidden-detail d-none">
                    <div className="name notranslate">{name}</div>
                    <div className="logo">{row.logo_url}</div>
                    <div className="address">{locationAddressFormatted}</div>
                </div>

            </div>
        </li>

        return <>{letterRow} {listRow}</>;
    }


    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }

    const loaderProfileHtml = () => {
        return (
            <ul className="dashboard-list-preloader">
                {
                    [...Array(20).keys()].map((key) =>
                        <li key={key}>
                            <div className="profile-image"></div>
                            <div></div>
                            <div></div>
                        </li>
                    )
                }

            </ul>
        )
    }


    const closeSearchBox = (totallyClose = false) => {
        setShow(false);

        /* if ( totallyClose===true ){
            dispatch( showPopupContactSearchReducer({ showPopup : false }) );
            dispatch( showMainSearchReducer(0) );
        } */
    }

    const onscrollContacts = (topScroll = 0) => {
        const scrollElem = jQuery('#contact-listing'), browserWith = jQuery(window).width();

        if (browserWith > Utilities.largeDeviceStartingPoint) {
            rowListHeight = rowListHeightLarger;
        }

        const rowNumber = parseInt(parseInt(topScroll) / rowListHeight) + 1;
        const rowLetter = scrollElem.find(`ul > li[data-inc="${rowNumber}"]`).attr('data-letter');

        if (letters.includes(rowLetter)) {
            setSelectedLetter(rowLetter);
        }

    }

    const searchContentKeywordHtml = () => {
        return <ul className="keyword-option form-theme bg-white w-100 border-radius">
            <li>
                <div className='form-group mb-0 border-0  w-100'>
                    <input type="text" id="input_keyword" className="form-input input-keyword bg-white border-radius w-100" placeholder="search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} autoComplete="off" ></input>
                </div>
            </li>
            <li></li>

            {
                !Utilities.isEmpty(defaultSearchValue) &&
                <li><button type="button" className="btn-icon btn-clear-input" onClick={() => resetSearchValue()} title="clear"><span className="ion-ios7-close-empty"></span></button>  </li>
            }

        </ul>
    }


    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.contactSearch));

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);
        sesstionLoginType = parseInt(sesstionLoginType);

        dispatch(showHeader(sesstionLoginType === 2 ? 5 : 1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        let defaultLocationTemp = defaultLocation;
        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
        if (userLocation !== null) {
            defaultLocationTemp = JSON.parse(userLocation);
        }

        const directoryLocation = SessionUtilities.getDirectoryLocation();
        if (directoryLocation !== null) {
            defaultLocationTemp = directoryLocation;
        }

        gettingProfiles(defaultLocationTemp.city_id, defaultLocationTemp.area_id ?? '0');
        setCurrentLocation(defaultLocationTemp);

        // gettingProfiles(defaultLocationTemp.city_id, defaultLocationTemp.area_id??'0');
        setSelectedLetter(letters[0]);


        jQuery(window).on('scroll', function () {
            const topScroll = window.pageYOffset || document.documentElement.scrollTop;
            onscrollContacts(topScroll);
        });



    }, []);


    useEffect(() => {

        let matchShowStatus = match.params.type ?? btoa(1);
        matchShowStatus = parseInt(atob(matchShowStatus));

        setProfileShowStatus(matchShowStatus);

    }, [match.params.type]);


    return (
        <>
            <div className='directory-location-option-wrap d-flex justify-content-center text-lowercase'>
                <div className="cursor-pointer position-fixed zindex-11 text-white text-center" onClick={() => handleShowPopUp()}>
                    <div className=' fs-20 fs-md-22'>
                        <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-map-pin.svg`} width={25} className="ml-n2 mr-2" />
                        {currentLocation.area_name ? currentLocation.area_name : currentLocation.city_name}
                    </div>
                    <div className='mt-1 fs-18 fs-md-16'>business directory</div>
                </div>
            </div>

            <div className="main-contact-search text-lowercase sticky-navigation">

                <div className="header-contact-search border-0">
                    <div className="container-contact-search">

                        <div className="container">
                            <div className='directory-search-btn-option d-flex justify-content-between'>
                                {searchContentKeywordHtml()}

                                <div className="btn-option  pl-3">
                                    {Utilities.arraySum(Object.values(counterProfiles)) > 0 && <div className="btn-icon btn-circle-info" title="profile statistic" onClick={() => handleShowStatsPopUp()}></div>}
                                    <div className="btn-icon  btn-map-pin" title="change city" onClick={() => handleShowPopUp()}></div>
                                </div>
                            </div>
                        </div>


                        <div className="breadcrumbs-navigation d-flex align-items-center">
                            <div className="container">
                                <ul className="ul-contact-head btn-option form-theme">
                                    <li className='overflow-x-hidden w-100'>
                                        <ul id="list-nav-letters" className="letter-list-selection d-flex align-items-center justify-content-between fs-18 fs-md-16 color-theme-grey">
                                            {
                                                letters.map(letter =>
                                                    <li key={letter}
                                                        id={`letter-${letter}`}
                                                        className={`${(selectedLetter === letter && lettersAvailable.includes(letter)) ? 'current' : ''}  ${!lettersAvailable.includes(letter) ? ' opacity-05 cursor-notallow ' : ''}`}
                                                        onClick={() => switchLetter(letter)}>{letter}
                                                    </li>
                                                )}
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="contact-listing-wrap">
                    <div className="container pt-3 pb-5">
                        {
                            gettingProfilesProcessing ?
                                loaderProfileHtml()
                                :
                                <>
                                    {
                                        Object.entries(listProfiles).length === 0 ?
                                            <NoRecordAvailable style="text-center text-lowercase no-record-label  no-chats-label " >{Utilities.labelNoFindResults}</NoRecordAvailable>
                                            :
                                            <div id="contact-listing" className="contact-listing search-result-list">
                                                <ul id="profile-listing-contact" className="profile-listing">
                                                    {Object.entries(listProfiles).map(([key, row]) => profileListingHtml(key, row))}
                                                </ul>
                                            </div>
                                    }
                                </>
                        }

                    </div>
                </div>


                <MainSearchProfile handleShowProfilePopup={show} handleCloseProfilePopup={handleClose} profileSelected={profileSelected} showStatus={profileShowStatus} closeSearchBox={closeSearchBox} />

                <CityAreaSearch showCityPopup={showCityPopup} handleClosePopUp={handleClosePopUp} passCityDetail={passCityDetail} />

                <Modal id="popup-directory-contact-statistics" show={showStatsPopup} onHide={handleCloseStatsPopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
                    <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                    <Modal.Body className='px-5  mt-n5 text-lowercase font-gotham-book fs-18 fs-md-16'>

                        <div className='py-4'>

                            <div className='text-center'>
                                <div className='color-theme-grey'>registrations</div>
                                <div className='font-gotham-bold fs-25 '>{currentLocation.city_name}</div>
                            </div>

                            <div className='row my-3 py-3 text-center'>
                                <div className='col-md-6 py-3'>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-circle-briefcase.svg`} className="logo-size-50 logo-size-md-60  border-0 mx-auto mb-2" />
                                    <div>business</div>
                                    <div className='font-gotham-bold fs-25 '>{counterProfiles.company}</div>
                                </div>
                                <div className='col-md-6 py-3 border-left'>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-circle-profile.svg`} className="logo-size-50 logo-size-md-60 border-0 mx-auto mb-2" />
                                    <div>users</div>
                                    <div className='font-gotham-bold fs-25 '>{counterProfiles.user}</div>
                                </div>

                            </div>

                            <button type="button" className="btn-theme-black" onClick={() => handleCloseStatsPopUp()}>close</button>
                        </div>

                    </Modal.Body>
                </Modal>

            </div>
        </>
    )
}

export default ContactSearch;
