

import React from 'react';
import TitlesLabels from '../../helpers/TitlesLabels';

const NoRecordAvailable = (props) => {
    return (
        <div className={`${props.style ?? 'text-center text-lowercase no-record-label mt-5'}`}>{props.children ?? TitlesLabels.general.noRecordAvailable}</div>
    )
}

export default NoRecordAvailable;