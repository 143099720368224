import React from 'react';
import Utilities from '../../helpers/Utilities';
import NoRecordAvailable from '../common/NoRecordAvailable';

const DirectorySearchNoRecord = (props) => {
    return (<NoRecordAvailable style={`text-center text-lowercase no-record-label  no-chats-label ${props.iconClass ?? ''}`} >{props.text ?? Utilities.labelNoFindResults}</NoRecordAvailable>)

}


export default DirectorySearchNoRecord;