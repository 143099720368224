import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { getCompanyAppLinks } from '../../helpers/QueryCompanyUtilities';
import Utilities from '../../helpers/Utilities';
import { CicleLoader } from '../common/Loaders';

const CompanyAppLinks = (props) => {

    const accountId = parseInt(props.accountId ?? 0);
    const [appLinks, setAppLinks] = useState({});
    const [processing, setProcessing] = useState(false);

    const getProfileAppLinks = async () => {
        setProcessing(true);
        const responseDetail = await getCompanyAppLinks(accountId);
        if (parseInt(responseDetail.status) === 1) {
            setAppLinks(responseDetail);
        } else {
            setAppLinks({});
        }
        setProcessing(false);
    }

    useEffect(() => {

        if (accountId > 0 && (props.showPopup ?? false) == true) {
            getProfileAppLinks();
        }

    }, [props.showPopup]);

    return (
        <Modal show={props.showPopup} onHide={props.handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton className="border-bottom-0">
                <h2 className="text-center font-gotham-bold fs-25 w-100 mt-3 text-lowercase">company app links</h2>
            </Modal.Header>
            <Modal.Body className="px-5 pb-5">
                {
                    processing ?
                        <CicleLoader />
                        :

                        Object.keys(appLinks).length > 0 ?
                            <ul className="list-company-detail-option justify-content-around mb-3 mt-2 px-4">
                                {!Utilities.isEmpty(appLinks.appstore_link) && <li><a href={`${appLinks.appstore_link}`} className="btn-company-detail-icon store-apple" target='_blank'></a></li>}
                                {!Utilities.isEmpty(appLinks.playstore_links) && <li><a href={`${appLinks.playstore_links}`} className="btn-company-detail-icon store-google" target='_blank'></a></li>}
                                {!Utilities.isEmpty(appLinks.appgalery_links) && <li><a href={`${appLinks.appgalery_links}`} className="btn-company-detail-icon store-huawei" target='_blank'></a></li>}
                            </ul>
                            :
                            <div className='text-center fs-16 fs-md-18 text-gray'>no links available</div>

                }

            </Modal.Body>

        </Modal>
    )
}

export default CompanyAppLinks;