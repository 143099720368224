import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
//import moment from 'moment';

import { showHeader, showFooter, pageTitle, passImageUrl, passChatProfile, newCityIDRequest, reloadAccountsDB, passConversList, refershDashboadConversation as refershDashboadConversationReducer, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showPopupTermsConditions, showLoader, shareQRCode } from '../../actions';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import ProfileDetail from '../../components/popups/ProfileDetail';
import WelcomeMessage from '../../components/popups/WelcomeMessage';

import BreadcrumbProfile from '../../layout/BreadcrumbProfile';
import BreadcrumbInternal from '../../layout/BreadcrumbInternal';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import SessionUtilities from '../../helpers/SessionUtilities';
import QueryUtilities from '../../helpers/QueryUtilities';
import MainSearchProfile from '../../components/popups/MainSearchProfile';

//import CommonProfileLogo from '../components/common/CommonProfileLogo';
import DashboardProfileListing from '../../components/common/DashboardProfileListing';
import DashboardProfileSearch from '../../components/common/DashboardProfileSearch';
import CampaignReferralPopup from '../../components/popups/CampaignReferralPopup';
import CampaignDownloadAppPopup from '../../components/popups/CampaignDownloadAppPopup';
import BusinessBoostCompetition from '../../components/common/BusinessBoostCompetition';
import { getRefferalDetailByAccountId } from '../../helpers/QueryReferral';
import { checkingFollowDailyGlanceStatus } from '../../helpers/QueryDailyGlance';
import { gettingMenuSettinListStatus } from '../../helpers/QueryDashboardUtilities';

import BreadcrumbsGlance from '../../components/common/BreadcrumbsGlance';
import { compose } from 'redux';
import NoRecordAvailable from '../../components/common/NoRecordAvailable';

const Dashboard = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const isMobileDevice = Utilities.isMobileByScreenSize();

    const refershDashboadConversation = useSelector(state => state.refershDashboadConversation);
    const switchAccount = useSelector(state => state.switchProfile);

    const [paginationLoader, setPaginationLoader] = useState(false);
    const [paginationNumber, setPaginationNumber] = useState(0);
    const [globalPassvalConversation, setGlobalPassvalConversation] = useState({});

    const [showBizcomLoader, setShowBizcomLoader] = useState(false);
    const [requestJazenetProfileID, setRequestJazenetProfileID] = useState(0);

    const [showMoreOption, setShowMoreOption] = useState(false);
    const [listProfiles, setListProfiles] = useState([]);
    //const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    //const [ profileSort, setProfileSort ] = useState(false);
    const [loggedInProfile, setLoggedInProfile] = useState({});
    const [showSwitchInternalButton, setShowSwitchInternalButton] = useState(false);
    const [contentLoaderClass, setContentLoaderClass] = useState('animate');
    const [showEmpty, setShowEmpty] = useState(false);
    const [allowNewChat, setAllowNewChat] = useState(true);
    const [rowProfileSelected, setRowProfileSelected] = useState({});

    const [profileSelectedDirectory, setProfileSelectedDirectorySearch] = useState({});
    const [showProfileSelectedDirectory, setShowProfileSelectedDirectory] = useState(false);

    const [allConversationResults, setAllConversationResults] = useState({});

    const [popupWelcomeMessage, setPopupWelcomeMessage] = useState(false);

    const [showCampaignDownload, setShowCampaignDownload] = useState(false);
    const [showCampaignReferral, setShowCampaignReferral] = useState(false);


    const [showBusinessBoost, setShowBusinessBoost] = useState(false);
    const [businessBoostCounter, setBusinessBoostCounter] = useState(0);

    const [showDailyGlanceNav, setShowDailyGlanceNav] = useState(false);
    const [showDailyGlanceNewRegisteredStatus, setShowDailyGlanceNewRegisteredStatus] = useState('1');

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            //setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const handleCloseProfileDirectory = () => {
        setProfileSelectedDirectorySearch({});
        setShowProfileSelectedDirectory(false);
    }

    const handleCloseWelcomeMessage = (action = 0) => {
        action = parseInt(action);
        setPopupWelcomeMessage(false);
        setShowDailyGlanceNewRegisteredStatus('1');

        if (action === 1) {
            history.push(`/${UrlSlugs.signup_companyInformation}`);

        } else if (action === 0) {

            setShowCampaignDownload(true);

            // CHECK iF COMPAIGN 
            const compaignShowPopup = SessionUtilities.getSignupCampaignDownload();
            if ((compaignShowPopup ?? 0) === 1) {
                //setShowCampaignDownload(true);
                SessionUtilities.removeSignupCampaignDownload();
            }

        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const resetSearchValue = () => {
        //setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }


    const toggleMainSearch = (action = 0) => {
        //dispatch( showMainSearch(action) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(action)}`);
    }

    const showChat = async (profileChat = {}) => {

        setRowProfileSelected(profileChat);

        let flagId = profileChat.hasOwnProperty('flag') ? profileChat.flag : 0;
        let profileChatConversation = {}, passValues = "", passval = {}, conversationDetailResponse = {};
        flagId = parseInt(flagId);

        if (flagId === 6) {
            passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: loggedInProfile.account_id.toString(),
                group_id: profileChat.id.toString(),
                convers_id: profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(passval);

        } else {
            passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: loggedInProfile.account_id.toString(),
                account_type: loggedInProfile.account_type.toString(),
                convers_id: profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passval);
        }



        if (Object.keys(conversationDetailResponse).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if (parseInt(conversationDetailResponse.success) === 0) {
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;

        }

        profileChatConversation = flagId !== 6 ?
            (conversationDetailResponse.result.beonchat_conversation ?? conversationDetailResponse.result.conversation_details) :
            (conversationDetailResponse.result.company_broadcast_conversation_details ?? conversationDetailResponse.result.company_group_conversation_details);

        passValues = ChatUtilities.encryptChatDetail(profileChatConversation);
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'dashboard');


        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileChatConversation.account_id))}/${passValues}`);

    }

    const noConversation = () => {
        setContentLoaderClass('');
        setListProfiles([]);
        setShowEmpty(true);
    }

    const getRecentConversation = async (account = {}, refresh = 0) => {
        refresh = parseInt(refresh);


        if (refresh === 0) {
            setContentLoaderClass('animate');
        }

        let master_account_id = account.account_id;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);
            accountsList = Object.values(accountsList);
            master_account_id = accountsList[0].account_id;
        }

        let flag = '1';
        let account_department_id = '0';
        if (account.hasOwnProperty('group_id')) {
            account_department_id = account.group_id.toString();
            flag = '4';
        }

        let passValues = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: account.account_id.toString(),
            account_type: account.account_type.toString(),
            master_account_id: master_account_id.toString(),
            flag: flag.toString(),
            group_id: account_department_id.toString(),
        }

        setGlobalPassvalConversation(passValues);

        //console.log(ApiUrls.bizcomWebHomeDetails, passValues);

        await axios
            .post(ApiUrls.bizcomWebHomeDetails, passValues)
            .then(response => {

                setContentLoaderClass('');
                dispatch(refershDashboadConversationReducer(''));

                if (parseInt(response.status) === 201) {
                    if (parseInt(response.data.success) === 1) {

                        const conversationResult = response.data.result.beonchat_home_details ?? {};

                        dispatch(passConversList(conversationResult));

                        const glanceStatus = conversationResult.home_glance_status ?? "0";

                        setAllConversationResults(conversationResult);
                        setShowDailyGlanceNav(glanceStatus == "1" ? true : false);


                        // CHECK IF NEW REGISTER FROM LENASIA CITY
                        const glanceSignupStatus = parseInt(SessionUtilities.getSignupGlanceStatus() ?? 0);
                        const glanceSignupStatusFinal = glanceSignupStatus === 1 ? 2 : 1;
                        setShowDailyGlanceNewRegisteredStatus(glanceSignupStatusFinal.toString());


                        if (Object.keys(conversationResult.home_conversation_list ?? []).length > 0) {

                            let conversationListingTemp = [];
                            Object.entries(conversationResult.home_conversation_list).map(([key, row]) => {

                                let converDetail = Utilities.formatConversationList(row);

                                if (converDetail.hasOwnProperty('department_name') && !Utilities.isEmpty(converDetail.department_name)) {
                                    if (converDetail.name.toLowerCase() === converDetail.title.toLowerCase()) {
                                        converDetail = { ...converDetail, name: row.name.toLowerCase(), title: row.department_name.toLowerCase() };
                                    }
                                }

                                conversationListingTemp.push(converDetail);
                            });

                            setListProfiles(conversationListingTemp);
                            setShowEmpty(false);

                        } else {
                            noConversation();
                        }

                    } else {
                        noConversation();
                    }

                } else {
                    noConversation();

                }

            })
            .catch((err) => {
                PagesAuthentication.logoutDirectly();

                noConversation();
                dispatch(refershDashboadConversationReducer(''));
            });


        passValues = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: account.account_id.toString(),
            account_type: account.account_type.toString(),
            flag: flag.toString(),
            group_id: account_department_id.toString(),
        }

        const menuSettingRes = await gettingMenuSettinListStatus(passValues);
        SessionUtilities.setLocalSessionMenuSettingsStatus(menuSettingRes);

    }

    const paginationContacts = async (elemId = '') => {
        const reachedScrollTopBool = Utilities.getElementSrollPosition(elemId);
        if (reachedScrollTopBool === true && paginationLoader === false) {

            let paginationNumberTemp = paginationNumber + 1;
            const passval = { ...globalPassvalConversation, page: paginationNumberTemp.toString() };

            setPaginationLoader(true);
            const contactListResponse = await QueryChatUtilities.getContactPagination(passval);
            setPaginationLoader(false);

            if (contactListResponse.length > 0) {

                let conversationListingTemp = [];
                Object.entries(contactListResponse ?? []).map(([key, row]) => {
                    conversationListingTemp.push(Utilities.formatConversationList(row));
                });

                const updatedList = [...listProfiles, ...conversationListingTemp];
                setListProfiles(updatedList);
                setPaginationNumber(paginationNumberTemp);

                SessionUtilities.setDashboadLoadedListTemp(updatedList);

                jQuery(`#${elemId}`).stop().animate({
                    scrollTop: parseInt(document.querySelector(`#${elemId}`).scrollTop) + 200

                }, 500, '', function () { });
            }

        }
    }


    const refresLoggedinProfile = async () => {

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if (Object.keys(loginProfileInfo).length > 0) {

                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setLoggedInProfile(profileLoggedinTemp);
                await getRecentConversation(profileLoggedinTemp);

                // RESTRICT NEW CHAT / REMOVE
                if (profileLoggedinTemp.hasOwnProperty('flag_handle_status')) {
                    if (parseInt(profileLoggedinTemp.flag_handle_status || 0) === 0) {
                        setAllowNewChat(false);
                    }
                } else {
                    setAllowNewChat(true);
                }

            }

        }


        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);
            const accountsCounter = Object.keys(accountsList).length;
            if (parseInt(accountsCounter) > 1) {
                setShowSwitchInternalButton(true);
            }
        }

    }


    const blockOrCounter = (chatDetail) => {
        let html_return = '';

        if (chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0) === 1) {
            html_return = <div className="onmute-icon"></div>
        }

        if (chatDetail.hasOwnProperty('block_status')) {
            const blockValue = parseInt(chatDetail.block_status.block_in_status || 0) + parseInt(chatDetail.block_status.block_out_status || 0);
            if (parseInt(blockValue) > 0) {
                html_return = <span className="blocked-icon"></span>
            }
        }

        if (chatDetail.hasOwnProperty('unread_count') && parseInt(chatDetail.unread_count || 0) > 0) {
            html_return = <> {html_return} <div className="unread-counter">{Utilities.counterMore(chatDetail.unread_count)}</div></>
        }

        return html_return;
    }

    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const handleClickQuickLinks = () => {
        dispatch(showPopupQuickLinksReducer(true));
    }


    const getCompanyDetailDirectory = async (account_id = 0) => {
        account_id = parseInt(account_id);

        if (account_id > 0) {

            dispatch(showLoader(1));
            let responseProfileDetail = await QueryUtilities.getProfileDetail(account_id);
            dispatch(showLoader(0));

            if (Object.keys(responseProfileDetail).length === 0) {
                return false;
            }

            responseProfileDetail = {
                ...responseProfileDetail,
                ...responseProfileDetail.location
            }

            setProfileSelectedDirectorySearch(responseProfileDetail);
            setShowProfileSelectedDirectory(true);

            history.push(`/${UrlSlugs.dashboard}`);

        }
    }


    const handleClosePopUpCampaignReferral = () => {
        setShowCampaignReferral(false);
        setShowCampaignDownload(true);
        SessionUtilities.removeSignupCampaignDownload();
    }

    const handleClosePopUpCampaignDownload = () => {
        setShowCampaignDownload(false);
        SessionUtilities.removeSignupCampaignDownload();
        setShowDailyGlanceNewRegisteredStatus('3');
    }

    const checkingCompainFromSignupCompany = () => {

        // CHECK iF COMPAIGN 
        const compaignShowPopup = SessionUtilities.getSignupCampaignDownload() ?? 0;
        if (compaignShowPopup === 2) {
            setShowCampaignDownload(false);
            setShowCampaignReferral(true);
            SessionUtilities.removeSignupCampaignDownload();
        }
    }

    /* const showBoostCompetitionContent = async () => {
        const teamLinked = SessionUtilities.getAllTeamMembersLinkedFromMaster();
        if (teamLinked.length > 0) {
            const companyId = parseInt(teamLinked[0].team_company_id ?? 0);
            setShowBusinessBoost(companyId > 0 ? true : false);

            // GET REFERREAL DETAIl
            const masterDetail = PagesAuthentication.getMasterUserDetailDetail();
            if (Object.keys(masterDetail).length > 0) {
                const referralDetail = await getRefferalDetailByAccountId({ account_id: masterDetail.account_id });

                if (Object.keys(referralDetail.detail ?? {}).length > 0) {
                    const companyAccountList = referralDetail.detail.company_account_list ?? '',
                        userAccountList = referralDetail.detail.user_account_list ?? '';

                    let companyAccountListArray = [], userAccountListArray = [], mergeAccountsArray = [];

                    if (companyAccountList !== '') {
                        companyAccountListArray = companyAccountList.split(',');
                    }

                    if (userAccountList !== '') {
                        userAccountListArray = userAccountList.split(',');
                    }

                    mergeAccountsArray = userAccountListArray.concat(companyAccountListArray);
                    setBusinessBoostCounter(mergeAccountsArray.length);

                }


            }

        }
    } */

    /* const checkingGlanceStatus = async () => {
        let dailyGlanceStatus = false;
        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (glanceStatus) {
            dailyGlanceStatus = glanceStatus;
        }
        setShowDailyGlanceNav(dailyGlanceStatus);
    } */

    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.dashboard));
        dispatch(showHeader(1));
        dispatch(showFooter(1));


        // FORCE TO LOGOUT IF MOBILE DEVICES
        if (isMobileDevice) {
            //PagesAuthentication.logoutUser();
            history.push(`/${UrlSlugs.logout}`);
        }

        PagesAuthentication.getUserLoggedin();

        // IF LOCATION CHANGE FROM BIZCOMPLUS
        let cityIDRequest = match.params.cityid;
        if (typeof cityIDRequest !== 'undefined') {

            const defaultLocation = Utilities.defaultLocation();
            let userCurrentCityId = defaultLocation.city_id || 0;

            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if (userLocation !== null) {
                userLocation = JSON.parse(userLocation);
                userCurrentCityId = userLocation.city_id;
            }

            userCurrentCityId = parseInt(userCurrentCityId || 0);
            cityIDRequest = parseInt(atob(cityIDRequest));

            if (userCurrentCityId !== cityIDRequest) {
                dispatch(newCityIDRequest(cityIDRequest));
            }

            dispatch(reloadAccountsDB(2)); // load session accounts from DB // updates is located in header.js
            history.push(`/${UrlSlugs.dashboard}`);
        }


        const sessionWelcomeMessage = parseInt(SessionUtilities.getSignupWelcomeMessage() ?? 0);
        if (sessionWelcomeMessage > 0) {
            setPopupWelcomeMessage(true);
            SessionUtilities.removeSignupWelcomeMessage();
        }

        /* const sessionWelcomeMessage = localStorage.getItem(process.env.REACT_APP_session_welcome_mesage);
        if ( sessionWelcomeMessage!==null ){
            setPopupWelcomeMessage(true);
            localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);
        } */

        dispatch(passChatProfile({}));


        let sessionJazenetRequestProfileID = sessionStorage.getItem(process.env.REACT_APP_session_jazenet_request_profileid);
        if (sessionJazenetRequestProfileID !== null) {

            if (parseInt(sessionJazenetRequestProfileID) > 0) {
                setRequestJazenetProfileID(sessionJazenetRequestProfileID);
                sessionStorage.removeItem(process.env.REACT_APP_session_jazenet_request_profileid);
            }
        }

        window.onpopstate = () => {
            const mainPopUp = jQuery('.main-search-component.show').length;
            if (mainPopUp === 1) {
                toggleMainSearch(0);
                //window.history.forward();
                history.push(`/${UrlSlugs.dashboard}`);
            }

        }

        // CLOSE TERMS POPUP WHEN IN CREATING COMPANY
        dispatch(showPopupTermsConditions(false));


        // PROFILE DIRECTORY SEACH 
        let paramsDirectConnectAccountId = match.params.accountid ?? '';
        if (!Utilities.isEmpty(paramsDirectConnectAccountId)) {
            await getCompanyDetailDirectory(atob(paramsDirectConnectAccountId));
        }


        checkingCompainFromSignupCompany();

        // REMOVED
        //showBoostCompetitionContent();

        // DELETE CAMPAIGN DETAIL - moved to setting create company
        //SessionUtilities.deleteSignupCampaign();


        // RESET SHAREQR CODE
        dispatch(shareQRCode(parseInt(0)));

        // CALL GLANCE STATUS
        //await checkingGlanceStatus();

        return () => {
            dispatch(refershDashboadConversationReducer(''));
            setShowDailyGlanceNewRegisteredStatus('1');
        }

    }, []);


    useEffect(() => {

        const reqAccountID = match.params.id;
        if (typeof reqAccountID === 'undefined') {
            setShowBizcomLoader(false);

            setShowEmpty(false);
            refresLoggedinProfile();
            setListProfiles([]);

            /* SessionUtilities.setDashboadInternalLoadedStatus(0);
            SessionUtilities.setDashboadLoadedInternalListTemp([]); */

            //SessionUtilities.setDashboadLoadedStatus(0);
            //SessionUtilities.setDashboadLoadedListTemp([]);
        }

    }, [match.params.id]);


    useEffect(async () => {

        if (!Utilities.isEmpty(switchAccount.toString()) && parseInt(switchAccount) > 0) {

            const reqAccountID = match.params.id;
            if (typeof reqAccountID !== 'undefined') {
                return false;
            }

            SessionUtilities.setDashboadLoadedStatus(0);
            SessionUtilities.setDashboadLoadedListTemp([]);

            setShowEmpty(false);
            refresLoggedinProfile();
            setListProfiles([]);
            //showBoostCompetitionContent();

            //await checkingGlanceStatus();
        }

    }, [switchAccount]);


    useEffect(() => {

        const reqAccountID = match.params.id;
        if (typeof reqAccountID !== 'undefined') {
            return false;
        }

        const isContentHigher = Utilities.checkContentIfHigher('listing-profiles');
        setShowMoreOption(isContentHigher);

    }, [listProfiles]);


    useEffect(async () => {

        if (!Utilities.isEmpty(refershDashboadConversation)) {
            let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if (loginProfileInfo !== null) {
                loginProfileInfo = JSON.parse(loginProfileInfo);

                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                await getRecentConversation(profileLoggedinTemp, 1);

            }
        }

    }, [refershDashboadConversation]);



    return (

        <div className="dashboad-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li className="btn-option">
                            <div></div>
                        </li>

                        {/* {
                            Utilities.isEmpty(contentLoaderClass)  &&  */}
                        <li className="btn-option">
                            {
                                showDailyGlanceNav && <BreadcrumbsGlance status={showDailyGlanceNewRegisteredStatus} />
                            }


                            <div className="btn-icon btn-circle-arrow-right" onClick={() => handleClickQuickLinks()} title={TitlesLabels.general.quickLinks}></div>
                            {
                                allowNewChat === true &&
                                <>
                                    <BreadcrumbProfile allConversationResults={allConversationResults} showLabel={false} />
                                    {showSwitchInternalButton && <BreadcrumbInternal allConversationResults={allConversationResults} showLabel={false} />}
                                    <div className="btn-icon btn-magnify" onClick={() => toggleMainSearch(3)} title={TitlesLabels.general.directorySearch}></div>
                                </>
                            }

                            <Link to={`/${UrlSlugs.settings_notifications}`} className="btn-icon btn-bell" title={TitlesLabels.general.newNotifications}>
                                {parseInt(allConversationResults.home_notice_notification_count ?? 0) > 0 && <span className="counter">{Utilities.counterMore(allConversationResults.notice_notification_count ?? 0)}</span>}
                            </Link>

                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.setting}></div>

                        </li>
                        {/* } */}

                    </ul>
                </div>

            </div>

            <div className="main-content">

                {
                    allowNewChat === true &&
                    <div className="content-subnav-wrap">
                        <div className="container py-2 d-flex align-items-center justify-content-between">
                            <div className="btn-new-chat btn-icon-wrap cursor-pointer fs-16 border-radius" onClick={() => toggleMainSearch(1)}>new chat</div>

                            <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius overflow-hidden  pl-0 side-right">
                                <DashboardProfileSearch pushValue={pushValue} resetSearchValue={resetSearchValue} placeholder="search current chat" />
                            </div>

                        </div>
                    </div>
                }

                <BusinessBoostCompetition showStatus={showBusinessBoost} counter={businessBoostCounter} />

                <div className="container">

                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="dashboard-list-preloader mt-3">
                            {
                                [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                                    <li key={key}>
                                        <div className="profile-image"></div>
                                        <div></div>
                                        <div></div>
                                    </li>
                                )
                            }
                        </ul>
                    }


                    {
                        showBizcomLoader === false &&

                        <>

                            {
                                showEmpty === false ?
                                    Object.keys(listProfiles).length > 0 &&
                                    <>
                                        <ul id="listing-profiles" className={`list-profile-messages max-scroll mt-3 ${!Utilities.isEmpty(refershDashboadConversation) ? 'processing-latest-loader' : ''}`} onScroll={(e) => paginationContacts('listing-profiles')}>
                                            {
                                                Object.entries(listProfiles).map(([key, row]) =>
                                                    <DashboardProfileListing  key={key} keyId={key} row={row} rowProfileSelected={rowProfileSelected} showChat={showChat} blockOrCounter={blockOrCounter} previewProfileImage={previewProfileImage} />
                                                )
                                            }
                                        </ul>

                                        {paginationLoader && <div className="content-loader list-loader animate"></div>}
                                    </>

                                    :
                                    <NoRecordAvailable style="text-center text-lowercase no-record-label no-chats-label">{Utilities.labelNoChatActive}</NoRecordAvailable>

                            }

                            {showMoreOption === true && <div className="scroll-option-wrap"><span className="btn-scroll-down" onClick={() => Utilities.scrollDownMore('listing-profiles')}></span></div>}

                        </>
                    }


                </div>
            </div>


            {parseInt(requestJazenetProfileID) > 0 && <ProfileDetail requestID={requestJazenetProfileID} />}

            <WelcomeMessage showPopup={popupWelcomeMessage} handleClosePopup={handleCloseWelcomeMessage} profileLoggedin={loggedInProfile} />

            <MainSearchProfile handleShowProfilePopup={showProfileSelectedDirectory} handleCloseProfilePopup={handleCloseProfileDirectory} profileSelected={profileSelectedDirectory} showStatus={3} />

            {showCampaignReferral && <CampaignReferralPopup showPopup={true} handleClosePopUp={handleClosePopUpCampaignReferral} />}

            {showCampaignDownload && <CampaignDownloadAppPopup showPopup={true} handleClosePopUp={handleClosePopUpCampaignDownload} />}


        </div>
    )
}

export default Dashboard;