import React, { useState, useEffect  } from 'react';
import QueryUtilities from '../../helpers/QueryUtilities';
import Utilities from '../../helpers/Utilities';
import { CicleLoader } from '../../components/common/Loaders';


const DocumentPreview = ( props ) => {

    const [ documentSelected , setDocumentSelected ] = useState({});
    const [ fileLoading, setFileLoading ] = useState(true);
    const [ encodedFile, setEncodedFile ] = useState({ filename : '', encoded : ''});

    const minFileSizeMb = Utilities.minPDFFileSize;

    const messageAlert = () => {
        Utilities.messagePopup('error', Utilities.labelCantDownloadFile );
    }

    const documentProperHtml = ( documentDetailProvided = {} ) => {

        let htmlOutput = '';

        if ( Object.entries(documentDetailProvided).length>0 ){

            let fileExtension = documentSelected.attachment.file_name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();

            const fileType =  documentSelected.attachment.file_type.toString()
            let filePath = ! Utilities.isEmpty(documentSelected.encoded) ?  'data:application/'+fileExtension+';base64,'+documentSelected.encoded : documentSelected.thumb_path;

            const htmlOutputTemp = <div className="document-preview-file file-item"><span>.{ documentDetailProvided.attachment.file_name.split('.').pop() }</span></div>
            
            if ( ['2'].includes(fileType) ){
                filePath = ! Utilities.isEmpty(documentSelected.encoded) ? 'data:image/jpeg;base64,'+documentSelected.encoded : documentSelected.thumb_path;
                htmlOutput = <img className="document-preview-file" src={filePath}/>

            }else if ( ['6'].includes(fileType) ){
                filePath = 'data:application/'+fileExtension+';base64,'+encodeURI(documentSelected.encoded);
                
                const bytes = (documentSelected.encoded.length - 814) / 1.37;
                const fileSize = Utilities.formatBytes(bytes);
                const fileSizeArray = fileSize.split(' ');
                const fileSizeExt = fileSizeArray[1].toLowerCase();
                const fileSizeAmount = fileSizeArray[0];

                htmlOutput = htmlOutputTemp;

                if ( ['kb', 'mb'].includes( fileSizeExt ) ){
                    htmlOutput = <iframe  width="640" height="650" src={`${filePath}#toolbar=0&view=Fill`}></iframe>

                    if ( fileSizeExt==='mb' && parseFloat(fileSizeAmount)>minFileSizeMb ){
                        htmlOutput = htmlOutputTemp;
                    }
                }

            }else{
                htmlOutput = htmlOutputTemp;
            }
        }

        return htmlOutput;
    }

    useEffect( async () => {

        if ( Object.entries(props.documentSelected ?? {}).length>0 ){

            let documentPassed = props.documentSelected;
            setFileLoading( true );
            
            const responseRequestedDecoded = await QueryUtilities.getDocumentEncoded( documentPassed.attachment );
            setFileLoading( false );
            let encodedFiles = '';

            if ( ! Utilities.isEmpty(responseRequestedDecoded) ){
                encodedFiles =responseRequestedDecoded;

                let fileExtension = documentPassed.attachment.file_name.split('.').pop();
                fileExtension = fileExtension.toLowerCase();

                const filePath = ! Utilities.isEmpty(encodedFiles) ?  'data:application/'+fileExtension+';base64,'+encodedFiles : '';
                setEncodedFile({ ...encodedFile, filename : documentPassed.title+'.'+fileExtension, encoded : filePath});
            }
            
            documentPassed = {...documentPassed, encoded : encodedFiles};
            setDocumentSelected( documentPassed );
        }

        return () =>{
            setFileLoading( false );
        }
       
    }, [props.documentSelected] );

    return (
        <div className="document-review">
            <div className="btn-close" onClick={ () => props.setDocumentSelected({}) }></div>
            
                { 
                    fileLoading ?
                        <div className="document-preview-file file-item bg-image-none"><CicleLoader /></div>
                    :
                        <>
                            { documentProperHtml( documentSelected ) }

                            <div className="w-100 py-4 font-gotham-bold fs-18 fs-md-18">{documentSelected.title}</div>
                            <div className="w-100 btn-option d-flex align-items-center justify-content-center">
                                <div className="btn-icon btn-refresh" onClick={ () => props.handleDocumenForm('edit', documentSelected) } title="edit"></div>
                                { 
                                    ! Utilities.isEmpty(encodedFile.encoded) ?
                                        <a className="btn-icon btn-download ml-4" download={encodedFile.filename} href={encodedFile.encoded} title="download"></a>
                                    : 
                                        <div className="btn-icon btn-download ml-4" onClick={ () => messageAlert() }  title="download"></div>
                                }
                                <div className="btn-icon btn-trash-thin ml-4" onClick={ () => props.handleDeleteDocument( documentSelected.id.toString() ) }  title="delete"></div>
                            </div>
                        </>
                }
        </div>
    )
}

export default DocumentPreview;