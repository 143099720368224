import React, { useState, useEffect } from 'react';
import jQuery from 'jquery';
import Utilities from '../../../helpers/Utilities';

import PagesAuthentication from '../../../helpers/PagesAuthentication';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import { getProfileList } from '../../../helpers/QueryDepartmentBulkMessage';
import SessionUtilities from '../../../helpers/SessionUtilities';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';
import { CicleLoader } from '../../../components/common/Loaders';


const SidebarDepartmentProfileSelection = (props) => {

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    //const [ userLoginDetail , setUserLoginDetail ] = useState({});
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);

    const [toggleAll, setToggleAll] = useState(false);
    const [accountIDs, setAccountIDs] = useState([]);
    const [accountIDsAll, setAccountIDsAll] = useState([]);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleMemberAll = () => {

        if (toggleAll === true) {
            setAccountIDs([]);
        } else {
            setAccountIDs([...accountIDsAll]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const toggleMemberInput = (e, row) => {
        let accountIDsTemp = accountIDs;
        let id = row.account_id.toString();

        (!accountIDsTemp.includes(id)) ? accountIDsTemp.push(id) : (accountIDsTemp = [...Utilities.removeValueInArray(accountIDsTemp, id)]);
        accountIDsTemp = Utilities.arrayUnique(accountIDsTemp);

        setAccountIDs(accountIDsTemp);
        setToggleAll(accountIDsTemp.length === accountIDsAll.length ? true : false);
    }

    const handleClickSubmitMembers = () => {
        if (parseInt(accountIDs.length) > 0) {

            const accountConversId = [];
            Object.values(listProfiles).map(row => {
                if (accountIDs.includes(row.account_id.toString())) {
                    accountConversId.push({
                        account_id: row.account_id.toString(),
                        account_type: row.account_type.toString(),
                        convers_id: row.convers_id.toString(),
                    });
                }
            });

            props.handleSelectedMembers(accountConversId);
        }
    }

    const gettingProfileList = async (profile = {}) => {

        //const masterUserDetailDetail = PagesAuthentication.getMasterUserDetailDetail();
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        const passval = {
            api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
            //master_account_id: masterUserDetailDetail.account_id.toString(),
            account_id: profile.account_id.toString(),
            account_type: profile.account_type.toString(),
            flag: "4",
            group_id: profile.group_id.toString(),
        };


        setAccountIDsAll([]);
        setListProfiles([]);
        setShowProcessing(true);

        const profileListRes = await getProfileList(passval), gIDs = [];
        setShowProcessing(false);


        if (Object.keys(profileListRes).length === 0) {
            // Utilities.messagePopup('error', errorMsg );
            return false;
        }

        Object.values(profileListRes).map(row => {
            gIDs.push(row.account_id.toString());
        });

        setListProfiles(profileListRes);
        setAccountIDsAll(gIDs);

    }

    useEffect(() => {

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        gettingProfileList(userLoginDetailTemp)

    }, []);

    return (
        <div className="notication-sidebar-followers chat-sidebar form-theme checkbox-radio-default checkbox-radio-check-icon form-theme broadcast-popup-content">
            <div className="btn-close-chat-sidebar" onClick={() => props.handleSelectedMembers()}></div>
            <div className="sidebar-title fs-20 fs-sm-20">send message</div>

            <div className="sidebar-block long-content">
                <div className="sidebar-block-content">

                    {
                        showProcessing ?
                            <CicleLoader />
                            :
                            <>
                                {
                                    Object.keys(listProfiles).length === 0 ?
                                        <NoRecordAvailable style="text-center text-lowercase no-record-label mt-5 ">{Utilities.labelProfileAvailable}</NoRecordAvailable>
                                        :
                                        <>
                                            <div className="form-group border-0">
                                                <input type="text" id="input_search_team" className="form-control text-lowercase input-search bg-gray-important" placeholder=" " autoComplete="off" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} ></input>
                                            </div>

                                            <div className="select-all-wrap">
                                                <div className='font-gotham-bold fs-18 fs-md-18'>select all</div>
                                                <div className="mr-n1"><input type="checkbox" checked={`${toggleAll ? 'checked' : ''}`} onChange={(e) => toggleMemberAll()} /></div>
                                            </div>

                                            <ul id="listing-profiles-member" className="list-members mt-2" style={{ height: 300 }}>
                                                {
                                                    Object.entries(listProfiles).map(([key, row]) =>
                                                        <li key={key} data-keyword={row.name.toLowerCase()} className='border-bottom border-theme-gray'>
                                                            <div>
                                                                <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                                            </div>
                                                            <div>
                                                                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20" data-name={row.name}>{row.name}</div>
                                                                <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.title}</div>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox" checked={`${(accountIDs.includes(row.account_id.toString())) ? 'checked' : ''}`} onChange={(e) => toggleMemberInput(e, row)} />
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                            <div className="team-selected-counter mt-3 text-center"> {accountIDs.length} Selected</div>

                                            <div className="py-5">
                                                <button type="button" id="btn-bulk-send" className={`btn-theme-black button-has-loader ${parseInt(accountIDs.length) > 0 ? '' : 'opacity-05 cursor-notallow'} `} onClick={() => handleClickSubmitMembers()}>send</button>
                                            </div>


                                        </>
                                }
                            </>
                    }

                </div>
            </div>

        </div>
    )
}

export default SidebarDepartmentProfileSelection;