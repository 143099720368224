import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';
import Select from 'react-select';

import Utilities from '../../helpers/Utilities';
import QueryMenuPriceUtilities from '../../helpers/QueryMenuPriceUtilities';

import { toggleMenuPriceList as toggleMenuPriceListReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';
import SessionUtilities from '../../helpers/SessionUtilities';
import { CicleLoader } from '../common/Loaders';

const MenuPriceList = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const errorMsg = process.env.REACT_APP_message_error_process;

    const maxFileSizeMb = Utilities.maxPDFFileSize;
    const minFileSizeMb = Utilities.minPDFFileSize;

    const toggleMenuPriceList = useSelector(state => state.toggleMenuPriceList);

    let menu_list = [];
    Object.entries(Utilities.menuProductOption).map(([key, label]) => {
        menu_list.push({ value: parseInt(key), label: label });
    });

    const defaultFormValues = {
        title: menu_list[0].value,
        label: menu_list[0].label,
        status: 0,
        group_id: 0
    };

    const documentFilesizeDetailValues = {
        size: 0,
        ext: ''
    };

    const [fileExt, setFileExt] = useState('');
    const [titlePopup, setTitlePopup] = useState('add');
    const [showPopup, setShowPopup] = useState(false);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [accountDetail, setAccountDetail] = useState({});
    const [showEditButton, setShowEditButton] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [documentFileRequest, setDocumentFileRequest] = useState('');
    const [documentFileDetail, setDocumentFileDetail] = useState('');
    const [documentFilesizeDetail, setDocumentFilesizeDetail] = useState(documentFilesizeDetailValues);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;
        let value = '';

        if (fieldName === 'menu_title') {
            value = parseInt(e.value);
            formValuesTemp = { ...formValuesTemp, ...{ title: value, label: menu_list[value - 1].label } };

        } else {
            value = e.target.value;

            if (fieldName === 'status') {
                value = parseInt(formValuesTemp.status) === 1 ? 0 : 1;

                if (parseInt(formValues.group_id) > 0) {

                    confirmAlert({
                        title: '',
                        message: `do you wish to ${value === 0 ? 'de-' : ''}activate ${Utilities.menuProductOption[parseInt(formValues.title)].toString()}?`,
                        overlayClassName: '',
                        buttons: [
                            { label: 'no', onClick: () => { handleStatusConfirmation(value === 1 ? 0 : 1) } },
                            { label: 'yes', onClick: () => { handleStatusConfirmation(value === 1 ? 1 : 0) } },
                        ]
                    });

                } else {
                    formValuesTemp = { ...formValuesTemp, ...{ status: value } };
                }

            }
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const handleStatusConfirmation = (status = 0) => {

        if (parseInt(formValues.status) !== status) {
            let formValuesTemp = { ...formValues, ...{ status: status } };
            setFormValues(formValuesTemp);

            submitUpdateDetail(2, formValuesTemp); // update
        }
    }

    const handleClosePopup = () => {
        setShowPopup(false);
        setIsEdit(false);
        setDocumentFileRequest('');
        setDocumentFileDetail('');
        setFormValues(defaultFormValues);
        dispatch(toggleMenuPriceListReducer({}));
    }


    const previewFile = (e) => {
        let value = e.target.value;
        const fileExtension = value.substr((value.lastIndexOf('.') + 1)).toString().toLowerCase();
        setFileExt(fileExtension);

        if (fileExtension === 'pdf' || Utilities.validate_image_ext(fileExtension)) {

            const fileSize = Utilities.formatBytes(e.target.files[0].size);
            const fileSizeArray = fileSize.split(' ');
            const fileSizeExt = fileSizeArray[1].toLowerCase();
            const fileSizeAmount = fileSizeArray[0];

            if (['kb', 'mb'].includes(fileSizeExt)) {

                if (fileSizeExt === 'mb' && parseFloat(fileSizeAmount) > maxFileSizeMb) {
                    Utilities.messagePopup('warning', `filesize should not more than ${maxFileSizeMb}mb.`);
                    return false;
                }

                let fileSizeAmountInMb = 0;
                if (fileSizeExt === 'mb') {
                    fileSizeAmountInMb = fileSizeAmount;
                }

                setDocumentFilesizeDetail({ ...documentFilesizeDetailValues, size: parseFloat(fileSizeAmountInMb), ext: fileSizeExt });

                const reader = new FileReader();
                reader.onload = function (e) {
                    setDocumentFileRequest(e.target.result);
                }
                reader.readAsDataURL(e.target.files[0]);
                setDocumentFileDetail(e.target.files[0]);
            }


        } else {
            setDocumentFileRequest('');
            setDocumentFileDetail('');
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }

    const selectFile = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const getDetail = async (profileProvided = {}) => {

        if (Object.entries(profileProvided).length > 0) {
            const passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: profileProvided.account_id.toString(),
                company_id: profileProvided.team_company_id.toString(),
            }
            const requestResponse = await QueryMenuPriceUtilities.getDetail(passval);
            jQuery('#input_select_file').val('');

            if (Object.entries(requestResponse).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(requestResponse.success) === 0) {
                Utilities.messagePopup('error', requestResponse.errorMessage);
                return false;
            }

            const catalogDegtail = requestResponse;

            setIsProcessing(false);

            if (Object.entries(catalogDegtail).length === 0) {
                setFormValues(defaultFormValues);
                setShowEditButton(false);
                setIsEdit(true);
                setTitlePopup('add');
            } else {

                setTitlePopup('edit');
                setIsEdit(false);
                setFormValues({
                    title: parseInt(catalogDegtail.title),
                    label: menu_list[parseInt(catalogDegtail.title) - 1].label,
                    status: parseInt(catalogDegtail.status),
                    group_id: parseInt(catalogDegtail.group_id)
                });

                //setDocumentFileRequest( catalogDegtail.file_url.toString() );
                setDocumentFileDetail(catalogDegtail.file_path.toString());
                setShowEditButton(true);
            }
        }
    }

    const submitUpdateDetail = async (flagProvided = 0, valueProvided = {}) => {

        if (flagProvided === 0 && ![1, 2, 3].includes(flagProvided)) { return false; }

        const fileElem = jQuery('#input_select_file');
        const fileValue = fileElem.val();

        const passvalAccount = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: accountDetail.account_id.toString(),
            company_id: accountDetail.team_company_id.toString(),
        };

        let button_name = '';
        const button_edit = 'btn-edit-menuprice';
        let passvalMain = { ...passvalAccount };
        let formValuesRequest = formValues;

        if (Object.entries(valueProvided).length > 0) {
            formValuesRequest = valueProvided;
        }

        if ([1, 2].includes(flagProvided)) {

            button_name = flagProvided === 1 ? 'btn-submit-menuprice' : 'btn-update-menuprice';
            Utilities.elementDisabledStatus(button_name, true);

            if (!Utilities.isEmpty(fileValue)) {
                const formData = new FormData();
                formData.append('api_token', passvalAccount.api_token.toString());
                formData.append('account_id', passvalAccount.account_id.toString());
                formData.append('company_id', passvalAccount.company_id.toString());
                formData.append('file', documentFileDetail);

                const requestResponse = await QueryMenuPriceUtilities.uploadFile(formData);

                Utilities.elementDisabledStatus(button_name, false);
                if (parseInt(requestResponse.success) === 1) {

                    passvalMain = {
                        ...passvalMain,
                        group_id: flagProvided === 1 ? '0' : formValuesRequest.group_id.toString(),
                        flag: flagProvided.toString(),
                        type: formValuesRequest.title.toString(),
                        file_path: requestResponse.result.company_image_result.file_name ?? '',
                        status: formValuesRequest.status.toString()
                    };
                }

            } else {
                passvalMain = {
                    ...passvalMain,
                    group_id: flagProvided === 1 ? '0' : formValuesRequest.group_id.toString(),
                    flag: flagProvided.toString(),
                    type: formValuesRequest.title.toString(),
                    file_path: documentFileDetail.toString(),
                    status: formValuesRequest.status.toString()
                };
            }

        } else if (flagProvided === 3) {
            passvalMain = {
                ...passvalMain,
                group_id: formValuesRequest.group_id.toString(),
                flag: flagProvided.toString(),
                type: formValuesRequest.title.toString(),
                file_path: '',
                status: formValuesRequest.status.toString()
            };
            button_name = 'btn-delete-menuprice';
        }

        fileElem.val('');

        Utilities.elementDisabledStatus(button_name, true);

        if ([2].includes(flagProvided)) {
            Utilities.elementDisabledStatus(button_edit, true);
        }

        const requestResponse = await QueryMenuPriceUtilities.manageDetail(passvalMain);
        Utilities.elementDisabledStatus(button_name, false);

        if ([2].includes(flagProvided)) {
            Utilities.elementDisabledStatus(button_edit, false);
        }

        if (Object.entries(requestResponse).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }
        if (parseInt(requestResponse.success) === 0) {
            Utilities.messagePopup('error', requestResponse.errorMessage);
            return false;
        }


        if (flagProvided === 3) {
            handleClosePopup();
            Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyDeleted);
            return false;
        }

        Utilities.messagePopup('success', `Has been successfully ${flagProvided === 1 ? 'saved' : 'updated'}.`);
        getDetail(accountDetail);

    }


    const confirmDeletion = () => {

        confirmAlert({
            title: '',
            message: Utilities.labelAreYouWishToDelete.toString(),
            overlayClassName: '',
            buttons: [
                { label: 'no', onClick: () => { } },
                {
                    label: 'yes', onClick: () => {
                        submitUpdateDetail(3);
                    }
                }
            ]
        });


    }

    const fileUploadHtml = () => {

        let htmlContent = <div>
            <div className="document-file-icon"></div>
            <div className='font-gotham-book fs-md-14 color-theme-grey'>upload a file</div>
        </div>

        if (titlePopup === 'edit' && !isEdit) {
            htmlContent = <div><div className="preview-file-icon pt-4 font-gotham-bold color-theme-grey">.{documentFileDetail.split('.')[1]}</div></div>
        }

        return htmlContent;

    }

    useEffect(() => {

        if (Object.entries(toggleMenuPriceList).length > 0) {
            if (toggleMenuPriceList.hasOwnProperty('account_type') && parseInt(toggleMenuPriceList.account_type) === 4) {
                setTitlePopup('add');
                setIsProcessing(true);
                setAccountDetail(toggleMenuPriceList);
                getDetail(toggleMenuPriceList);
                setDocumentFileRequest('');
                setDocumentFilesizeDetail(documentFilesizeDetailValues);
                setShowPopup(true);
            }
        }

    }, [toggleMenuPriceList]);


    return (

        <Modal id="popup-menu-price-list" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme document-form-popup" keyboard={true}>
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">{titlePopup} menu/products</Modal.Header>
            <Modal.Body className="pt-2 pb-5">
                {
                    isProcessing === true ?
                        <CicleLoader />
                        :
                        <div className="col-11 mx-auto  form-theme">

                            <div className="pb-2">

                                <input type="file" id="input_select_file" name="file" className="invisible" accept={`${Utilities.attributeImageAcceptExtension},application/pdf`} readOnly onChange={(e) => previewFile(e)} ></input>

                                <div className={`font-gotham-medium-16 mb-3`}>
                                    <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3">
                                        <div className="mt-n1">active</div>
                                        <input type="checkbox" className="checkbox-onoff ml-2" value={formValues.status} checked={formValues.status === 1} onChange={(e) => pushValue(e, 'status')} ></input>
                                    </div>
                                </div>

                                <div className={` pt-2 ${isEdit === true ? '' : 'content-disabled'}`}>
                                    <div className="form-group active">
                                        <Select options={menu_list} id="menu_title" name="menu_title" onChange={(e) => pushValue(e, 'menu_title')} placeholder="select" className="boostrap-select2" defaultValue={{ value: formValues.title, label: formValues.label }} value={{ value: formValues.title, label: formValues.label }} />
                                        <label className="form-label">choose catergory</label>
                                    </div>

                                    <div className="upload-avatar avatar-wrap">
                                        <div className="w-100">
                                            <div className="document-upload-preview mt-4">
                                                {
                                                    Utilities.isEmpty(documentFileRequest) ?
                                                        fileUploadHtml()
                                                        :
                                                        <>
                                                            {
                                                                documentFilesizeDetail.size <= minFileSizeMb ?
                                                                    Utilities.validate_image_ext(fileExt) ?
                                                                        <div className="h-100 w-100" style={{ backgroundImage: `url(${documentFileRequest})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} ></div>
                                                                        :
                                                                        <iframe width="100%" height="100%" className="h-100" src={`${documentFileRequest}#toolbar=0&view=Fill&scrollbar=0`}></iframe>
                                                                    :
                                                                    fileUploadHtml()
                                                            }
                                                        </>
                                                }
                                            </div>
                                            <div className='profile-logo-change text-center'>
                                                <button type="button" className="btn-select-file font-gotham-bold bg-theme-green border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectFile()}> {titlePopup == 'add' ? 'choose' : 'change'} file </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div>
                                {
                                    showEditButton === true ?
                                        <div className="button-group-boolean">
                                            {
                                                isEdit === false ?
                                                    <>
                                                        <button type="button" id="btn-delete-menuprice" className="btn-theme-black button-has-loader" onClick={() => confirmDeletion()}>delete</button>
                                                        <button type="button" id="btn-edit-menuprice" className="btn-theme-black button-has-loader" onClick={() => setIsEdit(true)}>edit</button>
                                                    </>

                                                    :
                                                    <>
                                                        <button type="button" className=" btn-theme-black button-has-loader" onClick={() => setIsEdit(false)}>cancel</button>
                                                        <button type="button" id="btn-update-menuprice" className=" btn-theme-black button-has-loader" onClick={() => submitUpdateDetail(2)}>update</button>
                                                    </>
                                            }
                                        </div>
                                        :
                                        <button type="button" id="btn-submit-menuprice" className=" btn-theme-black button-has-loader" onClick={() => submitUpdateDetail(1)}>submit</button>
                                }
                            </div>

                        </div>
                }


            </Modal.Body>
        </Modal>
    )
}

export default MenuPriceList;
