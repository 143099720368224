import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import UrlSlugs from "../../helpers/UrlSlugs";
import { checkingFollowDailyGlanceStatus, getNotificationFeedFlashListApi, getNotificationListApi } from "../../helpers/QueryDailyGlance";
import Utilities from "../../helpers/Utilities";
import { pageTitle, showFooter, showHeader, showLoader, passImageUrl } from "../../actions";
import { Modal } from "react-bootstrap";
import NoRecordAvailable from "../../components/common/NoRecordAvailable";

const GlanceNotificationList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

    const [contentLoader, setContentLoader] = useState(true);
    const [notificationList, setNotificationList] = useState([]);
    const [feedDetail, setFeedDetail] = useState({});

    const [showFeedPopUp, setShowFeedPopUp] = useState(false);
    const [notificationFeedList, setNotificationFeedList] = useState([]);


    const getNotificationList = async () => {
        setContentLoader(true);
        const notificationListResponse = await getNotificationListApi(profileLoggedin.account_id ?? 0);
        setContentLoader(false);
        //console.log(notificationListResponse);
        setNotificationList(notificationListResponse);
    }

    const viewFeedList = async (groupId = 0) => {
        groupId = parseInt(groupId ?? 0);
        if (groupId > 0) {
            setShowFeedPopUp(false);
            setNotificationFeedList([]);

            dispatch(showLoader(1));
            const notificationFeedListResponse = await getNotificationFeedFlashListApi(profileLoggedin.account_id ?? 0, groupId);
            dispatch(showLoader(0));

            if (Object.keys(notificationFeedListResponse).length > 0) {
                setShowFeedPopUp(true);
                setNotificationFeedList(notificationFeedListResponse);
            }

        }

    }

    const handleCloseFeedPopUp = () => {
        setShowFeedPopUp(false);
    }

    const viewArticle = async (detail = {}) => {
        setFeedDetail(detail);

        if (Object.keys(detail.article_details ?? {}).length > 0) {
            setFeedDetail(detail);

            let notiFlag = parseInt(detail.notif_flag ?? 0);
            let feedCount = parseInt(detail.feed_count ?? 0);

            if (notiFlag == 2 && feedCount > 0) {
                await viewFeedList(detail.group_id);
            } else {
                history.push(`/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.article_details.group_id ?? 0)}/${btoa(detail.article_details.date.split(' ')[0] ?? '')}`);
            }

        }
    }

    const loaderHtml = <li className="global-preloader">
        <div className="col-11 pl-0">
            <div className="bg-gray col-5 pt-3 border-radius-loader"></div>
            <div className="mt-2 bg-gray col-10 pt-3 border-radius-loader"></div>
        </div>
        <div className="col-1 pr-0">
            <div className="bg-gray pt-3 border-radius-loader"></div>
        </div>
    </li>

    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        const pageTitleText = Utilities.capitalize(TitlesLabels.general.notifications);
        dispatch(pageTitle(pageTitleText));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        setShowFeedPopUp(false);
        setNotificationFeedList([]);
        setFeedDetail({});

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            history.push(`/${UrlSlugs.settings_notifications}`);
            return false;
        }

        await getNotificationList();

    }, []);


    return (
        <div className="header-daily-glance">

            <div className="breadcrumbs  breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li className="btn-option">
                            <Link to={`/${UrlSlugs.notificationDailyGlance}`} className="btn-icon btn-arrow-left" title={TitlesLabels.general.atAGlance.toLowerCase()}></Link>
                            <div className="current">{TitlesLabels.general.notifications}</div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-4  px-5 pb-5">
                    {
                        contentLoader &&
                        <ul className="ul-global-listing mt-3">
                            {
                                [...Array(4 + (Math.floor(Math.random() * 3))).keys()].map((key) => loaderHtml)
                            }
                        </ul>
                    }

                    {
                        !contentLoader &&
                        <>
                            {
                                Object.keys(notificationList ?? []).length === 0 ?
                                    <NoRecordAvailable>{TitlesLabels.general.noRecordAvailable}</NoRecordAvailable>
                                    :
                                    <ul className="ul-global-listing cursor-pointer font-gotham-book fs-16 fs-md-16">
                                        {
                                            Object.entries(notificationList ?? []).map(([key, row]) =>
                                                <li key={key} onClick={() => viewArticle(row)} style={{ cursor: `${Object.keys(row.article_details ?? {}).length == 0 ? 'not-allowed' : 'pointer'}` }}>
                                                    <div className='line-height-1-3 w-100 py-2'>
                                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 d-flex justify-content-between" data-name={row.title}>
                                                            <span>{row.title}</span>
                                                            {parseInt(row.feed_count ?? 0) > 0 && <span className="mini-badge-grey ml-3">{parseInt(row.feed_count) > 99 ? `99+` : row.feed_count}</span>}
                                                        </div>
                                                        <div className="profile-label  color-theme-grey">
                                                            <div className="col-md-10 px-0">{row.description}</div>
                                                        </div>
                                                        <div className="text-right color-theme-grey fs-16">
                                                            {Utilities.formatDateList(row.create_date)}
                                                        </div>
                                                    </div>

                                                </li>
                                            )
                                        }
                                    </ul>
                            }
                        </>
                    }
                </div>
            </div>

            <GlanceNotificationFeedPopUp showPopup={showFeedPopUp} handleClosePopUp={handleCloseFeedPopUp} feedDetail={feedDetail} feedList={notificationFeedList} />

        </div>
    );

}

export default GlanceNotificationList;




export const GlanceNotificationFeedPopUp = (props) => {

    const dispatch = useDispatch();

    const feedDetail = props.feedDetail ?? {};
    const feedList = props.feedList ?? [];

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const updateSelectedIndex = (index = -1) => {
        index = parseInt(index ?? -1);
        setSelectedIndex(index == selectedIndex ? -1 : index);
    }

    const handleImageView = (e, imgUrl) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(passImageUrl(imgUrl));
    }


    useEffect(() => {
        setSelectedIndex(-1);
    }, [props]);

    return (
        <Modal id="popup-notification-feeds-list" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">{feedDetail.title ?? ''}</Modal.Header>
            <Modal.Body className="px-4 px-md-5 pb-5">


                {
                    Object.keys(feedList ?? []).length === 0 ?
                        <NoRecordAvailable>{TitlesLabels.general.noRecordAvailable}</NoRecordAvailable>
                        :
                        <ul className="ul-global-listing cursor-pointer font-gotham-book fs-16 fs-md-16">
                            {
                                Object.entries(feedList ?? []).map(([key, row]) =>
                                    <li key={key} className={`${key % 2 == 0 ? 'bg-gray' : ''}`} style={{ alignItems: `${key == selectedIndex ? 'start' : 'center'}` }} onClick={() => updateSelectedIndex(key)}>
                                        <div class="color-theme-grey fs-16 font-gotham-book mx-3  py-2">{feedList.length - key}</div>
                                        <div className='line-height-1-3 w-100 py-2'>
                                            <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 d-flex  justify-content-between" data-name={row.title}>
                                                <span>{row.title}</span>
                                                {
                                                    key == selectedIndex &&
                                                    <span className=" mx-3 text-right color-theme-grey fs-16 font-gotham-book  py-2">{Utilities.formatDateList(row.date_time)}</span>
                                                }
                                            </div>

                                            <div className={`color-theme-grey  mt-2 mr-3 ${key != selectedIndex ? 'ellipsis-2' : 'pt-3 border-top'}`}>{row.message}</div>

                                            {
                                                (key == selectedIndex && Object.keys(row.image_path ?? []).length > 0) &&
                                                <ul className="newfeeds-image-list mt-3 pt-4 pb-2 mr-3 border-top">
                                                    {
                                                        Object.entries(row.image_path ?? []).map(([imgKey, imgUrl]) =>
                                                            <li key={imgKey} style={{ backgroundImage: `url(${imgUrl})` }} className="rounded" onClick={(e) => handleImageView(e, imgUrl)}></li>
                                                        )
                                                    }

                                                </ul>
                                            }

                                        </div>
                                        {
                                            key != selectedIndex &&
                                            <div className=" mx-3 text-right color-theme-grey fs-16 font-gotham-book  py-2">{Utilities.formatDateList(row.date_time)}</div>
                                        }
                                    </li>
                                )
                            }
                        </ul>
                }

            </Modal.Body>

        </Modal>
    )

}