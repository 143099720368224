import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import * as RM from 'ramda';

import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import QueryUtilities from '../helpers/QueryUtilities';
import ApiUrls from '../helpers/ApiUrls';

import ChatSpecialNote from '../components/ChatSpecialNote';
import ChatTemplateMessages from '../components/ChatTemplateMessages';
import ChatBroadcast from '../components/ChatBroadcast';
import ChatGroup from '../components/ChatGroup';
import ChatForward from '../components/ChatForward';
import ChatGroupDetail from './ChatGroupDetail';

import { passConversDetail, chatSidebarBlock, passDeleteMediaList, showLoader, blockUnblockChat as blockUnblockChatReducer } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import ChatCompanyGroupsMembers from './ChatCompanyGroupsMembers';
import ChatSettingsCompanyGroupsPendingMembers from './ChatSettingsCompanyGroupsPendingMembers';
import ChatSettingsCompanyGroupsMembers from './ChatSettingsCompanyGroupsMembers';
import ChatSettingsCompanyGroupsAuthorisedTeam from './ChatSettingsCompanyGroupsAuthorisedTeam';
import SessionUtilities from '../helpers/SessionUtilities';
import NoRecordAvailable from './common/NoRecordAvailable';
import { CicleLoader } from './common/Loaders';


const ChatSidebar = (props) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_CHAT_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const mediaNavigation = Utilities.mediaNavigation;

    const [mediaList, setMediaList] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);
    const [mediaCounter, setMediaCounter] = useState(0);
    const [imageLoading, setImageLoading] = useState(true);

    const previewDefault = { type: 0, url: '' };
    const [showPopupMedia, setShowPopupMedia] = useState(false);
    const [showPrevieMediaType, setShowPrevieMediaType] = useState(previewDefault);
    const [muteValue, setMuteValue] = useState(0);

    const [listTeams, setListTeams] = useState([]);
    const [listDepartments, setListDepartments] = useState([]);

    const [transferTeamID, setTransferTeamID] = useState(0);
    const [transferDeptID, setTransferDeptID] = useState(0);

    const [reportText, setReportText] = useState('');

    const [showButtonTemplate, setShowButtonTemplate] = useState(false);
    const [formTemplateAction, setFormTemplateAction] = useState('');

    const [transferContent, setTransferContent] = useState(1);
    const [transferWho, setTransferWho] = useState(1);

    const [departmentKeyword, setDepartmentKeyword] = useState('');
    const [teamKeyword, setTeamKeyword] = useState('');

    const [loadingTeams, setLoadingTeams] = useState(true);
    const [loadingDepartments, setLoadingDepartments] = useState(true);

    const [deleteSelectedMedia, setDeleteSelectedMedia] = useState({});


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'report') {
            setReportText(value);

        } else if (fieldName === 'muteoption') {
            setMuteValue(parseInt(value));

        } else if (fieldName === 'transfercontent') {
            setTransferContent(parseInt(value));

        } else if (fieldName === 'transferwho') {
            setTransferWho(parseInt(value));

        } else if (fieldName === 'transferdepartment') {
            setTransferDeptID(parseInt(value));

        } else if (fieldName === 'transferteam') {
            setTransferTeamID(parseInt(value));

        } else if (fieldName === 'searchdepartment') {
            setDepartmentKeyword(value);
            filterDepartment(value);

        } else if (fieldName === 'searchteam') {
            setTeamKeyword(value);
            filterTeam(value);
        }
    }


    const handleCloseMedia = () => setShowPopupMedia(false);

    const handleShowChatMedia = (type, url, detail = {}) => {
        setShowPopupMedia(true);
        setShowPrevieMediaType({ detail: detail, type: parseInt(type), url: url.toString() });
    }

    const [showChatTransferDepartment, setShowChatTransferDepartment] = useState(false);
    const handleCloseChatTransferDepartment = () => setShowChatTransferDepartment(false);

    const [showChatTransferTeam, setShowChatTransferTeam] = useState(false);
    const handleCloseChatTransferTeam = () => setShowChatTransferTeam(false);


    const getAllMedia = async (reqTypeArray) => {

        setMediaList([]);

        if (parseInt(props.conversID || 0) > 0 && parseInt(props.activeProfile.account_id || 0) > 0) {

            const passValue = {
                api_token: TOKEN_SESSION.toString(), //API_CHAT_TOKEN.toString(),
                account_id: props.activeProfile.account_id.toString(),
                convers_id: props.conversID.toString()
            }

            /* console.log('getAllMedia');
            console.log(ApiUrls.bizchatGetAllMedia);
            console.log(passValue); */


            let mediaListTemp = {};
            jQuery('#btn-delete-media-selected').parent('div').addClass('d-none');

            await axios
                .post(ApiUrls.bizchatGetAllMedia, passValue)
                .then(async response => {
                    //console.log(response);

                    const responseData = response.data;
                    if (parseInt(response.status) === 201) {
                        if (parseInt(responseData.success) === 1) {


                            let mediaListResult = responseData.result.conversation_media_list ?? [];

                            const byTime = RM.descend(RM.prop('time_stamp'));
                            mediaListResult = RM.sort(byTime, mediaListResult);

                            let externalLinksArray = [];
                            Object.entries(mediaListResult).map(([key, row]) => {

                                const mType = parseInt(row.type);
                                if (reqTypeArray.includes(mType)) {

                                    let localTime = moment(parseInt(row.time_stamp));
                                    let dateFormatted = moment(new Date(localTime)).format('MMMM  YYYY').toLocaleString();
                                    dateFormatted = dateFormatted.toString().toLowerCase();

                                    if (!mediaListTemp.hasOwnProperty(dateFormatted)) {
                                        mediaListTemp[dateFormatted] = [row];
                                    } else {
                                        mediaListTemp[dateFormatted] = [...mediaListTemp[dateFormatted], row];
                                    }

                                    if (mType === 8) {
                                        externalLinksArray.push(row.message.toString());
                                    }
                                }

                            });


                            // ACTIVATE THIS WHEN get_meta_tags IS ENABLE IN THE SERVER

                            if (reqTypeArray.includes(8)) {
                                const urlMetaData = await QueryUtilities.getUrlMetaData(externalLinksArray, 1);

                                let mediaListUpdated = [];
                                Object.entries(mediaListTemp).map(([key, medias]) => {

                                    let mediaItemtUpdated = [];
                                    Object.entries(medias).map(([subkey, media]) => {

                                        if (externalLinksArray.includes(media.message)) {
                                            mediaItemtUpdated.push({ ...media, meta: urlMetaData[media.message] });
                                        }
                                    });

                                    mediaListUpdated[key] = [...mediaItemtUpdated];

                                });

                                mediaListTemp = mediaListUpdated;
                            }

                            setMediaList(mediaListTemp);
                            setMediaCounter(Object.keys(mediaListTemp).length);

                        }
                    }

                    setIsProcessing(false);

                })
                .catch((err) => {

                });
        }

    }

    const pushMediaDetail = (e, mediaData = {}) => {

        const currentConversID = parseInt(props.conversID || 0);
        if (currentConversID === 0) { return false; }

        const time_stamp = mediaData.time_stamp.toString();

        let deleteSelectedMediaTemp = { ...deleteSelectedMedia };
        if (time_stamp in deleteSelectedMedia) {
            delete deleteSelectedMediaTemp[time_stamp];
        } else {
            deleteSelectedMediaTemp[time_stamp] = mediaData;
        }


        jQuery('.media-button-option').addClass('d-none');
        if (Object.keys(deleteSelectedMediaTemp).length > 0) {
            jQuery('.media-button-option').removeClass('d-none');
        }


        setDeleteSelectedMedia(deleteSelectedMediaTemp);

    }

    const passSelectedRequestMediaDelete = () => {
        dispatch(passDeleteMediaList(deleteSelectedMedia));
    }


    const mediaFormat = (mkey, media) => {

        let return_html = '';
        let content_html = '';

        const mType = parseInt(media.type);
        const messsage = media.message.toString();

        const localTime = moment.utc(media.date);
        let local_date = moment(new Date(localTime)).format(Utilities.dateFormatStr.toString()).toLocaleString();
        let local_time = moment(new Date(localTime)).format(Utilities.timeFormatStr.toString()).toLocaleString();


        const inputChecked = (media.time_stamp ?? '') !== '' && <input type="checkbox" attr-val={media.time_stamp} checked={Object.keys(deleteSelectedMedia).includes(media.time_stamp.toString()) ? true : false} onChange={(e) => pushMediaDetail(e, media)} />

        if (mType === 2) {
            content_html = <div className="content-item" style={{ backgroundImage: `url(${messsage})` }} >
                <div className="media-image" onClick={() => handleShowChatMedia(1, messsage)}></div>
                {inputChecked}
            </div>

        } else if (mType === 3) {

            const file_ext = messsage.split('.').pop().toString().toLowerCase();
            content_html = <div href={messsage} className="content-item">
                <div className="media-image file-icon" onClick={() => handleShowChatMedia(2, messsage)}>{file_ext}</div>
                {inputChecked}
            </div>

        } else if (mType === 6) {

            const file_ext = messsage.split('.').pop().toString().toLowerCase();
            const filename = messsage.split('/').pop().toString().toLowerCase();

            content_html = <a href={messsage} className="content-item" target="_blank">
                {/* <div>{file_ext}</div> */}
                <div className='file-icon'></div>
                <div>
                    <div className="ellipsis-1">{filename}</div>
                    <div className="text-color-gray fs-12 fs-md-12 font-gotham-book">{local_date} {local_time}</div>
                </div>
                {inputChecked}
            </a>

        } else if (mType === 9) {
            const message_location = 'https://maps.google.com/?q=' + messsage;
            content_html = <iframe width="100%" height="100%" className="doc-icon" src={`${message_location}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no"></iframe>

        } else if (mType === 8) {

            content_html = <a href={messsage} className="content-item content-item-link" target="_blank">
                <div>
                    <div></div>
                    <div className="ellipsis-3">{messsage}</div>
                </div>
                <div className="mt-2 text-color-blue">{messsage.split('/')[2]}</div>
            </a>

            if (media.hasOwnProperty('meta')) {
                const mediaMeta = media.meta;

                if (mediaMeta.hasOwnProperty('title') && !Utilities.isEmpty(mediaMeta.title)) {

                    content_html = <a href={messsage} className="content-item content-item-link" target="_blank">
                        <div>
                            <div style={{ backgroundImage: `url(${mediaMeta.image})`, backgroundSize: 'cover' }}></div>
                            <div className="w-100  fs-12 fs-md-13">
                                <div className="ellipsis-1 font-gotham-bold fs-16 fs-md-15">{mediaMeta.title}</div>
                                <div className="ellipsis-1 color-theme-grey" dangerouslySetInnerHTML={{ __html: mediaMeta.description }}></div>
                                <div className="ellipsis-1 color-theme-grey opacity-075">{messsage.split('/')[2]}</div>
                            </div>
                        </div>
                    </a>
                }

            }

        } else if (mType === 16) {
            const messageArray = messsage.split('|');
            const imgSrc = !Utilities.isEmpty(messageArray[5]) ? messageArray[5].toString() : messageArray[4].toString();
            content_html = <img src={`${imgSrc.toString()}`} width="100%" onClick={() => handleShowChatMedia(16, imgSrc, media)} />


        } else {
            content_html = messsage;
        }

        return_html = <li key={mkey} id={`media-${media.time_stamp.toString()}`}>{content_html}</li>

        return return_html;
    }


    const showTemplateBackOption = (action) => {
        setShowButtonTemplate(action);
    }

    const submitMute = async () => {

        if (parseInt(props.conversID || 0) > 0 && parseInt(props.activeProfile.account_id || 0) > 0) {
            Utilities.elementDisabledStatus('btn-submit-mute', true);

            let mute_status = (parseInt(muteValue) === 0 || parseInt(muteValue) === 1) ? 0 : 1;

            const passValue = {
                api_token: TOKEN_SESSION.toString(), //API_CHAT_TOKEN.toString(),
                account_id: props.activeProfile.account_id.toString(),
                convers_id: props.conversID.toString(),
                mute_status: mute_status.toString(),
                mute_duration: muteValue.toString()
            }

            /* console.log('submitMute');
            console.log(ApiUrls.bizchatMuteConversation);
            console.log(passValue); */

            await axios
                .post(ApiUrls.bizchatMuteConversation, passValue)
                .then(response => {

                    Utilities.elementDisabledStatus('btn-submit-mute', false);

                    //console.log(response);

                    if (parseInt(response.status) === 201) {

                        if (parseInt(response.data.success) === 1) {
                            Utilities.messagePopup('success', response.data.successMessage);

                            const conversid = props.conversID;
                            let chatDetail = ChatUtilities.getAccountConversationDetail(conversid);

                            let contact_settings = chatDetail.contact_settings;
                            contact_settings = {
                                ...contact_settings,
                                ...{
                                    mute_status_settings: {
                                        ...contact_settings.mute_status_settings,
                                        ...{ mute_status: passValue.mute_status.toString(), mute_duration: passValue.mute_duration.toString() }
                                    }
                                }
                            };


                            chatDetail = { ...chatDetail, ...{ contact_settings: contact_settings } };

                            ChatUtilities.saveAccountsConversationDetail(chatDetail, 'mute');

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {

                    Utilities.elementDisabledStatus('btn-submit-mute', false);
                    Utilities.messagePopup('error', errorMsg);

                });
        }

    }

    const submitReport = async () => {

        if (!Utilities.isEmpty(reportText)) {

            if (parseInt(props.conversID || 0) > 0 && parseInt(props.activeProfile.account_id || 0) > 0) {

                Utilities.elementDisabledStatus('btn-submit-report', true);
                dispatch(showLoader(1));

                const passValue = {
                    api_token: TOKEN_SESSION.toString(), //API_CHAT_TOKEN.toString(),
                    account_id: props.activeProfile.account_id.toString(),
                    convers_id: props.conversID.toString(),
                    message: reportText.toString()
                }

                /* console.log('submitReport');
                console.log(ApiUrls.bizchatReportConversation);
                console.log(passValue); */

                await axios
                    .post(ApiUrls.bizchatReportConversation, passValue)
                    .then(response => {

                        //console.log(response);

                        setReportText('');
                        Utilities.elementDisabledStatus('btn-submit-report', false);
                        dispatch(showLoader(0));

                        if (parseInt(response.status) === 201) {

                            if (parseInt(response.data.success) === 1) {
                                Utilities.messagePopup('success', response.data.successMessage);
                                dispatch(blockUnblockChatReducer(true));
                                dispatch(chatSidebarBlock(''));

                            } else {
                                Utilities.messagePopup('error', response.data.errorMessage);
                            }

                        } else {
                            Utilities.messagePopup('error', errorMsg);
                        }

                    })
                    .catch((err) => {

                        setReportText('');
                        Utilities.elementDisabledStatus('btn-submit-report', false);
                        Utilities.messagePopup('error', errorMsg);
                        dispatch(showLoader(0));

                    });
            }
        }

    }

    const MediaNavigationsAccordon = (props) => {
        return (
            <ul className="media-navigations border-top border-theme-gray">
                {
                    Object.entries(mediaNavigation).map(([keyName, tabName]) =>
                        <li key={keyName} className={`${keyName === (props.currentNav ?? '') ? 'active' : ''}`}>
                            <div className={`tab-media-name font-gotham-bold fs-16 fs-md-16 py-2 px-4 ${isProcessing ? 'cursor-notallow' : ''}`} onClick={() => !isProcessing && dispatch(chatSidebarBlock(keyName))}>{tabName}</div>
                            {
                                keyName === (props.currentNav ?? '') &&
                                <div className='border-bottom border-theme-gray pb-4 px-4 media-listing-content'>{props.children}</div>
                            }
                        </li>
                    )
                }
            </ul>
        )
    }

    /* const mediaNavigations = ( currentNav = '' ) => {
        return (
            <ul className="media-navigations">
                {
                    Object.entries(mediaNavigation).map( ([ keyName, tabName ]) => 
                        <li key={keyName} className={`${keyName===currentNav?'active':''}`} onClick={ () => dispatch( chatSidebarBlock(keyName) ) }>{tabName}</li>
                    )
                }
            </ul>
        )
    } */

    const viewBlock = () => {

        let viewTypeTemp = props.viewType.toString().toLowerCase();
        let blockContent = '';

        if (viewTypeTemp === 'media') {
            blockContent = <Fragment>
                <div className="sidebar-title">Media</div>
                <div className="sidebar-block long-content sidebar-block-media   px-0">
                    <div className="sidebar-block-content sidebar-media">

                        <MediaNavigationsAccordon currentNav={viewTypeTemp}>

                            <div className={`content-loader ${isProcessing ? 'animate' : ''}`}></div>
                            {parseInt(mediaCounter) === 0 && <NoRecordAvailable style="text-center text-lowercase no-record-label mt-3">{TitlesLabels.general.noMediaAvailable}</NoRecordAvailable>}

                            <div className={` mt-3 ${parseInt(mediaCounter) === 0 || isProcessing === true ? 'd-none' : ''} `}>
                                {
                                    Object.entries(mediaList).map(([key, medias]) =>
                                        <div key={key} >
                                            <div className="date-title fs-12 fs-md-13 mb-2  color-theme-grey">{key}</div>
                                            <ul className="media-list image-list">
                                                {Object.entries(medias).map(([mkey, mMedia]) => mediaFormat(mkey, mMedia))}
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>

                        </MediaNavigationsAccordon>

                    </div>

                </div>


                <div className="media-button-option mt-3 px-3 d-none">
                    <button id="btn-delete-media-selected" type="button" className="btn-theme-black button-has-loader" onClick={() => passSelectedRequestMediaDelete()} >delete selected</button>
                </div>

            </Fragment>

        } else if (viewTypeTemp === 'documents') {
            blockContent = <Fragment>
                <div className="sidebar-title">Media</div>
                <div className="sidebar-block long-content sidebar-block-documents   px-0">
                    <div className="sidebar-block-content sidebar-documents">

                        <MediaNavigationsAccordon currentNav={viewTypeTemp}>
                            <div className={`content-loader ${isProcessing ? 'animate' : ''}`}></div>
                            {parseInt(mediaCounter) === 0 && <NoRecordAvailable style="text-center text-lowercase no-record-label mt-3">{TitlesLabels.general.noDocumentsAvailable}</NoRecordAvailable>}

                            <div className={` mt-3 ${parseInt(mediaCounter) === 0 || isProcessing === true ? 'd-none' : ''} `}>
                                {
                                    Object.entries(mediaList).map(([key, medias]) =>
                                        <div key={key} >
                                            <div className="date-title fs-12 fs-md-13  mb-2  color-theme-grey">{key}</div>
                                            <ul className="media-list document-list checkbox-radio-default checkbox-radio-check-icon fs-16 fs-md-15">
                                                {Object.entries(medias).map(([mkey, mMedia]) => mediaFormat(mkey, mMedia))}
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </MediaNavigationsAccordon>

                    </div>
                </div>

                <div className="media-button-option mt-3 px-3 d-none">
                    <button id="btn-delete-media-selected" type="button" className="btn-theme-black button-has-loader" onClick={() => passSelectedRequestMediaDelete()} >delete selected</button>
                </div>

            </Fragment>

        } else if (viewTypeTemp === 'links') {
            blockContent = <Fragment>
                <div className="sidebar-title">Media</div>
                <div className="sidebar-block long-content   px-0">
                    <div className="sidebar-block-content sidebar-links">

                        <MediaNavigationsAccordon currentNav={viewTypeTemp}>
                            <div className={`content-loader ${isProcessing ? 'animate' : ''}`}></div>
                            {parseInt(mediaCounter) === 0 && <NoRecordAvailable style="text-center text-lowercase no-record-label mt-3">{TitlesLabels.general.noLinksAvailable}</NoRecordAvailable>}

                            <div className={` mt-3 ${parseInt(mediaCounter) === 0 || isProcessing === true ? 'd-none' : ''} `}>
                                {
                                    Object.entries(mediaList).map(([key, medias]) =>
                                        <div key={key} >
                                            <div className="date-title fs-12 fs-md-13  mb-2  color-theme-grey">{key}</div>
                                            <ul className="media-list link-list">
                                                {Object.entries(medias).map(([mkey, mMedia]) => mediaFormat(mkey, mMedia))}
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>

                        </MediaNavigationsAccordon>
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'locations') {
            blockContent = <Fragment>
                <div className="sidebar-title">Media</div>
                <div className="sidebar-block long-content  px-0">
                    <div className="sidebar-block-content sidebar-locations">

                        <MediaNavigationsAccordon currentNav={viewTypeTemp}>
                            <div className={`content-loader ${isProcessing ? 'animate' : ''}`}></div>
                            {parseInt(mediaCounter) === 0 && <NoRecordAvailable style="text-center text-lowercase no-record-label mt-3">{TitlesLabels.general.noLocationsAvailable}</NoRecordAvailable>}

                            <div className={` mt-3 ${parseInt(mediaCounter) === 0 || isProcessing === true ? 'd-none' : ''} `}>
                                {
                                    Object.entries(mediaList).map(([key, medias]) =>
                                        <div key={key} >
                                            <div className="date-title fs-12 fs-md-13  mb-2  color-theme-grey">{key}</div>
                                            <ul className="media-list location-list">
                                                {Object.entries(medias).map(([mkey, mMedia]) => mediaFormat(mkey, mMedia))}
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>

                        </MediaNavigationsAccordon>

                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'qr-code') {
            blockContent = <Fragment>
                <div className="sidebar-title">Media</div>
                <div className="sidebar-block long-content  px-0">
                    <div className="sidebar-block-content sidebar-qrcodes">

                        <MediaNavigationsAccordon currentNav={viewTypeTemp}>
                            <div className={`content-loader ${isProcessing ? 'animate' : ''}`}></div>
                            {parseInt(mediaCounter) === 0 && <NoRecordAvailable style="text-center text-lowercase no-record-label mt-3">{TitlesLabels.general.noLocationsAvailable}</NoRecordAvailable>}

                            <div className={` mt-3 ${parseInt(mediaCounter) === 0 || isProcessing === true ? 'd-none' : ''} `}>
                                {
                                    Object.entries(mediaList).map(([key, medias]) =>
                                        <div key={key} >
                                            <div className="date-title fs-12 fs-md-13 color-theme-grey mb-2">{key}</div>
                                            <ul className="media-list qrcodes-list">
                                                {Object.entries(medias).map(([mkey, mMedia]) => mediaFormat(mkey, mMedia))}
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </MediaNavigationsAccordon>

                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'mute') {
            blockContent = <Fragment>
                <div className="sidebar-title">Mute Notification</div>
                <div className="sidebar-block">
                    <div className="sidebar-block-content sidebar-mute">

                        <ul className="mute-selection checkbox-radio-default checkbox-radio-check-icon mt-4">
                            <li> <span>for 15 minutes</span> <input type="radio" name="muteoption" value="15" onChange={(e) => pushValue(e, 'muteoption')} checked={muteValue === 15}  ></input> </li>
                            <li> <span>for 1 hour</span> <input type="radio" name="muteoption" value="1440" onChange={(e) => pushValue(e, 'muteoption')} checked={muteValue === 1440} ></input> </li>
                            <li> <span>for 24 hour</span> <input type="radio" name="muteoption" value="60" onChange={(e) => pushValue(e, 'muteoption')} checked={muteValue === 60} ></input> </li>
                            <li> <span>for until i turn back on</span> <input type="radio" name="muteoption" value="1" onChange={(e) => pushValue(e, 'muteoption')} checked={muteValue === 1} ></input> </li>
                            <li> <span>unmute</span> <input type="radio" name="muteoption" value="0" onChange={(e) => pushValue(e, 'muteoption')} checked={muteValue === 0} ></input> </li>
                        </ul>

                        <button type="button" id="btn-submit-mute" className="btn-theme-black text-lowercase mt-5 button-has-loader" onClick={() => submitMute()}>Submit</button>
                    </div>
                </div>
            </Fragment>


        } else if (viewTypeTemp === 'report') {
            blockContent = <Fragment>
                <div className="sidebar-title">Report User</div>
                <div className="sidebar-block">
                    <div className="sidebar-block-content sidebar-report form-theme">
                        <div className="mb-2">{process.env.REACT_APP_site_title} guarantees your confidentiality. no information of yourself will be known to the person being reported.</div>
                        <div className="color-theme-red pb-3 font-gotham-bold">if you believe someone is in immediate danger, please act in haste to call your local emergency service. don't wait.</div>

                        <div className="form-group">
                            <textarea className="textarea-report-user form-control" placeholder=" " value={reportText} onChange={(e) => pushValue(e, 'report')}  ></textarea>
                            <label className="form-label">Type here</label>
                        </div>

                        <button type="button" id="btn-submit-report" className="btn-theme-black text-lowercase mt-5 button-has-loader" onClick={() => submitReport()}>Submit</button>
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'note') {
            blockContent = <Fragment>
                <div className="sidebar-title">Note</div>
                <div className="sidebar-block">
                    <div className="sidebar-block-content sidebar-note">
                        <ChatSpecialNote />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'template') {
            blockContent = <Fragment>
                <div className={`sidebar-title ${showButtonTemplate > 0 ? 'position-relative' : ''} ${showButtonTemplate === 3 ? 'zindex-1' : ''} `}>
                    {(showButtonTemplate > 0 && showButtonTemplate === 2) && <div className="btn-template-back" onClick={() => { showTemplateBackOption(0); }}></div>}
                    {formTemplateLabel()} saved frequent message
                </div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-template">
                        <ChatTemplateMessages showTemplateBackOption={showTemplateBackOption} formTemplateAction={formTemplateAction} />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'broadcast') {
            blockContent = <Fragment>
                <div className="sidebar-title">Broadcast</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-broadcast pt-3">
                        <ChatBroadcast />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'transfer') {
            blockContent = <Fragment>
                <div className="sidebar-title">Transfer Chat</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-transfer">

                        <ul className="message-content-option list-selection checkbox-radio-default checkbox-radio-check-icon mt-4">
                            <li> <input type="radio" name="transfercontent" value="1" onChange={(e) => pushValue(e, 'transfercontent')} checked={transferContent === 1}></input><span className="ml-2">with content</span> </li>
                            <li> <input type="radio" name="transfercontent" value="2" onChange={(e) => pushValue(e, 'transfercontent')} checked={transferContent === 2}></input><span className="ml-2">without content</span> </li>
                        </ul>

                        <ul className="transfer-content-option list-selection checkbox-radio-default checkbox-radio-check-icon mt-4">
                            <li> <input type="radio" name="transferwho" value="1" onChange={(e) => pushValue(e, 'transferwho')} checked={transferWho === 1}></input><span className="ml-2">departments</span> </li>
                            <li> <input type="radio" name="transferwho" value="2" onChange={(e) => pushValue(e, 'transferwho')} checked={transferWho === 2}></input><span className="ml-2">team members</span> </li>
                        </ul>

                        <button type="button" className="btn-theme-black text-lowercase mt-5" onClick={() => setupTransfer()}>Submit</button>

                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'forward') {

            blockContent = <Fragment>
                <div className="sidebar-title">forward to</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-forward">
                        <ChatForward />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'select-company-groups-members') {

            blockContent = <Fragment>
                <div className="sidebar-title">group members</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-forward">
                        <ChatCompanyGroupsMembers conversID={props.conversID ?? 0} />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'company-groups-authorised-team') {

            blockContent = <Fragment>
                <div className="sidebar-title">authorised team</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-forward">
                        <ChatSettingsCompanyGroupsAuthorisedTeam conversID={props.conversID ?? 0} />
                    </div>
                </div>
            </Fragment>


        } else if (viewTypeTemp === 'company-groups-pending-members') {
            const conversationDetail = ChatUtilities.getAccountConversationDetail(props.conversID);

            blockContent = <Fragment>
                <div className="sidebar-title">pending members ({conversationDetail.pending_group_members_count ?? 0})</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-forward">
                        <ChatSettingsCompanyGroupsPendingMembers conversID={props.conversID ?? 0} />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'company-groups-members') {

            blockContent = <Fragment>
                <div id="chatsidebar-company-groups-members" className="sidebar-title">group members</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-forward">
                        <ChatSettingsCompanyGroupsMembers conversID={props.conversID ?? 0} />
                    </div>
                </div>
            </Fragment>


        } else if (viewTypeTemp === 'groupdetail') {

            blockContent = <Fragment>
                <div className="sidebar-title">group details</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-group-detail">
                        <ChatGroup />
                    </div>
                </div>
            </Fragment>

        } else if (viewTypeTemp === 'groupdetailupdate') {

            blockContent = <Fragment>
                <div className="sidebar-title">group details</div>
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content sidebar-group-detail">
                        <ChatGroupDetail />
                    </div>
                </div>
            </Fragment>
        }



        return blockContent;
    };

    const formTemplateLabel = () => {
        let labelString = '';

        if (showButtonTemplate === 1) {
            labelString = 'Add';
        } else if (showButtonTemplate === 2) {
            labelString = 'Edit';
        } else if (showButtonTemplate === 3) {
            labelString = 'Delete';
        }
        return labelString;
    }


    const submitTransferProper = async () => {

        if (parseInt(props.conversID || 0) > 0 && parseInt(props.activeProfile.account_id || 0) > 0) {

            const total_sum_id = parseInt(transferTeamID) + parseInt(transferDeptID);
            if (parseInt(total_sum_id) === 0) {
                Utilities.messagePopup('error', `no ${btn_name} selected!`);
                return false;
            }

            let receiver_team_account_id = parseInt(transferWho) === 2 ? transferTeamID : 0;
            let department_id = parseInt(transferWho) === 1 ? transferDeptID : 0;

            const passValue = {
                api_token: API_CHAT_TOKEN.toString(),
                convers_id: props.conversID.toString(),
                account_id: props.activeProfile.account_id.toString(),
                company_id: props.activeProfile.team_company_id.toString(),
                receiver_team_account_id: receiver_team_account_id.toString(),
                department_id: department_id.toString(),
                flag: '2',
            }

            /*  Utilities.messagePopup('error', 'we are currently working on it!' ); 
 
             return false; */

            const btn_name = parseInt(transferWho) === 1 ? 'department' : 'team';
            Utilities.elementDisabledStatus('btn-submit-transfer-' + btn_name, true);

            await axios
                .post(ApiUrls.bizchatTransferConversation, passValue)
                .then(response => {

                    Utilities.elementDisabledStatus('btn-submit-transfer-' + btn_name, false);

                    if (parseInt(transferWho) === 1) {
                        handleCloseChatTransferDepartment(false);
                    } else {
                        handleCloseChatTransferTeam(false);
                    }

                    if (parseInt(response.status) === 201) {

                        if (parseInt(response.data.success) === 1) {
                            Utilities.messagePopup('success', response.data.successMessage);

                            const conversid = props.conversID;
                            let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversid);
                            currentConversationDetailTemp = { ...currentConversationDetailTemp, ...{ access_status: '0' } };

                            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 'transfer');

                            dispatch(passConversDetail(currentConversationDetailTemp));

                            dispatch(chatSidebarBlock(''));

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {
                    Utilities.elementDisabledStatus('btn-submit-transfer-' + btn_name, false);
                    Utilities.messagePopup('error', errorMsg);

                });
        }
    }

    const setupTransfer = () => {

        const transferWhoTemp = parseInt(transferWho);
        if (transferWhoTemp === 1) {
            setShowChatTransferDepartment(true);
        } else if (transferWhoTemp === 2) {
            setShowChatTransferTeam(true);
        }
    }

    const filterTeam = (keyword) => {
        jQuery('#listing-transfer-team > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const filterDepartment = (keyword) => {
        jQuery('#listing-transfer-department > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const showMediaPreviewHtml = (chatDetail = {}) => {
        const type = parseInt(chatDetail.type);

        if (type === 1) {
            return <div>
                <img src={showPrevieMediaType.url.toString()} width="100%" className={imageLoading ? 'd-none' : ''} onLoad={() => setImageLoading(false)} onError={() => setImageLoading(false)} ></img>
                {imageLoading == true && <CicleLoader />}
            </div>
        } else if (type === 16) {
            return <div>
                <div className='bg-white'>
                    <img src={showPrevieMediaType.url.toString()} width="100%" className={imageLoading ? 'd-none' : ''} onLoad={() => setImageLoading(false)} onError={() => setImageLoading(false)} ></img>
                </div>
                {imageLoading == true && <CicleLoader />}

                <div className="w-100 pt-3 btn-option d-flex align-items-center justify-content-center">
                    <a className="btn-icon btn-download ml-4" href={showPrevieMediaType.url.toString()} download="qrcode" title="download" target="_blank"></a>
                    <div className="btn-icon btn-forward" title="forward" onClick={() => handleClickForwardPreviewQrcode(chatDetail)}></div>
                </div>

            </div>
        } else if (type === 2) {
            return <>
                <div className="pt-5 pb-4 d-flex justify-content-center"><audio controls ><source src={showPrevieMediaType.url.toString()} type="audio/mp3"></source></audio></div>
                <div className="text-center pb-5"><a href={showPrevieMediaType.url.toString()} download target="_blank">{TitlesLabels.general.clickHereToDownload}</a></div>
            </>
        }

    }

    const handleClickForwardPreviewQrcode = (chatDetail = {}) => {

        chatDetail = {
            ...chatDetail.detail,
            ...{
                doc_id: chatDetail.detail.time_stamp.toString(),
                message_type: chatDetail.detail.type.toString()
            }
        };

        let chatDetailList = [];
        chatDetailList.push(chatDetail);

        handleCloseMedia();
        props.messagesSelectedDetailInMedia(chatDetailList);
        dispatch(chatSidebarBlock('forward'));
    }

    const getTeamMembers = (companyID) => {
        if (parseInt(companyID ?? 0) > 0) {
            axios
                .post(ApiUrls.teamList, { company_id: companyID })
                .then(response => {

                    setLoadingTeams(false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.list)) {
                                setListTeams([]);
                            } else {
                                setListTeams(dataResponse.list);
                            }
                        }
                    } else {
                        setListTeams([]);
                    }
                })
                .catch((err) => {
                    setListTeams([]);
                    setLoadingTeams(false);
                });
        }
    }

    const getDepartments = (companyID) => {
        if (parseInt(companyID) > 0) {
            axios
                .post(ApiUrls.departmentListByAccountID, { id: companyID })
                .then(response => {

                    setLoadingDepartments(false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.departments)) {
                                setListDepartments([]);
                            } else {
                                setListDepartments(dataResponse.departments);
                            }
                        } else {
                            setListDepartments([]);
                        }
                    }

                })
                .catch((err) => {
                    setListDepartments([]);
                    setLoadingDepartments(false);
                });
        }

    }

    useEffect(() => {

        if (Object.keys(props.activeProfile ?? {}).length > 0) {
            getTeamMembers(props.activeProfile.team_company_id);
            getDepartments(props.activeProfile.team_company_id);
        }

    }, [props.activeProfile]);

    useEffect(() => {

        setMediaCounter(1);
        setMediaList([]);
        setIsProcessing(true);
        setDeleteSelectedMedia({});
        setShowPrevieMediaType(previewDefault);

        const viewType = props.viewType.toString().toLowerCase();
        if (viewType === 'media') {
            getAllMedia([2, 3]);
        } else if (viewType === 'documents') {
            getAllMedia([6]);
        } else if (viewType === 'links') {
            getAllMedia([8]);
        } else if (viewType === 'locations') {
            getAllMedia([9]);
        } else if (viewType === 'qr-code') {
            getAllMedia([16]);

        } else if (viewType === 'mute') {

            const conversid = props.conversID;
            let chatDetail = ChatUtilities.getAccountConversationDetail(conversid);

            if (chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0) === 1) {
                setMuteValue(parseInt(chatDetail.contact_settings.mute_status_settings.mute_duration || 0));
            } else {
                setMuteValue(0);
            }
        }

    }, [props.viewType]);


    useEffect(() => {
        setFormTemplateAction(showButtonTemplate);

    }, [showButtonTemplate]);




    return (
        <Fragment>
            <div key={parseInt(props.conversID ?? 0)}>{viewBlock()}</div>


            <Modal show={showPopupMedia} onHide={handleCloseMedia} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName={`${parseInt(showPrevieMediaType.type) === 16 ? 'modal-dialog-qrcode' : ''} `} >
                {
                    parseInt(showPrevieMediaType.type) === 2 &&
                    <Modal.Header closeButton>
                        <h5 className="text-lowercase text-center w-100 my-2" >Preview Media</h5>
                    </Modal.Header>
                }

                <Modal.Body className={`p-0 ${parseInt(showPrevieMediaType.type) === 2 ? 'media-popup-body' : ''}`}>
                    {showMediaPreviewHtml(showPrevieMediaType)}
                </Modal.Body>
            </Modal>


            <Modal id="chat-transfer-department-popup" dialogClassName="modal-dialog-theme" show={showChatTransferDepartment} onHide={handleCloseChatTransferDepartment} animation={false} aria-labelledby="contained-modal-title-vcenter" centered  >
                <Modal.Header closeButton className="border-0">
                    <h5 className="text-lowercase text-center w-100 my-2" >Transfer Chat</h5>
                </Modal.Header>
                <Modal.Body className="px-5">

                    <div className="col-11 mx-auto text-lowercase">
                        <div className="w-100 form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
                            <div className="px-5">

                                <div className="form-group">
                                    <input type="text" id="input_transfer_department" className="form-control text-lowercase" placeholder=" " value={departmentKeyword} onChange={(e) => pushValue(e, 'searchdepartment')} autoComplete="off" autoFocus ></input>
                                    <label className="form-label">Search</label>
                                </div>

                                {loadingDepartments === true && <CicleLoader />}

                                {
                                    Object.keys(listDepartments).length > 0 ?
                                        <ul id="listing-transfer-department" className="list-department-transfer mt-2 px-2">
                                            {
                                                Object.entries(listDepartments).map(([key, row]) =>
                                                    <li key={key} data-keyword={row.department_name}>
                                                        <div className="dept-name">{row.department_name}</div>
                                                        <input type="radio" name="transferdepartment" value={row.group_id} onChange={(e) => pushValue(e, 'transferdepartment')} checked={transferDeptID === parseInt(row.group_id)} />
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        :
                                        <NoRecordAvailable style={`text-center text-lowercase no-record-label mb-5 ${loadingDepartments === true ? 'd-none' : ''}`}>{TitlesLabels.general.noDepartmentAvailable}</NoRecordAvailable>
                                }

                            </div>

                            {Object.keys(listDepartments).length > 0 && <div id="btn-submit-transfer-department" className="btn-theme-black text-lowercase w-100 my-4  button-has-loader" onClick={() => submitTransferProper()}>Done</div>}
                        </div>

                    </div>

                </Modal.Body>

            </Modal>



            <Modal id="chat-transfer-team-popup" dialogClassName="modal-dialog-theme" show={showChatTransferTeam} onHide={handleCloseChatTransferTeam} animation={false} aria-labelledby="contained-modal-title-vcenter" centered  >
                <Modal.Header closeButton className="border-0">
                    <h5 className="text-lowercase text-center w-100 my-2" >Transfer Chat</h5>
                </Modal.Header>
                <Modal.Body className="px-5">

                    <div className="col-11 mx-auto text-lowercase">
                        <div className="w-100 broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">

                            <div className="form-group">
                                <input type="text" id="input_transfer_team" className="form-control text-lowercase" placeholder=" " value={teamKeyword} onChange={(e) => pushValue(e, 'searchteam')} autoComplete="off" autoFocus ></input>
                                <label className="form-label">Search</label>
                            </div>

                            {loadingTeams === true && <CicleLoader />}

                            {
                                Object.keys(listTeams).length > 0 ?
                                    <ul id="listing-transfer-team" className="list-members list-member-transfer mt-2">
                                        {
                                            Object.entries(listTeams).map(([key, row]) =>
                                                <li key={key} data-keyword={row.name}>
                                                    <div><div className="profile-image" style={{ backgroundImage: `url(${row.logo_url})` }}></div></div>
                                                    <div>
                                                        <div className="profile-name notranslate">{row.name}</div>
                                                        <div className="profile-label">{row.title}</div>
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="transferteam" value={row.account_id} onChange={(e) => pushValue(e, 'transferteam')} checked={transferTeamID === parseInt(row.account_id)} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    :
                                    <NoRecordAvailable style={`text-center text-lowercase no-record-label mb-5 ${loadingTeams === true ? 'd-none' : ''}`}>{TitlesLabels.general.noTeamMemberAvailable}</NoRecordAvailable>
                            }

                            {Object.keys(listTeams).length > 0 && <div id="btn-submit-transfer-team" className="btn-theme-black text-lowercase w-100 my-4  button-has-loader" onClick={() => submitTransferProper()} >Done</div>}
                        </div>

                    </div>

                </Modal.Body>

            </Modal>


        </Fragment>
    )
}

export default ChatSidebar;
