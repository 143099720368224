import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';


import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import UrlSlugs from "../../helpers/UrlSlugs";
import { checkingFollowDailyGlanceStatus } from "../../helpers/QueryDailyGlance";
import Utilities from "../../helpers/Utilities";
import { pageTitle, passImageUrl, showDirectoryProfilePopup, showFooter, showHeader, viewMenuPricePopup, showLoader as showLoaderPopup } from "../../actions";
import { Modal } from "react-bootstrap";
import { NoRecordWithIcon } from "../../components/common/NoRecord";
import { glanceQuizQuestions, glanceQuizSubmitAnswer } from "../../helpers/QueryGlanzQuiz";
import SessionUtilities from "../../helpers/SessionUtilities";
import { CicleLoader } from "../../components/common/Loaders";
import QueryMasterSearchUtlities from "../../helpers/QueryMasterSearchUtlities";
import { AdsPopUpBody } from "../DailyGlance/DailyGlancePopups";

const GlanceQuizList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const masterDetail = PagesAuthentication.getMasterUserDetailDetail()
    const defaultLocation = SessionUtilities.getUserLocation() ?? {};

    const currentAreaId = defaultLocation.area_id ?? 0;

    const [contentLoader, setContentLoader] = useState(true);
    const [currentDateTitle, setCurrentDateTitle] = useState('');
    const [showQuizMesasgePopUp, setShowQuizMesasgePopUp] = useState(false);
    const [quizQuestionDetail, setQuizQuestionDetail] = useState({});
    const [quizAdDetail, setAdDetail] = useState({});
    const [quizQuestionList, setQuizQuestionList] = useState({});
    const [quizSelectedAnswer, setQuizSelectedAnswer] = useState({});

    const [showAdsPopup, setShowAdsPopup] = useState(false);
    const [adsDetail, setAdsDetail] = useState({});

    const [correctAnswer, setCorrectAnswer] = useState('');
    const [answerStatus, setAnswerStatus] = useState(0);

    const init = () => {
        const currentDateTitleTemp = moment().format(Utilities.dateTimeGlanceLabelStr);
        setCurrentDateTitle(currentDateTitleTemp);
        setQuizQuestionDetail({});
        setAdDetail({});
        setQuizQuestionList([]);
        setContentLoader(true);
        setAdsDetail({});
        setShowAdsPopup(false);
        setAnswerStatus(0);
        setCorrectAnswer('');
    }

    const handleCloseQuizMesasgePopUp = () => {
        setShowQuizMesasgePopUp(false);
    }

    const handleCloseOptionAdsPopUp = () => {
        setAdsDetail({});
        setShowAdsPopup(false);
    }

    const handleClickAdverstisement = async (detail = {}) => {
        if (Object.keys(detail).length > 0) {
            setAdsDetail(detail);
            setShowAdsPopup(true);
        }
    }

    const handleShowProfile = async () => {
        const premiumDetail = quizAdDetail ?? {};
        if (Object.keys(premiumDetail).length > 0) {
            const areaId = parseInt(currentAreaId ?? 0);
            const adFlag = parseInt(premiumDetail.ad_flag ?? 0);
            const profileId = parseInt(premiumDetail.company_id ?? 0);
            const externalStatus = parseInt(premiumDetail.external_status ?? 0);


            const filePathUrl = premiumDetail.external_link_details.file_path ?? '';

            if (areaId === 0 && adFlag === 1) {

                dispatch(showLoaderPopup(1));
                const menuRes = await QueryMasterSearchUtlities.getCompanyExtraDetail(profileId, 'menu-price-detail');
                const menuLink = menuRes.file_url ?? '';
                dispatch(showLoaderPopup(0));

                handleClickAdverstisement({
                    group_id: premiumDetail.group_id,
                    company_id: premiumDetail.company_id,
                    company_name: premiumDetail.company_name,
                    company_logo: premiumDetail.company_logo,
                    description: premiumDetail.description,
                    banner_image: premiumDetail.ad_image_path, //.banner_image_path,
                    catelog_status: premiumDetail.catelog_status,
                    website_link: premiumDetail.website_link,
                    flag_type: premiumDetail.external_status,
                    external_link: filePathUrl,
                    menu_link: menuLink
                });


            } else {
                if ([1, 2].includes(externalStatus)) {
                    if (profileId > 0) {
                        dispatch(showLoaderPopup(1));
                        const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profileId);
                        dispatch(showLoaderPopup(0));
                        dispatch(showDirectoryProfilePopup(profileDetail.list[profileId.toString()]));

                        if (externalStatus === 2) {

                            const menuRes = await QueryMasterSearchUtlities.getCompanyExtraDetail(profileId, 'menu-price-detail');
                            const menuLink = menuRes.file_url ?? '';

                            if (!Utilities.isEmpty(menuLink)) {
                                const fileExt = menuLink.split('.').slice(-1).pop();

                                if (Utilities.validate_image_ext(fileExt)) {
                                    dispatch(passImageUrl(menuLink));
                                } else {
                                    dispatch(viewMenuPricePopup({ file_url: menuLink, new_tab: 1 }));
                                }
                            }

                        }
                    }
                } else if ([3, 4].includes(externalStatus)) {
                    if ((premiumDetail.external_link_details.link_status ?? 0 === 1) && !Utilities.isEmpty(filePathUrl)) {

                        const fileExt = filePathUrl.split('.').slice(-1).pop();

                        if (externalStatus === 3) {

                            if (Utilities.validate_image_ext(fileExt)) {
                                dispatch(passImageUrl(filePathUrl));
                            } else {
                                window.open(filePathUrl, '_blank');
                            }

                        } else if (externalStatus === 4) {
                            window.open(filePathUrl, '_blank');
                        }

                    }
                }
            }

        }
    }

    const onChangeAnswer = (answerDetail = {}) => {
        if (Object.keys(answerDetail).length > 0) {
            setQuizSelectedAnswer(answerDetail);
        }

    }

    const getQuestion = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const currentDate = moment().format(Utilities.dayFormatServerStr);
        const masterAccountId = parseInt(masterDetail.account_id ?? 0);

        console.log(TOKEN_SESSION);
        const passval = {
            api_token: TOKEN_SESSION.toString(),
            account_id: masterAccountId.toString(),
            date: currentDate.toString(),
        }

        const resQuestions = await glanceQuizQuestions(passval);
        setContentLoader(false);
        if (Object.keys(resQuestions).length > 0) {
            setQuizQuestionDetail(resQuestions);
            setQuizQuestionList(resQuestions.question_list[0] ?? {});
            setAdDetail(resQuestions.ad_details ?? {});

            /* const answerCorrectArray = resQuestions.question_list[0].answer.filter(ans => parseInt(ans.status || 0) === 1)[0] ?? {};
            console.log(answerCorrectArray);
            setAnswerStatus(0);
            setCorrectAnswer(answerCorrectArray.answer ?? '');
            setShowQuizMesasgePopUp(true); */
        }
    }

    const handleSubmitAsnwer = async () => {

        if (Object.keys(quizSelectedAnswer).length > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const currentDate = moment().format(Utilities.dayFormatServerStr);
            const masterAccountId = parseInt(masterDetail.account_id ?? 0);
            let answer_details = [];

            answer_details.push({
                question_id: quizQuestionList.question_id.toString(),
                answer: quizSelectedAnswer.answer.toString(),
                answer_status: quizSelectedAnswer.status.toString()
            });

            const passval = {
                api_token: TOKEN_SESSION.toString(),
                account_id: masterAccountId.toString(),
                date: currentDate.toString(),
                group_id: quizQuestionDetail.group_id.toString(),
                answer_details: answer_details
            }

            dispatch(showLoaderPopup(1));
            const resQuestions = await glanceQuizSubmitAnswer(passval);
            dispatch(showLoaderPopup(0));

            if (Object.keys(resQuestions).length > 0) {
                setQuizQuestionDetail(resQuestions);
                setQuizQuestionList(resQuestions.question_list[0] ?? {});
                setAdDetail(resQuestions.ad_details ?? {});

                const winnerFlag = parseInt(resQuestions.winner_flag ?? 0);
                const answerCorrectArray = quizQuestionList.answer.filter(ans => parseInt(ans.status || 0) === 1)[0] ?? {};

                setCorrectAnswer(answerCorrectArray.answer ?? '');
                setAnswerStatus(winnerFlag);
                setShowQuizMesasgePopUp(true);

            }

        }

    }


    useEffect(async () => {

        const pageTitleText = Utilities.capitalize(TitlesLabels.general.notifications);
        dispatch(pageTitle(pageTitleText));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            history.push(`/${UrlSlugs.settings_notifications}`);
            return false;
        }

        init();
        getQuestion();

    }, []);


    const titleContent = (contentHtml = '', bgColor = ' bg-theme-green') => {
        //contentHtml = `prize<br />R500 five hundred rand<br />gift voucher from<br />dawoods`;
        return <div className={`${bgColor} prize-description px-5 py-3 d-flex align-items-center justify-content-center text-center text-white font-gotham-book line-height-1-2`}>
            <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
        </div>
    }

    return (
        <>
            <div className="glance-quiz">

                <div className="breadcrumbs  breadcrumbs-chat">
                    <div className="container breadcrumbs-content">
                        <ul className="breadcrumbs-nav">
                            <li className="btn-option">
                                <Link to={`/${UrlSlugs.notificationDailyGlance}`} className="btn-icon btn-arrow-left" title={TitlesLabels.general.atAGlance.toLowerCase()}></Link>
                                <div className={Utilities.isEmpty(quizQuestionDetail.title ?? '') ? 'current' : ''}>{TitlesLabels.general.quiz}</div>
                                {
                                    !Utilities.isEmpty(quizQuestionDetail.title ?? '') && <div className="current">{quizQuestionDetail.title}</div>
                                }

                            </li>
                        </ul>
                    </div>

                </div>
                <div className="bg-black text-center py-2 text-white font-gotham-book fs-md-14 text-lowercase">
                    <div className="my-1">{currentDateTitle}</div>
                </div>

                <div className="main-content">
                    <div className="container pt-4  px-5 pb-5">
                        <div className="quiz-content col-lg-10 mx-auto">
                            {
                                contentLoader ? <CicleLoader />
                                    : <>
                                        {
                                            Object.keys(quizQuestionDetail).length === 0 ?
                                                <NoRecordWithIcon iconFileName="circle-paper-warning.svg">our quiz is not<br />available on weekends or public<br />holidays</NoRecordWithIcon>
                                                : <>
                                                    <section className="quiz-content-wrap col-lg-8 px-0 mx-auto  mb-4  fs-md-14">
                                                        {titleContent(quizQuestionDetail.prize_description ?? '')}
                                                        <div className="py-2 font-gotham-book d-flex align-items-center">
                                                            <div className=" w-100">
                                                                <div className="fs-md-12">question</div>
                                                                <div className="font-gotham-medium  ">
                                                                    <div className="pb-4 mt-1">{quizQuestionList.question ?? ''}</div>

                                                                    <ul className="quiz-choices checkbox-radio-default pb-2">
                                                                        {
                                                                            Object.entries(quizQuestionList.answer ?? []).map(([key, row]) =>
                                                                                row.answer != null &&
                                                                                <li key={key} >
                                                                                    <input type="radio"
                                                                                        d={row.answer}
                                                                                        className="border-0 mr-2"
                                                                                        name="ans" value={row.answer}
                                                                                        checked={parseInt(row.apply_status ?? 0) === 1 ? true : false}
                                                                                        onChange={() => onChangeAnswer(row)}
                                                                                    />
                                                                                    <label for={row.answer} className="my-0">{row.answer}</label>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </ul>
                                                                </div>
                                                                <div className="my-3 text-center fs-md-19">
                                                                    {
                                                                        parseInt(quizQuestionDetail.winner_flag ?? 0) === 0 ?
                                                                            <>
                                                                                <div className="mb-3 fs-md-12">answer can be found in one of today’s {TitlesLabels.general.atAGlance.toLowerCase()} articles</div>
                                                                                <button type="button" className="btn-theme-green w-auto px-4 mx-auto" style={{ height: 40 }} onClick={() => handleSubmitAsnwer()} >submit</button>
                                                                            </>

                                                                            : parseInt(quizQuestionDetail.winner_flag ?? 0) === 1 ?
                                                                                <div className="font-gotham-medium fs-md-19 text-theme-green">your entry has been submitted into our draw</div>
                                                                                :
                                                                                <div className="font-gotham-medium fs-md-19 text-pink">incorrect answered.<br />better luck next time!</div>
                                                                    }

                                                                </div>
                                                            </div>

                                                        </div>
                                                        {titleContent(quizQuestionDetail.previ_prize_description ?? '', `bg-black`)}
                                                    </section>
                                                    <section className="ads-image cursor-pointer bg-transparent">
                                                        {
                                                            Object.keys(quizAdDetail ?? {}).length > 0 &&
                                                            <img src={!Utilities.isEmpty(quizAdDetail.banner_image_path ?? '') ? quizAdDetail.banner_image_path : ''} className="h-100" onClick={() => handleShowProfile()} />
                                                        }

                                                    </section>
                                                </>
                                        }

                                    </>
                            }

                        </div>
                    </div>
                </div >



            </div >

            <GlanceQuizeMessagePopUp showPopup={showQuizMesasgePopUp} handleClosePopUp={handleCloseQuizMesasgePopUp} status={answerStatus} answer={correctAnswer} />

            <AdsPopUpBody showStatus={showAdsPopup} handleClose={handleCloseOptionAdsPopUp} detail={adsDetail} />
        </>
    );

}

export default GlanceQuizList;





export const GlanceQuizeMessagePopUp = (props) => {

    const dispatch = useDispatch();

    const propStatus = parseInt(props.status ?? 0);
    const propAnswer = props.answer;

    useEffect(() => {

    }, [props]);

    return (
        <Modal id="popup-glance-quiz-message" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className={`font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 ${propStatus === 1 ? 'text-theme-green' : 'text-danger'}`}>{propStatus === 1 ? 'congratulations!' :
                'sorry'}</Modal.Header>
            <Modal.Body className="px-4 px-md-5 pb-5 text-center">

                <div className="font-gotham-bold">
                    {
                        propStatus == 1 ?
                            <>
                                you have been entered into the<br />@<span className="text-theme-green">a</span>glance quiz draw list.
                            </>
                            : <>
                                your answer was incorrect.<br />
                                the correct answer was<br />
                                <span className="text-theme-green">{propAnswer}</span><br />
                                better luck next time!
                            </>
                    }

                </div>
                <button type="button" className="btn-theme-black   mt-5" onClick={() => props.handleClosePopUp()}>ok</button>
            </Modal.Body>

        </Modal>
    )

}