

import React from 'react';
import TitlesLabels from '../../helpers/TitlesLabels';

export const NoRecordWithIcon = (props) => {
    const propsPath = props.path ?? '';
    const propsIconFileName = props.iconFileName ?? '';

    const icon = propsPath == "" ? `${process.env.REACT_APP_img_folder}/icons/black/${propsIconFileName}` : propsPath;

    return (
        <div className={`${props.style ?? 'text-center text-lowercase font-gotham-bold fs-medium-20 mt-5 pt-5 line-height-1-4'}`}>
            <div className='mb-3 pt-5'><img src={icon} /></div>
            {props.children ?? TitlesLabels.general.noRecordAvailable}
        </div>
    )
}


