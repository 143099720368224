import React, { memo } from "react";
import CommonProfileLogo from "./CommonProfileLogo";


const DashboardProfileListing = (props) => {

    const propsRow = props.row ?? {},
        propsKey = props.keyId ?? '',
        propsRowSelected = props.rowProfileSelected ?? {},
        propInternalStatus = props.internalStatus??false;


        const rowClass = propInternalStatus ? (parseInt(propsRow.access_status || 1) === 0 ? 'opacity-05' : '') : ( parseInt(propsRow.access_status || 0) === 1 ? 'opacity-05' : '');

    return (
        <li key={propsKey} data-keyword={propsRow.name.toLowerCase()} className={`${rowClass} ${parseInt(propsRow.account_id || 0) === parseInt(propsRowSelected.account_id || -1) ? 'cursor-wait' : ''}`}>
            <div>
                <CommonProfileLogo classAttr={`profile-image`} profileDetail={propsRow} handlePreviewProfileImage={props.previewProfileImage} preventOnclick={true} />
            </div>
            <div className='line-height-1-3' onClick={() => props.showChat(propsRow)}>
                <div className="profile-name notranslate ellipsis-1" data-name={propsRow.name}>{propsRow.name}</div>
                <div className="profile-label color-theme-grey ellipsis-1">{propsRow.title}</div>
            </div>
            <div className="time color-theme-grey">{propsRow.time}</div>
            <div>{props.blockOrCounter(propsRow)}</div>
        </li>
    )
}

export default memo(DashboardProfileListing);
