import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import { Dropdown } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../../../actions';
import { unfollowProfiles, getFollowingList, muteNotificationCompany, muteNotificationCSettingDetail } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import SessionUtilities from '../../../helpers/SessionUtilities';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';

const FollowSettings = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);

    const [unFollowManage, setUnFollowManage] = useState(false);
    const [memberIDsSelected, setMemberIDsSelected] = useState([]);
    const [memberIDsMuted, setMemberIDsMuted] = useState([]);
    const [toggleAll, setToggleAll] = useState(false);
    const [typeVal, setTypeVal] = useState(1); // 1-follow, 2 - mute


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getFollowingListing = async (profile = {}) => {

        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: profile.account_id.toString(),
        };


        setUnFollowManage(false);
        setToggleAll(false);
        setMemberIDsSelected([]);

        setShowProcessing(true);
        const companyFollowersResponse = await getFollowingList(passval);
        setShowProcessing(false);

        if (Object.keys(companyFollowersResponse).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(companyFollowersResponse.success) === 1) {

            const followinglist = companyFollowersResponse.result.notification_following_list ?? [];
            setListProfiles(followinglist);


            // getting setting
            const resMuteSettingDetail = await muteNotificationCSettingDetail({ account_id: profile.account_id.toString() });
            if (Object.keys(resMuteSettingDetail).length > 0) {
                if (parseInt(resMuteSettingDetail.status ?? 0) === 1) {

                    const profileIds = (resMuteSettingDetail.detail.company_list ?? '').toString();
                    setMemberIDsSelected(!Utilities.isEmpty(profileIds) ? profileIds.split(',') : []);
                    setMemberIDsMuted(!Utilities.isEmpty(profileIds) ? profileIds.split(',') : []);
                }
            }


        } else {
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage);
        }
    }

    const toggleActiveClass = (e, member = {}) => {

        if (!unFollowManage) {
            setMemberIDsSelected(memberIDsMuted);
            return false;
        }

        let memberIDsTemp = memberIDsSelected;
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        setMemberIDsSelected(memberIDsTemp);

        setToggleAll(listProfiles.length === memberIDsTemp.length ? true : false);
    }


    const toggleMemberAll = () => {
        ;
        if (toggleAll === true) {
            setMemberIDsSelected([]);
        } else {
            let allMembersIds = [];
            listProfiles.map(detail => allMembersIds.push(detail.account_id ?? '0'));

            setMemberIDsSelected([...allMembersIds]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const handleClickCancel = (action = false, type = 1) => {
        setUnFollowManage(action);
        setMemberIDsSelected(action === true ? (type === 1 ? [] : memberIDsMuted) : memberIDsMuted);
        setToggleAll(false);
        setTypeVal(type);

        if (action === true && type === 2) {
            setToggleAll(listProfiles.length === memberIDsMuted.length ? true : false);
        }

    }

    const handleClickSubmitStatus = async (submitAction = 0) => {

        if (memberIDsSelected.length === 0) {
            return false;
        }

        submitAction = parseInt(submitAction);

        if (submitAction === 0) {

            confirmAlert({
                title: '',
                message: typeVal === 1 ? TitlesLabels.confirmMessages.wishToUnfollowCompany : TitlesLabels.confirmMessages.wishToMuteompany,
                overlayClassName: 'normal-font-style',
                buttons: [
                    { label: 'no' },
                    { label: 'yes', onClick: () => handleClickSubmitStatus(1) }
                ]
            });

            return false;
        }

        const button_name = 'btn-submit-status';
        const masterProfileDetail = PagesAuthentication.getMasterAccountDetail();

        // FOLLOW
        if (typeVal === 1) {

            let selectedGroupIdsArray = [];
            listProfiles.map(detail => {
                if (memberIDsSelected.includes(detail.account_id ?? '0')) {
                    selectedGroupIdsArray.push(detail.group_id ?? '0');
                }
            });


            const passval = {
                api_token: API_TOKEN.toString(),
                account_id: masterProfileDetail.account_id.toString(), //account_id: userLoginDetail.account_id.toString(),
                group_id: selectedGroupIdsArray.join(',')
            };


            Utilities.elementDisabledStatus(button_name, true);
            const responseActionDb = await unfollowProfiles(passval);
            Utilities.elementDisabledStatus(button_name, false);

            if (Object.keys(responseActionDb).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(responseActionDb.success) === 1) {
                Utilities.messagePopup('success', responseActionDb.successMessage);
                await getFollowingListing(PagesAuthentication.getMasterAccountDetail());

            } else {
                Utilities.messagePopup('error', responseActionDb.errorMessage);
            }

            // MUTE
        } else if (typeVal === 2) {


            if (memberIDsSelected.length > 0) {

                const passval = {
                    account_id: masterProfileDetail.account_id.toString(), //account_id: userLoginDetail.account_id.toString(),
                    company_list: memberIDsSelected.join(',')
                };


                console.log(passval);
                Utilities.elementDisabledStatus(button_name, true);
                const responseActionDb = await muteNotificationCompany(passval);
                Utilities.elementDisabledStatus(button_name, false);

                if (Object.keys(responseActionDb).length === 0) {
                    Utilities.messagePopup('error', errorMsg);
                    return false;
                }

                if (parseInt(responseActionDb.status ?? 0) === 1) {
                    Utilities.messagePopup('success', TitlesLabels.alertMessages.successMuteUpdated);
                    await getFollowingListing(PagesAuthentication.getMasterAccountDetail());

                } else {
                    Utilities.messagePopup('error', errorMsg);
                }

            }
        }


    }

    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        const userAccountDetailTemp = PagesAuthentication.getMasterAccountDetail();
        await getFollowingListing(userAccountDetailTemp);

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        setUserLoginDetail(userLoginDetailTemp);

        dispatch(pageTitle(`${TitlesLabels.general.followSettings}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="notication-company-followers-page team-members-page">

            <div className="breadcrumbs breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notifications}`}>{TitlesLabels.navigations.notifications}</Link>
                            <div className={`${!unFollowManage ? 'current' : 'cursor-pointer'}`} onClick={() => handleClickCancel()}>{TitlesLabels.general.followSettings}</div>
                            {
                                unFollowManage &&
                                <div className="current">{typeVal === 1 ? TitlesLabels.general.unfollow : TitlesLabels.general.muteNotification}</div>
                            }

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(listProfiles).length > 0 &&
                                <>
                                    <div className="btn-icon btn-magnify" onClick={() => toggleSeachBox()} title={TitlesLabels.general.searchProfile}></div>
                                    {/* <div className="btn-icon btn-person-remove-2" title={ TitlesLabels.general.unfollow }></div> */}
                                    <Dropdown className="btn-icon btn-menu-two">
                                        <Dropdown.Toggle variant="dropdown-icon" ></Dropdown.Toggle>
                                        <Dropdown.Menu align="right" className="dropdown-chat-option text-center">
                                            <Dropdown.Item onClick={() => handleClickCancel(true, 2)}>mute notification</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleClickCancel(true, 1)} >unfollow</Dropdown.Item>
                                        </Dropdown.Menu>

                                    </Dropdown>


                                </>
                            }

                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content position-relative">

                {
                    Object.keys(listProfiles).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap d-none  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                                </div>
                                {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                            </div>

                        </div>
                    </div>

                }

                <div className="container pt-4">

                    {
                        showProcessing ?
                            <ul className="ul-global-list px-5">
                                {
                                    [...Array(12).keys()].map((key) =>
                                        <li key={key} className="global-preloader  py-2">
                                            <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                            <div>
                                                <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                                <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            :
                            <>
                                {
                                    Object.keys(listProfiles).length === 0 ?
                                        <NoRecordAvailable style="text-center text-lowercase no-record-label  mt-5 ">{Utilities.labelRecordAvailable}</NoRecordAvailable>
                                        :
                                        <div className=' text-lowercase checkbox-radio-default checkbox-radio-check-icon form-theme fs-sm-18  px-5 '>

                                            {
                                                unFollowManage &&
                                                <div className="select-all-wrap d-flex align-items-center justify-content-between fs-18 pb-3">
                                                    <div className='font-gotham-bold'>select all</div>
                                                    <div className="mr-n1"><input type="checkbox" checked={`${toggleAll ? 'checked' : ''}`} onChange={(e) => toggleMemberAll()} /></div>
                                                </div>
                                            }



                                            <ul id="listing-profiles" className={`listing-profiles  ul-global-list notranslate text-lowercase ${unFollowManage ? `list-has-check` : `list-has-muted`}`}>
                                                {
                                                    Object.entries(listProfiles).map(([key, row]) =>
                                                        <li
                                                            key={key}
                                                            data-keyword={row.name}
                                                            className={`
                                                                ${!unFollowManage ?
                                                                    `${memberIDsSelected.includes(row.account_id.toString()) ? 'muted' : ''} cursor-pointer`
                                                                    :
                                                                    memberIDsSelected.includes(row.account_id.toString()) ?
                                                                        'selected cursor-pointer' : 'cursor-auto'
                                                                }
                                                                
                                                        `}
                                                            onClick={(e) => toggleActiveClass(e, row)}
                                                        >
                                                            <div className="profile-image-wrap">
                                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                            </div>

                                                            <div className='line-height-1'>
                                                                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1" data-name={row.name}>{row.name} </div>
                                                                <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.title}</div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                            {
                                                unFollowManage &&
                                                <div className="button-group-boolean col-md-6 mx-auto pt-5 mt-5">
                                                    <button type="button" className="btn-theme-black" onClick={() => handleClickCancel()}>cancel</button>
                                                    <button id="btn-submit-status" type="button" className="btn-theme-black button-has-loader" disabled={memberIDsSelected.length === 0 ? true : false} onClick={() => handleClickSubmitStatus()}>submit</button>
                                                </div>
                                            }
                                        </div>
                                }
                            </>
                    }

                </div>
            </div>
        </div>
    )
}

export default FollowSettings;