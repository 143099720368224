import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Utilities from '../../helpers/Utilities';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';
import SessionUtilities from '../../helpers/SessionUtilities';

import { showLoader as showLoaderPopup, showDirectoryProfilePopup, passImageUrl, viewMenuPricePopup } from "../../actions";
import { CicleLoader } from './Loaders';
import { AdsPopUpBody } from '../../pages/DailyGlance/DailyGlancePopups';


const DirectoryNewRegisteredInfo = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    // const defaultLocation = Utilities.defaultLocation();
    const defaultLocation = SessionUtilities.getUserLocation() ?? {};

    const currentCityId = props.cityId ?? (defaultLocation.city_id ?? 0);
    const currentAreaId = props.areaId ?? (defaultLocation.area_id ?? 0);

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    let { type } = useParams();

    const [premiumDetail, setPremiumDetail] = useState({});
    const [totalCounter, setTotalCounter] = useState(0);
    const [premiumLogo, setPremiumLogo] = useState('');
    const [showLoader, setShowLoader] = useState(true);

    const [showAdsPopup, setShowAdsPopup] = useState(false);
    const [adsDetail, setAdsDetail] = useState({});

    const handleCloseOptionAdsPopUp = () => {
        setAdsDetail({});
        setShowAdsPopup(false);
    }

    const handleClickAdverstisement = async (detail = {}) => {
        if (Object.keys(detail).length > 0) {
            setAdsDetail(detail);
            setShowAdsPopup(true);
        }
    }

    const getNewRegisteredProfiles = async () => {
        const city_id = parseInt(currentCityId);
        const area_id = parseInt(currentAreaId);

        if (city_id > 0 && parseInt(profileLoggedin.account_id ?? 0) > 0) {
            setShowLoader(true);
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: city_id.toString(),
                area_id: (area_id ?? '0').toString(),
                date_time: Utilities.getMomentCurrentDateTime()
            }
            //console.log(passval);
            const profileCounterResponse = await QueryMasterSearchUtlities.getNewRegisteredCounter(passval);
            //console.log(profileCounterResponse);
            if (Object.keys(profileCounterResponse).length > 0) {
                setTotalCounter(profileCounterResponse.result.search_home_details.company_count ?? 0);
                setPremiumLogo(profileCounterResponse.result.search_home_details.premium_ads_details.banner_image_path ?? '');
                setPremiumDetail(profileCounterResponse.result.search_home_details.premium_ads_details ?? {});
            }
            setShowLoader(false);
        }

    }

    const handleShowProfile = async () => {
        if (Object.keys(premiumDetail).length > 0) {
            const areaId = parseInt(currentAreaId ?? 0);
            const adFlag = parseInt(premiumDetail.ad_flag ?? 0);
            const profileId = parseInt(premiumDetail.company_id ?? 0);
            const externalStatus = parseInt(premiumDetail.external_status ?? 0);

            /*  if (profileId > 0) {
                 dispatch(showLoaderPopup(1));
                 const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profileId);
                 dispatch(showLoaderPopup(0));
                 dispatch(showDirectoryProfilePopup(profileDetail.list[profileId.toString()]));
 
                 const menuRes = await QueryMasterSearchUtlities.getCompanyExtraDetail(profileId, 'menu-price-detail');
                 dispatch(viewMenuPricePopup({ file_url: menuRes.file_url.toString(), new_tab: 1 }));
 
                 console.log(menuRes);
             }
 
             return false; */

            const filePathUrl = premiumDetail.external_link_details.file_path ?? '';

            if (areaId === 0 && adFlag === 1) {

                dispatch(showLoaderPopup(1));
                const menuRes = await QueryMasterSearchUtlities.getCompanyExtraDetail(profileId, 'menu-price-detail');
                const menuLink = menuRes.file_url ?? '';
                dispatch(showLoaderPopup(0));

                handleClickAdverstisement({
                    group_id: premiumDetail.group_id,
                    company_id: premiumDetail.company_id,
                    company_name: premiumDetail.company_name,
                    company_logo: premiumDetail.company_logo,
                    description: premiumDetail.description,
                    banner_image: premiumDetail.ad_image_path, //.banner_image_path,
                    catelog_status: premiumDetail.catelog_status,
                    website_link: premiumDetail.website_link,
                    flag_type: premiumDetail.external_status,
                    external_link: filePathUrl,
                    menu_link: menuLink
                });


            } else {
                if ([1, 2].includes(externalStatus)) {
                    if (profileId > 0) {
                        dispatch(showLoaderPopup(1));
                        const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profileId);
                        dispatch(showLoaderPopup(0));
                        dispatch(showDirectoryProfilePopup(profileDetail.list[profileId.toString()]));

                        if (externalStatus === 2) {

                            const menuRes = await QueryMasterSearchUtlities.getCompanyExtraDetail(profileId, 'menu-price-detail');
                            const menuLink = menuRes.file_url ?? '';

                            if (!Utilities.isEmpty(menuLink)) {
                                const fileExt = menuLink.split('.').slice(-1).pop();

                                if (Utilities.validate_image_ext(fileExt)) {
                                    dispatch(passImageUrl(menuLink));
                                } else {
                                    dispatch(viewMenuPricePopup({ file_url: menuLink, new_tab: 1 }));
                                }
                            }

                        }
                    }
                } else if ([3, 4].includes(externalStatus)) {
                    if ((premiumDetail.external_link_details.link_status ?? 0 === 1) && !Utilities.isEmpty(filePathUrl)) {

                        const fileExt = filePathUrl.split('.').slice(-1).pop();

                        if (externalStatus === 3) {

                            if (Utilities.validate_image_ext(fileExt)) {
                                dispatch(passImageUrl(filePathUrl));
                            } else {
                                window.open(filePathUrl, '_blank');
                            }

                        } else if (externalStatus === 4) {
                            window.open(filePathUrl, '_blank');
                        }

                    }
                }
            }

        }

    }


    const handleRedirectRegsiteredNew = () => {
        let matchShowStatus = type ?? btoa(1);
        history.push(`/${UrlSlugs.page_directoryRegisteredNew}/${matchShowStatus}`);
    }

    useEffect(() => {
        getNewRegisteredProfiles();

    }, [currentCityId, currentAreaId]);

    return (
        showLoader ?
            <CicleLoader />
            :
            <>
                <div className='font-gotham-medium fs-22 fs-md-20 text-center'>
                    {
                        parseInt(totalCounter) > 0 &&
                        <>
                            <div>new business registrations</div>
                            <div>in your area for the last month</div>
                            <div className='font-gotham-bold fs-70 fs-50 color-theme-green'>{totalCounter}</div>
                            <div className='font-gotham-medium fs-14 fs-md-14 cursor-pointer color-theme-green ' onClick={() => handleRedirectRegsiteredNew()}>click here to view</div>
                        </>
                    }

                    {
                        !Utilities.isEmpty(premiumLogo) &&
                        <img src={premiumLogo} width={250} className='mt-2 cursor-pointer' onClick={() => handleShowProfile()} />
                    }

                </div>

                <AdsPopUpBody showStatus={showAdsPopup} handleClose={handleCloseOptionAdsPopUp} detail={adsDetail} />
            </>

    )

}

export default memo(DirectoryNewRegisteredInfo);


