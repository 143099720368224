import React, { useEffect, useState } from 'react';
import ChatUtilities from '../helpers/ChatUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { getMembersGroups, groupRemoveMembers, groupSuspendMembers } from '../helpers/QueryChatGroups';
import Utilities from '../helpers/Utilities';
import { Dropdown } from 'react-bootstrap';
import jQuery from 'jquery';
import TitlesLabels from '../helpers/TitlesLabels';
import CommonProfileLogo from './common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';
import NoRecordAvailable from './common/NoRecordAvailable';
import { CicleLoader } from './common/Loaders';

const ChatSettingsCompanyGroupsMembers = (props) => {


    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const errorMsg = process.env.REACT_APP_message_error_process;

    const conversId = parseInt(props.conversID ?? 0);
    const conversationDetail = ChatUtilities.getAccountConversationDetail(conversId);
    const accessFlag = parseInt(conversationDetail.access_flag ?? 0);
    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail() ?? {};
    const sidebarLabelId = "#chatsidebar-company-groups-members";

    const [conversAccessFlag, setConversAccessFlag] = useState(accessFlag);
    const [viewType, setViewType] = useState(0);

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);

    const [memberAccountIds, setMemberAccountIds] = useState([]);
    const [memberSelectedAccountIds, setMemberSelectedAccountIds] = useState([]);
    const [memberSuspendedAccountIds, setMemberSuspendedAccountIds] = useState([]);
    const [showEmpty, setShowEmpty] = useState(false);
    const [toggleAll, setToggleAll] = useState(false);

    const defaultValues = { title: '' };
    const [formValues, setFormValues] = useState(defaultValues);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);

        } else if (fieldName === 'title') {

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleMemberInput = (e, row) => {
        let memberSelectedAccountIdsTemp = memberSelectedAccountIds;
        let id = row.account_id.toString();

        (!memberSelectedAccountIdsTemp.includes(id)) ? memberSelectedAccountIdsTemp.push(id) : (memberSelectedAccountIdsTemp = [...Utilities.removeValueInArray(memberSelectedAccountIdsTemp, id)]);
        memberSelectedAccountIdsTemp = Utilities.arrayUnique(memberSelectedAccountIdsTemp);

        setMemberSelectedAccountIds(memberSelectedAccountIdsTemp);
        setToggleAll(false);
    }

    const toggleMemberAll = () => {

        if (toggleAll === true) {
            setMemberSelectedAccountIds([]);
        } else {
            setMemberSelectedAccountIds([...memberAccountIds]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }


    const getMembers = async () => {

        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (userLoginDetail.account_id ?? 0).toString(),
            group_id: (conversationDetail.id ?? 0).toString(),
            flag: "0"
        }

        const responseMembers = await getMembersGroups(paramMembers);
        if (Object.entries(responseMembers).length === 0) {
            setShowEmpty(true);
            return false;
        }

        if (parseInt(responseMembers.success) === 0) {
            setShowEmpty(true);
            return false;
        }

        //const memberListRes = responseMembers.result.conversation_members_list.members_list ?? [];
        const memberListRes = responseMembers.result.company_group_members_list.members_list ?? [];
        if (parseInt(memberListRes.length) > 0) {

            let memberAccountIdsLoop = [], memberAccountIdsSuspended = [];

            Object.entries(memberListRes).map(([id, memberdetail]) => {
                memberAccountIdsLoop.push(memberdetail.account_id.toString());
                if (parseInt(memberdetail.suspend_status ?? 0) === 1) {
                    memberAccountIdsSuspended.push(memberdetail.account_id.toString());
                }
            });

            setMemberAccountIds(memberAccountIdsLoop);
            setMemberSuspendedAccountIds(memberAccountIdsSuspended);
            setListProfiles(memberListRes);

        } else {
            setShowEmpty(true);
        }

    }

    const handleActionType = (action = 0) => {
        setConversAccessFlag(0);
        setToggleAll(false);
        setMemberSelectedAccountIds(action === 2 ? memberSuspendedAccountIds : []);
        setViewType(action);

        jQuery(sidebarLabelId).text(`${action === 2 ? 'suspend' : 'remove'} members`);
    }

    const handleButtonAction = async (action = 0) => {

        //flag :  1: remove, 2: suspend

        if (action === 0) {
            setConversAccessFlag(accessFlag);
            jQuery(sidebarLabelId).text("group members");
        } else {

            let membersListDelete = "", membersListSupend = [];

            if (memberSelectedAccountIds.length === 0) {
                return false;
            }


            if (viewType === 1) {
                membersListDelete = memberSelectedAccountIds.join(",");

            } else if (viewType === 2) {
                memberSelectedAccountIds.map(id => {
                    membersListSupend.push({
                        account_id: id.toString(),
                        status: "2"
                    });
                });

            }



            const paramMembers = {
                api_token: TOKEN_SESSION.toString(), // TOKEN.toString(),
                account_id: (userLoginDetail.account_id ?? 0).toString(),
                group_id: (conversationDetail.id ?? 0).toString(),
                members_list: viewType === 1 ? membersListDelete : membersListSupend
            }


            const button_name = 'btn-submit-member';
            Utilities.elementDisabledStatus(button_name, true);

            let responseMembersStatus = {};
            
            // DELETE or REMOVE
            if ( viewType===1){
                responseMembersStatus = await groupRemoveMembers(paramMembers);

            // SUSPEND
            }else{
                responseMembersStatus = await groupSuspendMembers(paramMembers);
            }

           
            
            Utilities.elementDisabledStatus(button_name, false);


            if (Object.entries(responseMembersStatus).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(responseMembersStatus.success) === 0) {
                Utilities.messagePopup('error', responseMembersStatus.errorMessage);
                return false;
            }

            Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyUpdated);

            setConversAccessFlag(accessFlag);
            getMembers();
        }
    }



    useEffect(() => {
        getMembers();
        jQuery(sidebarLabelId).text("group members");
    }, []);


    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme position-relative">

            {
                ([1].includes(conversAccessFlag) && Object.keys(listProfiles).length > 0) &&
                <>
                    <Dropdown drop="down" alignRight className="btn-icon position-absolute" style={{ right: -4, top: -5, zIndex: 22 }}>
                        <Dropdown.Toggle variant="dropdown-icon" className="btn-icon btn-paper-plane" title="option">
                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-dots-vertical.svg`} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="fs-14">
                            <Dropdown.Item onClick={() => handleActionType(2)}>suspend</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleActionType(1)}>remove</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            }

            {
                Object.keys(listProfiles).length > 0 ?
                    <>
                        <div className="form-group border-0 bg-white mb-0 border-radius">
                            <input type="text" id="input_search_team" className="form-control text-lowercase bg-gray-important input-search" placeholder=" " autoComplete="off" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} ></input>
                        </div>
                        {
                            [1, 2].includes(conversAccessFlag) ?
                                <>

                                    <ul id="listing-profiles-member" className="list-members mt-2">
                                        {
                                            Object.entries(listProfiles).map(([key, row]) =>
                                                <li key={key} data-keyword={row.name.toLowerCase()} className={`${parseInt(row.suspend_status ?? 0) === 1 ? 'opacity-05' : ''}`}>
                                                    <div>
                                                        {/* <div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                                        <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                                    </div>
                                                    <div className='line-height-1-2'>
                                                        <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                        <div className="profile-label">{row.title}</div>
                                                    </div>
                                                    <div></div>
                                                </li>
                                            )
                                        }
                                    </ul>

                                </>
                                :
                                <>
                                    <div className="select-all-wrap mt-3">
                                        <div className='font-gotham-bold fs-20 fs-16'>select all</div>
                                        <div className="mr-n1"><input type="checkbox" checked={`${toggleAll ? 'checked' : ''}`} onChange={(e) => toggleMemberAll()} /></div>
                                    </div>

                                    <ul id="listing-profiles-member" className="list-members mt-2">
                                        {
                                            Object.entries(listProfiles).map(([key, row]) =>
                                                <li key={key} data-keyword={row.name.toLowerCase()}>
                                                    <div>
                                                        {/* <div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                                        <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                                    </div>
                                                    <div className='line-height-1-2'>
                                                        <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                        <div className="profile-label">{row.title}</div>
                                                    </div>
                                                    <div>
                                                        <input type="checkbox" checked={`${(memberSelectedAccountIds.includes(row.account_id.toString())) ? 'checked' : ''}`} onChange={(e) => toggleMemberInput(e, row)} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>

                                    <div className="team-selected-counter mt-3 text-center"> {memberSelectedAccountIds.length} Selected</div>

                                    <div className="button-group-boolean pt-5">
                                        <button type="button" className="btn-theme-black" onClick={() => handleButtonAction(0)}>cancel</button>
                                        <button type="button" id="btn-submit-member" className=" btn-theme-black button-has-loader" disabled={memberSelectedAccountIds.length === 0 ? true : false} onClick={() => handleButtonAction(1)}>submit</button>
                                    </div>

                                </>

                        }

                    </>
                    :
                    showEmpty === false ? <CicleLoader /> : <NoRecordAvailable style="text-center text-lowercase no-record-label ">{Utilities.labelProfileAvailable}</NoRecordAvailable>
            }
        </div>

    )
}

export default ChatSettingsCompanyGroupsMembers;
