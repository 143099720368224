import React, { useEffect, useState } from 'react';
//import axios from 'axios';

//import ApiUrls from '../helpers/ApiUrls';
import Utilities from '../helpers/Utilities';
import NoRecordAvailable from './common/NoRecordAvailable';

const TeamMembersSelectionListingTemplate = (props) => {

    //const companyId = props.companyId ?? 0;
    const listProfiles = props.listProfiles ?? [];

    //const [ listProfiles , setListProfiles ] = useState([]);
    const [memberSelectedIDs, setMemberSelectedIDs] = useState(props.memberSelectedIDs ?? []);

    const toggleActiveClass = (e, member) => {
        let memberSelectedIDsTemp = memberSelectedIDs;
        let id = (member.id ?? member.account_id).toString();

        !(memberSelectedIDsTemp.includes(id)) ? memberSelectedIDsTemp.push(id) : (memberSelectedIDsTemp = [...Utilities.removeValueInArray(memberSelectedIDsTemp, id)]);
        memberSelectedIDsTemp = Utilities.arrayUnique(memberSelectedIDsTemp);
        setMemberSelectedIDs(memberSelectedIDsTemp);
    }

    const submitTeamSelection = () => {
        //console.log(memberSelectedIDs);
        const memberSelectedDetailTemp = listProfiles.filter(member => memberSelectedIDs.includes((member.account_id || 0).toString())) ?? [];
        props.submitTeamSelection(memberSelectedDetailTemp);
    }

    /* const getMembers = async () => {

        if ( parseInt(companyId)>0 ){
            await axios
                    .post( ApiUrls.companyTeamList , { account_id : companyId } )
                    .then( response => {
                    
                        if ( response.status===200 ){
                            const dataResponse = response.data;
            
                            if ( parseInt(dataResponse.status)===1 ){
                                let memberList = [];

                                Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                                    memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];                                    
                                });

                                setListProfiles(memberList);
                            }
                        }
                    })
                    .catch( (err) => {
                        setListProfiles([]);
                    }); 
        }
    } */

    useEffect(() => {
        //getMembers();
    }, []);


    return (
        <div className="departments-page">
            {
                Object.keys(listProfiles).length > 0 ?
                    <ul id="listing-members" className="listing-profiles ul-global-list list-has-check mt-4 text-lowercase">
                        {
                            Object.entries(listProfiles).map(([key, row]) =>
                                <li key={key} className={`${((memberSelectedIDs.includes((row.id ?? row.account_id).toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClass(e, row)}>
                                    <div className="profile-image-wrap">
                                        <div className={`logo-size-60 logo-size-md-60`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(row.logo_url ?? row.logo)})`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo_url ?? row.logo) }}>{Utilities.showHideInitial(row.logo_url ?? row.logo, row.name)}</div>
                                    </div>
                                    <div className='line-height-1'>
                                        <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{row.name}</div>
                                        <div className="profile-position font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position ?? row.position}</div>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                    :
                    <NoRecordAvailable>{Utilities.labelProfileAvailable}</NoRecordAvailable>
            }

            <div className="col-md-4 mx-auto  pt-5 mt-5">
                <button type="button" className="btn-theme-black" onClick={() => props.submitTeamSelection ? submitTeamSelection() : ''}>{Object.keys(listProfiles).length === 0 ? 'close' : 'done'}</button>
            </div>
        </div>

    )
}

export default TeamMembersSelectionListingTemplate;
