import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import SessionUtilities from '../../helpers/SessionUtilities';
import NoRecordAvailable from '../../components/common/NoRecordAvailable';


const SpecialMessageInfo = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const errorMsg = process.env.REACT_APP_message_error_process;
    const maxWords = 100;
    const defaultValue = { title: '', template: '' };

    const [formValues, setFormValues] = useState(defaultValue);
    const [loginDetail, setLoginDetail] = useState({});
    const [templateList, setTemplateList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formAction, setFormAction] = useState('');
    const [templateDetail, setTemplateDetail] = useState({});
    const [rowSelected, setRowSelected] = useState(-1);
    const [templateIDs, setTemplateIDs] = useState([]);
    const [showDeleteOption, setShowDeleteOption] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'title') {
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
        } else if (fieldName === 'template') {

            const wordsCounter = Utilities.wordsCounter(value);
            if (parseInt(wordsCounter) > maxWords) {
                Utilities.messagePopup('error', `Only ${maxWords} words is allowed!`);
                return false;
            }
            formValuesTemp = { ...formValuesTemp, ...{ template: value } };
        }
        setFormValues(formValuesTemp);
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const enableDeleteTemplate = (bol = true) => {
        setShowDeleteOption(bol);
        setTemplateIDs([]);
        setRowSelected(-1);
    }

    const deleteTemplateSelected = () => {
        if (parseInt(templateIDs.length) > 0) {
            addEditSubmit(3, templateIDs.join(','));
        }
    }

    const addEditForm = (action, detailTemplate = {}) => {

        if (action === 'add') {
            setFormValues(defaultValue);

        } else if (action === 'edit') {
            setTemplateDetail(detailTemplate);
            const reqValues = { template: detailTemplate.message, title: detailTemplate.title };
            setFormValues(reqValues);

        } else {
            setRowSelected(-1);
        }
        setFormAction(action);
    }

    const toggleTemplateInput = (e, row) => {
        let templateIDsTemp = templateIDs;
        let id = row.group_id.toString();

        (!templateIDsTemp.includes(id)) ? templateIDsTemp.push(id) : (templateIDsTemp = [...Utilities.removeValueInArray(templateIDsTemp, id)]);
        templateIDsTemp = Utilities.arrayUnique(templateIDsTemp);

        setTemplateIDs(templateIDsTemp);
    }

    const toggleSelected = (key) => {
        if (showDeleteOption === false) {
            let rowSelectedTemp = (key === rowSelected) ? -1 : key;
            setRowSelected(rowSelectedTemp);
        }
    }

    const getTemplates = async (accountDetail = {}) => {

        let account_id = 0;

        if (Object.entries(accountDetail).length > 0) {
            account_id = accountDetail.account_id;
        }

        if (Object.entries(loginDetail).length > 0) {
            account_id = loginDetail.account_id;
        }

        if (parseInt(account_id) === 0) { return false; }


        const passValue = {
            api_token: TOKEN_SESSION.toString(),
            account_id: account_id.toString()
        }

        if (parseInt(account_id) > 0) {

            //console.log(`getTemplates`);
            //console.log(ApiUrls.bizchatGetTemplateMessage);
            //console.log(passValue);

            await axios
                .post(ApiUrls.bizchatGetTemplateMessage, passValue)
                .then(response => {
                    setIsLoading(false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {

                            if (Object.keys(response.data.result ?? {}).length > 0) {
                                const templatesSaved = response.data.result.menu_saved_message_list ?? [];
                                setTemplateList(templatesSaved);

                            } else {
                                setTemplateList([]);
                            }
                        } else {
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetTemplates);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    PagesAuthentication.logoutDirectly();

                    setIsLoading(false);
                    Utilities.messagePopup('error', errorMsg);
                });
        }
    }

    const reloadList = async () => {
        await getTemplates();
        addEditForm('');
        enableDeleteTemplate(false);
    }

    const clearInputs = () => {
        setFormValues(defaultValue);
    }

    const addEditSubmit = async (action, group_ids = '') => {
        action = parseInt(action);
        let group_id = 0;
        let passValue = {};

        if (Object.keys(templateDetail).length > 0) {
            group_id = templateDetail.group_id;
        }

        if (action === 3 && !Utilities.isEmpty(group_ids)) {
            group_id = group_ids;
        }

        let btn_name = (action === 1) ? 'submit' : 'update';
        if (action === 3) { btn_name = 'delete'; }

        if (action !== 3) {
            if (Utilities.isEmpty(formValues.template)) {
                Utilities.messagePopup('error', TitlesLabels.alertMessages.messageShouldNotEmpty);
                return false;

            } else {
                const wordsCounter = Utilities.wordsCounter(formValues.template);
                if (parseInt(wordsCounter) > maxWords) {
                    Utilities.messagePopup('error', `Only ${maxWords} words is allowed!`);
                    return false;
                }
            }
        }

        if (action <= 3) {
            passValue = {
                api_token: TOKEN_SESSION.toString(),
                account_id: loginDetail.account_id.toString(),
                group_id: group_id.toString(),
                flag: action.toString(),
                title: formValues.title.toString(),
                message: formValues.template.toString()
            }

            //console.log('addEditSubmit');
            //console.log(ApiUrls.bizchatUpdateTemplateMessage);
            //console.log(passValue);

            Utilities.elementDisabledStatus(`btn-${btn_name}-template`, true);

            await axios
                .post(ApiUrls.bizchatUpdateTemplateMessage, passValue)
                .then(response => {
                    Utilities.elementDisabledStatus(`btn-${btn_name}-template`, false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {
                            reloadList();
                        }
                    }
                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(`btn-${btn_name}-template`, false);
                    Utilities.messagePopup('error', errorMsg);
                });
        }

    }

    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.specialMessage}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginBasicInfo !== null) {
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoginDetail(loginBasicInfo);

            getTemplates(loginBasicInfo);
        }

    }, []);

    return (
        <div className="special-message-info-page chat-template-messages-content-fq">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            {/* <div className="cursor-pointer" onClick={ () => triggerSetting()}>{ TitlesLabels.settings }</div> */}
                            <div className="current">{TitlesLabels.navigations.standardizedReplies}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container">

                    {
                        Utilities.isEmpty(formAction) ?
                            <section className="pt-5 px-md-5">
                                {
                                    isLoading === false ?
                                        <div className="d-flex  align-itemd-center border-bottom border-theme-gray pb-3">
                                            {
                                                (Object.keys(templateList).length > 0 && showDeleteOption === false) &&
                                                <button type="button" className="btn-theme-black-circle-icon btn-trash mr-2" onClick={() => enableDeleteTemplate(true)}></button>
                                            }
                                            <button type="button" id="btn-new-template" className="btn-theme-black-circle-icon btn-plus" onClick={() => addEditForm('add')}></button>
                                        </div>
                                        :
                                        <div>
                                            <div className="d-flex align-itemd-center mb-4">
                                                <button type="button" className="btn-theme-black-circle-icon border-0 mr-2"></button>
                                                <button type="button" className="btn-theme-black-circle-icon border-0"></button>
                                            </div>
                                            <ul className="ul-special-messages-preloader">
                                                {
                                                    [...Array(3).keys()].map((key) => <li key={key}></li>)
                                                }
                                            </ul>
                                        </div>
                                }



                                <ul className="template-list-option">
                                    {
                                        Object.entries(templateList).map(([key, row]) =>
                                            <li key={key} className={`${rowSelected === key ? 'selected' : ''}`}>

                                                <div className="temp-wrap" onClick={() => toggleSelected(key)}>
                                                    <div className="temp-title">{row.title}</div>
                                                    <div className={`temp-msg color-theme-grey ${rowSelected === key ? 'd-block' : ''} `}>{row.message}</div>
                                                </div>

                                                {
                                                    rowSelected === key &&
                                                    <div className="mt-3">
                                                        <button type="button" className="btn-theme-black col-md-2" onClick={() => addEditForm('edit', row)}>edit</button>
                                                    </div>
                                                }

                                                {
                                                    showDeleteOption &&
                                                    <div className="deletion-wrap checkbox-radio-default checkbox-radio-check-icon">
                                                        <input type="checkbox" checked={`${((templateIDs.includes(row.group_id.toString())) ? 'checked' : '')}`} onChange={(e) => toggleTemplateInput(e, row)} />
                                                    </div>
                                                }

                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    showDeleteOption &&
                                    <div className="pt-5 button-group-boolean col-md-5 mx-auto mt-5">
                                        <button type="button" className="btn-theme-black" onClick={() => enableDeleteTemplate(false)}>cancel</button>
                                        <button type="button" id="btn-delete-template" className={`btn-theme-black button-has-loader ${templateIDs.length === 0 ? 'opacity-075' : ''}`} onClick={() => deleteTemplateSelected()}>delete</button>
                                    </div>
                                }


                                {
                                    (isLoading === false && Object.keys(templateList).length === 0) &&
                                    <NoRecordAvailable>{TitlesLabels.general.noRecordAvailable}</NoRecordAvailable>
                                }

                            </section>

                            :
                            <section className="col-10 mx-auto mt-5">

                                <div className="px-5 form-theme">

                                    <div className="form-group ">
                                        <input type="text" name="title" className="form-control text-tranform-initial" placeholder=" " autoFocus autoComplete="off" value={formValues.title} onChange={(e) => pushValue(e, 'title')} ></input>
                                        <label className="form-label">Title</label>
                                    </div>

                                    <div className="form-group border-0">
                                        <textarea className="form-control scroll-none text-tranform-initial" placeholder=" " rows="10" autoComplete="off" value={formValues.template} onChange={(e) => pushValue(e, 'template')} ></textarea>
                                        <label className="form-label">Type here</label>
                                    </div>


                                    <div className={`pt-4 pb-3 button-group-boolean col-md-${formAction === 'add' ? '5' : '6'} mx-auto`}>
                                        <button type="button" className="btn-theme-black" onClick={() => addEditForm('')}>cancel</button>
                                        {
                                            formAction === 'add' ?
                                                <button type="button" id="btn-submit-template" className="btn-theme-black button-has-loader" onClick={() => addEditSubmit(1)}>submit</button>
                                                :
                                                <button type="button" id="btn-update-template" className="btn-theme-black button-has-loader" onClick={() => addEditSubmit(2)}>update</button>
                                        }
                                    </div>

                                    <div className="text-center font-gotham-book fs-14-important">
                                        <div><span className="color-theme-grey">maximum {maxWords} words</span></div>
                                        <div><span className="color-theme-green cursor-pointer" onClick={() => clearInputs()}>clear</span></div>
                                    </div>


                                </div>

                            </section>
                    }



                </div>
            </div>
        </div>
    )
}

export default SpecialMessageInfo;