import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import jQuery from 'jquery';
import moment from 'moment';
//import * as RM from 'ramda';


import Utilities from '../helpers/Utilities';
//import PagesAuthentication from '../helpers/PagesAuthentication';
import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import { blockWordsAndEmailsValidation, checkCompanyEmailExistance, checkCompanyMobileExistance, } from './../helpers/VerificationUtilities';

import FieldInput from '../components/FieldInput';
//import PasswordInput from '../components/PasswordInput';
import SignupHeader from '../layout/SignupHeader';
import MobileSelection from '../components/MobileSelection';
import CompanyListingSelection from '../components/common/CompanyListingSelection';
import { getListCompanyByMobile } from '../helpers/QueryCompanyUtilities';
import SessionUtilities from '../helpers/SessionUtilities';
import { ComanySocialLinksManage } from '../components/common/CompanySocialLinks';
import { CicleLoader } from '../components/common/Loaders';


const CompanyInfo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    //const errorMsg = process.env.REACT_APP_message_error_process;
    const limitCharacters = Utilities.companyAboutWordsLimit;;
    const defaultLocation = Utilities.defaultLocation();

    let defaultFormValues = {
        company_name: '',
        company_display_name: '',
        company_email: '',
        company_website: '',
        category_name: '',

        company_phone: '',
        company_phone_code: defaultLocation.country_id,
        company_landline: '',
        company_landline_code: defaultLocation.country_id,

        company_phone_checked: 0,
        company_landline_checked: 0,

        referral_mobile_number: '',
        referral_mobile_number_code: defaultLocation.country_id,

        company_info: '',
        company_social: '',
        company_app: '',
        company_referred: ''
    }

    let finalInfoValues = { company_about: '' }, defaultInfoValues = { company_about: '' };
    let finalSocialValues = { ...Utilities.socialLinkValues };
    let finalAppValues = { apple: '', google: '', huawei: '' };

    const [showSection, setShowSection] = useState('');

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [formInfoValues, setFormInfoValues] = useState(defaultInfoValues);
    const [formFinalInfoValues, setFormFinalInfoValues] = useState(finalInfoValues);

    const [formSocialValues, setFormSocialValues] = useState(finalSocialValues);
    const [formCompanyAppValues, setFormCompanyAppValues] = useState(finalAppValues);

    const [showLoaderReferral, setShowLoaderReferral] = useState(false);
    const [referralCompanyList, setReferralCompanyList] = useState([]);
    const [referralCompanySelected, setReferralCompanySelected] = useState({});


    const [countryList, setCountryList] = useState([]);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if (fieldName === 'company_name') {
            formValuesTemp = { ...formValuesTemp, ...{ company_name: value, company_display_name: value } };
        } else if (fieldName === 'company_display_name') {
            formValuesTemp = { ...formValuesTemp, ...{ company_display_name: value } };
        } else if (fieldName === 'company_email') {
            formValuesTemp = { ...formValuesTemp, ...{ company_email: value } };

        } else if (fieldName === 'category_name') {
            formValuesTemp = { ...formValuesTemp, ...{ category_name: value } };
        } else if (fieldName === 'company_website') {
            formValuesTemp = { ...formValuesTemp, ...{ company_website: value } };

        } else if (fieldName === 'company_landline') {

            let company_landline = value.replace(' ', '');
            if (!Utilities.isEmpty(company_landline) && !Utilities.contactNumberValidate(company_landline, 1).status) {
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_landline: parseInt(company_landline) || '' } };

        } else if (fieldName === 'company_landline_code') {
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: value.toString().trim() } };

        } else if (fieldName === 'company_phone') {

            let company_phone = value.replace(' ', '');
            if (!Utilities.contactNumberValidate(company_phone, 1).status) {
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_phone: parseInt(company_phone) || '' } };

        } else if (fieldName === 'company_phone_code') {
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: value.toString().trim() } };

        } else if (fieldName === 'company_phone_checked') {
            value = parseInt(formValuesTemp.company_phone_checked) === 1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: value } };
        } else if (fieldName === 'company_landline_checked') {

            value = parseInt(formValuesTemp.company_landline_checked) === 1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: value } };

        } else if (fieldName === 'company_info') {
            formValuesTemp = { ...formValuesTemp, ...{ company_info: value } };

        } else if (fieldName === 'company_social') {
            formValuesTemp = { ...formValuesTemp, ...{ company_social: value } };

        } else if (fieldName === 'referral_mobile_number') {
            formValuesTemp = { ...formValuesTemp, referral_mobile_number: parseInt(value) || '' };
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'] && value === 1) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileActivationWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            });
        }
    }

    const confirmContactNumber = (fieldName = '', value = 0) => {

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked']) {
            let formValuesTemp = formValues;

            if (fieldName === 'company_phone_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: parseInt(value) } };

            } else if (fieldName === 'company_landline_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: parseInt(value) } };
            }
            setFormValues(formValuesTemp);
        }
    }


    const changeCountryCodeReferral = (passValues, inputName = '') => {
        let formValuesTemp = formValues;
        if (inputName === 'referral_mobile_number') {
            formValuesTemp = { ...formValuesTemp, referral_mobile_number_code: passValues.id };
        }
        setFormValues(formValuesTemp);
    }

    const submitInformation = async () => {

        jQuery('#form-company-info .error').removeClass('error');
        dispatch(showLoader(0));

        let errorCounter = 0, responseChecker = false;
        const optionInputsArray = ['company_info', 'company_social', 'company_website', 'company_landline', 'company_landline_code', 'company_referred', 'company_app', 'referral_mobile_number'];

        Object.entries(formValues).map(([key, value]) => {

            if (Utilities.isEmpty(value) && !optionInputsArray.includes(key.toString())) {
                //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(key);
                errorCounter++;
                console.log(key);
            }

            if (key === 'company_email') {
                if (!Utilities.isEmail(value)) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            } else if (['company_name', 'company_display_name'].includes(key)) {
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if (responseChecker === false) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });


        let contactErrorCounter = 0;
        if (!Utilities.isEmpty(formValues.company_landline) && !Utilities.contactNumberValidate(formValues.company_landline)) {
            //jQuery('[name="company_landline"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_landline`);
            contactErrorCounter++;
        }

        if (!Utilities.contactNumberValidate(formValues.company_phone)) {
            //jQuery('[name="company_phone"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_phone`);
            contactErrorCounter++;
        }


        if (contactErrorCounter > 0) { return false; }

        if (errorCounter > 0) { return false; }


        const utcTime = Utilities.currentUTCTime();
        const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ create_date: curretTime, update_date: curretTime } };


        if (Object.keys(formValuesTemp).length > 0) {

            // RESTRICTED WORDS VALIDATION
            // TYPES : 1=>email, 2=>name, 3=>keywords
            let wordsRequestArray = [];
            //wordsRequestArray.push(formValues.company_email);
            wordsRequestArray.push(formValues.company_name);
            wordsRequestArray.push(formValues.company_display_name);

            dispatch(showLoader(1));
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('1,2', wordsRequestArray);
            dispatch(showLoader(0));

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
                return false;
            }

            // END RESTRICTED WORDS VALIDATION


            // Checking email if exist in database
            dispatch(showLoader(1));
            const responseCompanyEmailCheckingDB = await checkCompanyEmailExistance(formValues.company_email);
            dispatch(showLoader(0));

            if (Object.keys(responseCompanyEmailCheckingDB).length > 0) {
                if (parseInt(responseCompanyEmailCheckingDB.exist) === 1) {
                    Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist);
                    return false;
                }
            }


            // Checking mobile number if exist in database
            /* dispatch( showLoader(1) );
            const responseMobileCheckingDB = await checkCompanyMobileExistance( formValues.company_phone_code, formValues.company_phone );
            dispatch( showLoader(0) );

            if ( Object.keys(responseMobileCheckingDB).length>0 ){
                if ( parseInt(responseMobileCheckingDB.exist)===1 ){
                    Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist )
                    return false;
                }
            } */

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo,
                JSON.stringify(formValuesTemp)
            );

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_about,
                JSON.stringify(formFinalInfoValues)
            );

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_social,
                JSON.stringify(formSocialValues)
            );


            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_applink,
                JSON.stringify(formCompanyAppValues)
            );


            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_referral,
                JSON.stringify(referralCompanySelected)
            );


            history.push(`/${UrlSlugs.signup_companyKeywords}`);

        }
    }


    const changeCountryCodeLandline = (passValues, fieldName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = (passValues, fieldName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }


    const getCountries = async () => {
        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    setCountryList(response.data);
                }
            })
            .catch((err) => { });
    }


    const handleSectionNavigation = (sectionName = '') => {
        if (!Utilities.isEmpty(sectionName)) {
            setShowSection(sectionName);
        }

    }

    const submitCompanySocial = (socialLinksValues = {}) => {

        setFormSocialValues({ ...socialLinksValues });
        setShowSection('');

        let formValuesTemp = { ...formValues, company_social: '' };
        let concatSocialText = Object.values(socialLinksValues).filter(val => val !== '').join('|');
        concatSocialText = concatSocialText.trim();

        if (!Utilities.isEmpty(concatSocialText)) {
            formValuesTemp = { ...formValues, company_social: 'view' };
        }
        setFormValues(formValuesTemp);

    }


    const submitCompanyAppLinks = () => {

        jQuery('.company-app-links > li').removeClass('error');
        /* let validAppCounter = 0, errorAppCounter = 0;
        Object.entries(formCompanyAppValues).map(([key, val]) => {
            if (!Utilities.isEmpty(val)) {
                if (!Utilities.isValidURL(val)) {
                    jQuery(`input#company_app_${key}`).closest('li').addClass('error');
                    errorAppCounter++;
                } else {
                    validAppCounter++;
                }

            }
        });


        if (errorAppCounter > 0) {
            return false;
        } */

        setShowSection('');

        //console.log(formCompanyAppValues, validAppCounter);
        let formValuesTemp = { ...formValues, company_app: validAppCounter > 0 ? 'view' : '' };
        setFormValues(formValuesTemp);

    }

    const submitCompanyAbout = () => {

        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }

        setShowSection('');
        setFormFinalInfoValues(formInfoValues);

        let formValuesTemp = { ...formValues, company_info: '' };
        if (!Utilities.isEmpty(formInfoValues.company_about)) {
            formValuesTemp = { ...formValues, company_info: 'click here to view' };
        }
        setFormValues(formValuesTemp);
    }

    const headerHtml = <SignupHeader title="Company Information" classWrapAttr="text-center pb-4" />

    const headerSocialHtml = <SignupHeader title="social media links" classWrapAttr="text-center pb-4" />

    const headerCompanyAppHtml = <SignupHeader title="company app link" classWrapAttr="text-center pb-4" />

    const headerCompanyReferredHtml = <SignupHeader title="referral company" classWrapAttr="text-center pb-4" />

    const handleSelectedReferral = (profile = {}) => {
        setReferralCompanySelected(profile);

        let formValuesTemp = { ...formValues, company_referred: Object.keys(profile).length > 0 ? profile.name ?? '' : '' };
        setFormValues(formValuesTemp);


        //if ( Object)
        setShowSection('');
    }


    const htmlContent = () => {

        if (showSection === 'company-social') {
            return <section className="w-100  fs-18">
                <div className="container position-relative px-0">
                    {headerSocialHtml}

                    <ComanySocialLinksManage submitCompanySocial={submitCompanySocial} socialLinksValue={formSocialValues} showAddButton={true} />

                </div>
            </section>


        } else if (showSection === 'company-app-link') {
            return <section className="w-100  fs-18">
                <div className="container position-relative">
                    {headerCompanyAppHtml}

                    <div className='mt-n4'>
                        <div className="btn-icon-option btn-outline-arrow-left-black back-option" onClick={() => setShowSection('')}></div>
                        <ul className="company-social-input  company-app-links px-5">
                            <li className="app-apple"><input type="text" id="company_app_apple" placeholder="link" value={formCompanyAppValues.apple} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ apple: e.target.value } })} /></li>
                            <li className="app-google"><input type="text" id="company_app_google" placeholder="link" value={formCompanyAppValues.google} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ google: e.target.value } })} /></li>
                            <li className="app-huawei"><input type="text" id="company_app_huawei" placeholder="link" value={formCompanyAppValues.huawei} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ huawei: e.target.value } })} /></li>
                        </ul>

                        <div className="mt-5 pt-4">
                            <button type="button" className="btn-theme-black col-md-4 mx-auto" onClick={() => submitCompanyAppLinks()}>submit</button>
                        </div>
                    </div>

                </div>
            </section>

        } else if (showSection === 'company-referred') {
            return <section className="w-100  fs-18">
                <div className="container position-relative">
                    {headerCompanyReferredHtml}

                    <div className='mt-n4  form-theme'>
                        <div className='font-gotham-book fs-16 fs-md-16 color-theme-green text-center pt-2'>please input telephone number of referral company</div>
                        <div className="btn-icon-option btn-outline-arrow-left-black back-option" onClick={() => setShowSection('')}></div>

                        <div className='mt-3 col-md-10 mx-auto'>
                            <MobileSelection alignment={`center`} showPlaceholder={false} inputValue={formValues.referral_mobile_number} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.referral_mobile_number_code} inputListingId="list-country-current" inputName="referral_mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCodeReferral} />
                            {
                                showLoaderReferral ?
                                    <CicleLoader />
                                    :
                                    <CompanyListingSelection list={referralCompanyList} handleSelectedReferral={handleSelectedReferral} />
                            }


                        </div>



                    </div>

                </div>
            </section>

        } else if (showSection === 'company-info') {
            return <section className="w-100  fs-18">
                <div className="container position-relative">

                    {headerHtml}

                    <div className="form-theme  pt-4">
                        <div className="btn-icon-option btn-outline-arrow-left-black back-option ml-n5 ml-md-0" onClick={() => setShowSection('')}></div>
                        {/* <div className="btn-icon-option btn-outline-arrow-left-black" style={{ top: '-110px'}} onClick={ () => setShowSection('') }></div> */}

                        <div id="form-company-info">

                            <div className='text-center mt-n4 pb-4'>about your company</div>

                            <div className="form-group border-0">
                                <textarea id="company_about" name="company_about" className="form-control border-radius bg-gray-important p-4" rows="10" autoFocus placeholder=" " value={formInfoValues.company_about} onChange={(e) => setFormInfoValues({ ...formInfoValues, ...{ company_about: e.target.value } })}></textarea>
                                {/* <label className="form-label">about your business</label> */}
                            </div>

                            <div className="d-flex align-items-center justify-content-between color-theme-green fs-13-important  mt-n2">
                                <div className="cursor-pointer" onClick={() => setFormInfoValues({ ...formInfoValues, company_about: '' })}>clear</div>
                                <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                            </div>

                            <div className="mt-5">
                                <button type="button" className="btn-theme-black col-md-4 mx-auto" onClick={() => submitCompanyAbout()}>submit</button>
                            </div>

                        </div>
                    </div>

                </div>

            </section>

        } else {
            return <>
                <section className="w-100  fs-18">
                    <div className="container py-md-5">

                        {headerHtml}

                        <form id="form-company-info" className="form-theme pt-4" autoComplete="off" method="POST">

                            <input type="hidden" id="input_company_phone_code" name="company_phone_code" readOnly value={formValues.company_phone_code} onChange={(e) => pushValue(e, 'company_phone_code')} ></input>
                            <input type="hidden" id="input_company_landline_code" name="company_landline_code" readOnly value={formValues.company_landline_code} onChange={(e) => pushValue(e, 'company_landline_code')} ></input>


                            <div className='row'>
                                <div className='col-md-6'>
                                    <FieldInput inputName="company_name" inputLabel="Company Name" inputValue={formValues.company_name} pushValue={pushValue} checkerType="general-companyname" />
                                </div>
                                <div className='col-md-6'>
                                    <FieldInput inputName="company_display_name" inputLabel="internal display name" inputValue={formValues.company_display_name} pushValue={pushValue} checkerType="general-companyname" />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group ">
                                        <input type="email" name="company_email" className="form-control" placeholder=" " autoComplete="new-password" value={formValues.company_email} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)} onChange={(e) => pushValue(e, 'company_email')} ></input>
                                        <label className="form-label">Email</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group optional">
                                        <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={formValues.company_website} onChange={(e) => pushValue(e, 'company_website')} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)}  ></input>
                                        <label className="form-label">Company Website</label>
                                        <div className="optional-label">Optional</div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group optional optional-category">
                                        <input type="text" name="category_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.category_name} onChange={(e) => pushValue(e, 'category_name')} onKeyDown={(e) => e.key === 'Tab' && handleSectionNavigation('company-info')}  ></input>
                                        <label className="form-label">Business Type</label>
                                        <div className="optional-label">e.g. airline, restaurant, etc.</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group optional content-disabled cursor-pointer" onClick={() => handleSectionNavigation('company-info')}>
                                        <input type="text" name="company_info" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_info} onChange={(e) => pushValue(e, 'company_info')}></input>
                                        <label className="form-label">Company Info</label>
                                        <div className="optional-label">Optional</div>
                                    </div>
                                </div>
                            </div>


                            <div className='row'>

                                <div className='col-md-6'>
                                    <MobileSelection inputValue={formValues.company_landline} countryListProvided={countryList} inputLabel="Landline Number" countryCodeProvided={formValues.company_landline_code} inputListingId="landline-input-list" inputName="company_landline" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeLandline} extraClasses={`has-off-on pr-0`}>
                                        <input type="checkbox" className="checkbox-onoff" value={formValues.company_landline_checked} checked={formValues.company_landline_checked === 1} onChange={(e) => pushValue(e, 'company_landline_checked')} title={TitlesLabels.general.chooseNumberRefectOnBusiness}></input>
                                    </MobileSelection>
                                </div>

                                <div className='col-md-6'>
                                    <MobileSelection inputValue={formValues.company_phone} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.company_phone_code} inputListingId="mobile-input-list" inputName="company_phone" pushValue={pushValue} placeholderExtra={`(mandatory)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on pr-0`}>
                                        <input type="checkbox" className="checkbox-onoff" value={formValues.company_phone_checked} checked={formValues.company_phone_checked === 1} onChange={(e) => pushValue(e, 'company_phone_checked')} title={TitlesLabels.general.chooseNumberRefectOnBusiness}></input>
                                    </MobileSelection>

                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group optional content-disabled cursor-pointer" onClick={() => handleSectionNavigation('company-social')}>
                                        <input type="text" name="company_social" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_social} onChange={(e) => pushValue(e, 'company_social')}></input>
                                        <label className="form-label">social media links</label>
                                        <div className="optional-label">Optional</div>
                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div className="form-group optional content-disabled cursor-pointer" onClick={() => handleSectionNavigation('company-app-link')}>
                                        <input type="text" name="company_app_link" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_app} onChange={(e) => pushValue(e, 'company_app')}></input>
                                        <label className="form-label">company app link</label>
                                        <div className="optional-label">Optional</div>
                                    </div>

                                </div>

                            </div>

                            {/* <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group optional content-disabled cursor-pointer" onClick={ () =>  handleSectionNavigation('company-referred') }>
                                            <input type="text" name="company_referred" className="form-control" placeholder=" " autoComplete="off" readOnly value={ formValues.company_referred }  onChange={ (e) => pushValue( e, 'company_referred') }></input>
                                            <label className="form-label">referred by</label>
                                            <div className="optional-label">Optional</div>
                                        </div>
                                        
                                    </div>

                                </div> */}


                        </form>

                    </div>


                </section>

                <div className='signup-button position-absolute w-100 bottom-0 start-0 mb-3 px-3 px-md-0'>

                    <button type="button" className="btn-theme-black col-md-4 mx-auto " onClick={() => submitInformation()} >next</button>
                </div>

            </>

        }
    }


    const searchCompanyByMobile = async () => {

        setShowLoaderReferral(true);
        const companyResponse = await getListCompanyByMobile(formValues.referral_mobile_number_code.toString(), formValues.referral_mobile_number.toString());
        setShowLoaderReferral(false);
        setReferralCompanyList(companyResponse);

    }


    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.companyInformation));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        //PagesAuthentication.getUserLoggedin();
        getCountries();



        // REMOVE WELCOME MESSAGE
        SessionUtilities.removeSignupWelcomeMessage();

        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, []);


    useEffect(() => {

        if (!Utilities.isEmpty(formValues.referral_mobile_number) && !Utilities.isEmpty(formValues.referral_mobile_number_code)) {

            // if valid mobile number
            if (Utilities.contactNumberValidate(formValues.referral_mobile_number, 1).status) {
                const delayDebounceFn = setTimeout(() => {

                    searchCompanyByMobile();

                }, 1000);

                return () => {
                    clearTimeout(delayDebounceFn);
                };
            }

        }

    }, [formValues.referral_mobile_number, formValues.referral_mobile_number_code]);


    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center  text-left font-gotham-book'>
                {htmlContent()}

            </div>


            {
                !['company-social', 'company-info'].includes(showSection) &&
                <div className='pb-5'>
                    <div className="text-lowercase cursor-pointer color-theme-green fs-18" onClick={() => history.goBack()}>{TitlesLabels.general.exitWithoutSaving}</div>
                </div>
            }
        </div>
    )
}

export default CompanyInfo;