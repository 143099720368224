import axios from 'axios';
import ApiUrls from './ApiUrls';
import PagesAuthentication from './PagesAuthentication';


class QueryMasterSearchUtlities {


    getCompanyExtraDetail = async (companyId = 0, types = '') => {
        let response = {};
        companyId = parseInt(companyId ?? 0);

        if (companyId > 0 && types != '') {
            const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

            //  'member-external-count,department-general,department-feedback,department-count,menu-price-detail,social-accounts,following_status,company-groups-count,app-links,gotolinks-count'
            const passVal = {
                company_id: companyId,
                account_id: profileLoggedin.account_id,
                search_type: 0,
                types: types
            };
            try {
                const detailRes = await axios.post(ApiUrls.companyOthers, passVal);
                if (detailRes.status === 200) {
                    response = detailRes.data.menu_price_detail ?? {};
                }
            } catch (e) {

            }

        }

        return response;
    }

    getNewRegisteredCounter = async (passValue = {}) => {
        let response = {};
        if (Object.entries(passValue).length > 0) {
            try {
                const urlRequest = ApiUrls.getTodayCompanyToday;

                //console.log('getNewRegisteredCounter');
                //console.log(urlRequest);
                //console.log(passValue);

                //console.log(urlRequest, passValue);
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data;

            } catch (e) {

            }
        }

        return response;
    }

    getAllProfileResultsByCaterogyName = async (passValue = {}) => {
        let response = {};
        if (Object.entries(passValue).length > 0) {
            try {
                const urlRequest = ApiUrls.profileSearchCategoryByCategoryName;
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data;

            } catch (e) {

            }
        }

        return response;
    }

    getProfileCounterByCategoryName = async (category_name = '') => {

        let response = {};
        if (category_name !== '') {
            try {
                const passValue = { keywords: category_name.toString() }
                const urlRequest = ApiUrls.profileSearchCategoryProfileIdsByCategoryName;
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data;

            } catch (e) {

            }
        }

        return response;
    }

    getProfileCounterByCategoryId = async (category_id = 0) => {

        let response = {};
        if (category_id > 0) {
            try {
                const passValue = { category_id: category_id.toString() }
                const urlRequest = ApiUrls.profileSearchCategoryProfileIdsByCategoryId;
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data;

            } catch (e) {

            }
        }

        return response;
    }

    getProfileDetail = async (account_id = 0) => {
        account_id = account_id.toString();

        let profileDetail = {};
        if (account_id !== '') {
            try {
                const passValue = { account_ids: account_id.toString() }
                const urlRequest = ApiUrls.mainCompanyKeywordsGetProfileByIds;
                const profileDetailRes = await axios.post(urlRequest, passValue);
                profileDetail = profileDetailRes.data;

            } catch (e) {
                profileDetail = {};
            }

        }
        return profileDetail;
    }

    followingStatusUpdate = async (passValue = {}) => {

        let responseDetail = {};
        if (Object.entries(passValue).length > 0) {
            try {
                const urlRequest = ApiUrls.followingUpdateStatus;
                const responseDetailDb = await axios.post(urlRequest, passValue);
                responseDetail = responseDetailDb.data;

            } catch (e) {

            }

        }
        return responseDetail;
    }


    contactRequestTeamDetail = async (passValue = {}) => {

        let responseDetail = {};
        if (Object.entries(passValue).length > 0) {
            try {
                console.log('contactRequestTeamDetail');
                console.log(ApiUrls.bizchatContactRequestTeam);
                console.log(passValue);

                const urlRequest = ApiUrls.bizchatContactRequestTeam;
                const responseDetailDb = await axios.post(urlRequest, passValue);
                responseDetail = responseDetailDb.data;
                console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }


    requestAccessTeamCommunicationDetails = async (passValue = {}) => {
        let responseDetail = {};
        if (Object.entries(passValue).length > 0) {
            try {

                /* console.log('requestAccessTeamCommunicationDetails');
                console.log(ApiUrls.searchTeamProfileResult);
                console.log(passValue); */

                const urlRequest = ApiUrls.searchTeamProfileResult;
                const responseDetailDb = await axios.post(urlRequest, passValue);
                responseDetail = responseDetailDb.data;
                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }

    requestAccessTeamCommunication = async (passValue = {}) => {

        let responseDetail = {};
        if (Object.entries(passValue).length > 0) {
            try {

                console.log('requestAccessTeamCommunication');
                console.log(ApiUrls.bizchatRequestAccessTeamCommunication);
                console.log(passValue);

                const urlRequest = ApiUrls.bizchatRequestAccessTeamCommunication;
                const responseDetailDb = await axios.post(urlRequest, passValue);
                responseDetail = responseDetailDb.data;
                console.log(responseDetail);
            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }


    // type = 1 => local, 2=>city , 4 => nationals, 5 => internationals
    formatAdressByLocationType = (loctype = 1, profile = {}) => {
        let returnStr = '', locationArr = [];
        loctype = parseInt(loctype);


        if (loctype > 0 && Object.keys(profile).length > 0) {

            const addressVisibilityStatus = parseInt(profile.address_visibility_status ?? 0);

            let street_name = profile.hasOwnProperty('street_name') ? profile.street_name : '';
            let area_name = profile.hasOwnProperty('area_name') ? profile.area_name : '';
            let city_name = profile.hasOwnProperty('city_name') ? profile.city_name : '';
            //let state_name = profile.hasOwnProperty('state_name') ? profile.state_name : '';
            //let country_name = profile.hasOwnProperty('country_name') ? profile.country_name : '';

            locationArr.push(area_name);

            if (addressVisibilityStatus === 1) {
                locationArr.push(street_name);
            } else {
                locationArr.push(city_name);
            }


            if (loctype === 1) {
                //locationArr.push(area_name);
                locationArr.push(street_name);

            }/* else if ( loctype===2 ){
                locationArr.push(area_name);
                locationArr.push(city_name);
                
            }else if ( loctype===3 ){
                locationArr.push(country_name);
                locationArr.push(city_name);
            }
 */
            locationArr = locationArr.filter(function (e) { return e });

            returnStr = locationArr.join(', ');
        }

        return returnStr;
    }


}

export default new QueryMasterSearchUtlities();

