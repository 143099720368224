import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import { Dropdown } from 'react-bootstrap';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getCompanyFollowers } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import SessionUtilities from '../../../helpers/SessionUtilities';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';

const NotificationFollowers = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getCompanyFollowerList = async (profile = {}) => {

        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: profile.account_id.toString(),
            company_id: profile.company_detail.account_id.toString(),
            flag: '0'
        };

        setShowProcessing(true);
        const companyFollowersResponse = await getCompanyFollowers(passval);
        setShowProcessing(false);

        if (Object.keys(companyFollowersResponse).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(companyFollowersResponse.success) === 1) {
            setListProfiles(companyFollowersResponse.result.notification_followers_list ?? []);


        } else {
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage);
        }
    }

    const handleRedirect = (url = '') => {

        if (!Utilities.isEmpty(url)) {
            history.push(`/${url}`);
        }
    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if (parseInt(userLoginDetailTemp.account_type) !== 4) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        } else {

            // RETURN to activation page
            if (userLoginDetailTemp.hasOwnProperty('company_detail')) {
                if (parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0) === 0) {
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;
                }
            }
        }

        getCompanyFollowerList(userLoginDetailTemp)

        dispatch(pageTitle(`${TitlesLabels.navigations.followersList}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="notication-company-followers-page team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notificationManagement}`}>{TitlesLabels.navigations.notificationManagement}</Link>
                            <div className="current">{TitlesLabels.navigations.followersList}</div>

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(listProfiles).length > 0 &&
                                <div className="btn-icon btn-magnify" onClick={() => toggleSeachBox()} title={TitlesLabels.general.searchProfile}></div>
                            }

                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                                    <div className="btn-icon btn-dots-vertical" title={TitlesLabels.general.notificationOption}></div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-medium">
                                    <div onClick={() => handleRedirect(UrlSlugs.settings_notificationFollowersSuspend)} className="py-2 px-4 dropdown-item font-gotham-book fs-18 fs-md-16" role="button">suspend follower</div>
                                    <div onClick={() => handleRedirect(UrlSlugs.settings_notificationFollowersRemove)} className="py-2 px-4 dropdown-item font-gotham-book fs-18 fs-md-16" role="button">remove follower</div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content position-relative">

                <div className="content-subnav-wrap profile-search-wrap d-none">
                    <div className="container py-2 d-flex align-items-center px-5">
                        <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius">
                            <div className='form-group mb-0 border-0  w-100 '>
                                <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                            </div>
                            {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                        </div>

                    </div>
                </div>

                <div className="container pt-4 ">

                    {
                        showProcessing ?
                            <ul className="ul-global-list px-5">
                                {
                                    [...Array(6).keys()].map((key) =>
                                        <li key={key} className="global-preloader  py-2">
                                            <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                            <div>
                                                <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                                <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            :
                            <>
                                {
                                    Object.keys(listProfiles).length === 0 ?
                                        <NoRecordAvailable style="text-center text-lowercase no-record-label  mt-5 ">{Utilities.labelProfileAvailable}</NoRecordAvailable>
                                        :

                                        <ul id="listing-profiles" className={`listing-profiles  ul-global-list notranslate px-5`}>
                                            {
                                                Object.entries(listProfiles).map(([key, row]) =>
                                                    <li key={key} data-keyword={row.name} className={`${parseInt(row.status) === 2 ? 'opacity-05' : ''} cursor-auto`}>
                                                        <div className="profile-image-wrap">
                                                            {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                                            <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                        </div>
                                                        <div className='line-height-1'>
                                                            <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1" data-name={row.name}>{row.name} </div>
                                                            <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.title}</div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                }
                            </>
                    }

                </div>
            </div>
        </div>
    )
}

export default NotificationFollowers;