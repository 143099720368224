import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showLoader as showLoaderReducer, showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getNoticeList, updateCompanyFollowStatus } from '../../../helpers/FollowersNotices';
import SessionUtilities from '../../../helpers/SessionUtilities';
import Utilities from '../../../helpers/Utilities';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';


const NotificationManagement = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [selectedDetail, setSelectedDetail] = useState({});
    const [noticeListing, setNoticeListing] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);
    const [defaultSearchValue, setDefaultSearchValue] = useState('');

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
    }

    const handleClickUpdateCompanyFollowStatusConfirmation = () => {

        let followStatusMessage = TitlesLabels.confirmMessages.wishToDeactivate;

        if (parseInt(userLoginDetail.company_detail.follow_status ?? 0) === 2) {
            followStatusMessage = TitlesLabels.confirmMessages.wishToActivate;
        }

        confirmAlert({
            title: '',
            message: followStatusMessage,
            overlayClassName: 'normal-font-style warning',
            buttons: [
                { label: 'no' },
                { label: 'continue', onClick: () => handleClickUpdateCompanyFollowStatus() }
            ],
        });
    }

    const handleClickUpdateCompanyFollowStatus = async () => {
        let follow_status = 2;

        if (parseInt(userLoginDetail.company_detail.follow_status ?? 0) === 2) {
            follow_status = 1;
        }

        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: userLoginDetail.account_id.toString(),
            account_type: userLoginDetail.account_type.toString(),
            company_id: userLoginDetail.company_detail.account_id.toString(),
            //flag: follow_status.toString()
        };

        dispatch(showLoaderReducer(1));
        const responseDb = await updateCompanyFollowStatus(passval);
        dispatch(showLoaderReducer(0));

        if (Object.keys(responseDb).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(responseDb.success) === 1) {

            let userLoginDetailTemp = {
                ...userLoginDetail,
                company_detail: { ...userLoginDetail.company_detail, follow_status: follow_status.toString() }
            };

            PagesAuthentication.setLoginAccountDetail(userLoginDetailTemp);
            SessionUtilities.updateCompanyDetail(userLoginDetailTemp.company_detail);


            confirmAlert({
                title: 'success',
                message: responseDb.successMessage,
                overlayClassName: 'normal-font-style',
                buttons: [
                    { label: 'close', onClick: () => window.location.reload() }
                ],
                onClickOutside: () => window.location.reload(),
                onKeypressEscape: () => window.location.reload()
            });


        } else {
            Utilities.messagePopup('error', responseDb.errorMessage);
        }

    }

    const getCompanytNoticeList = async (profile = {}) => {
        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: profile.account_id.toString(),
            company_id: profile.company_detail.account_id.toString()
        };
        //console.log(passval);
        setShowProcessing(true);
        const noticeListResponse = await getNoticeList(passval);
        setShowProcessing(false);

        if (Object.keys(noticeListResponse).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }


        if (parseInt(noticeListResponse.success) === 1) {
            setNoticeListing(noticeListResponse.result.notification_notice_list.notification_list ?? []);
        } else {
            Utilities.messagePopup('error', noticeListResponse.errorMessage);
        }


    }

    const handleClickSelected = (detail = {}) => {
        setSelectedDetail(detail);
    }

    const listingHtml = () => {
        return <>

            {
                showProcessing &&
                <ul className="ul-global-list">
                    {
                        [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                            <li key={key} className="global-preloader  py-2">
                                <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                <div>
                                    <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                    <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className="bg-gray col-8 pt-4 border-radius-loader"></div>
                                </div>
                            </li>
                        )
                    }
                </ul>
            }

            {
                !showProcessing &&
                <>
                    {
                        Object.keys(noticeListing ?? []).length === 0 ?
                            <NoRecordAvailable>{TitlesLabels.general.noRecordAvailable}</NoRecordAvailable>
                            :
                            <ul id="listing-profiles" className="ul-global-list max-scroll cursor-pointer text-lowercase font-gotham-book fs-16 fs-md-16">
                                {
                                    Object.entries(noticeListing ?? []).map(([key, row]) =>
                                        <li key={key} onClick={() => handleClickSelected(row)} data-keyword={row.title} >
                                            <div>
                                                {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.image_path) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.image_path)  }} onClick={ () => previewProfileImage(row.image_path) }>{ Utilities.showHideInitial(row.logo, row.title) }</div> */}
                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={{ ...row, name: row.title, logo: row.image_path }} />
                                            </div>
                                            <div className='line-height-1'>
                                                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1" data-name={row.title}>{row.title}</div>
                                                <div className="profile-label  color-theme-grey"><div className=" ellipsis-1 col-md-9 px-0">{row.description}</div></div>
                                            </div>
                                            <div className="text-right color-theme-grey fs-16">{Utilities.formatDateList(row.create_date)}</div>
                                        </li>
                                    )
                                }
                            </ul>
                    }
                </>
            }
        </>
    }

    const selectedDetailHtml = () => {

        if (Object.keys(selectedDetail).length === 0) { return false; }

        return <>
            <div className="row form-theme text-lowercase pt-5">

                <div className="col-md-6">
                    <div className="global-image-preview bg-gray" style={{ backgroundImage: `url(${selectedDetail.image_path})`, height: 350 }}></div>
                </div>

                <div className='col-md-6 '>
                    <div className="form-group active">
                        <label className="form-label">title</label>
                        {/* <div className="form-control height-inherit">{selectedDetail.title}</div> */}
                        <div className="form-control height-inherit" dangerouslySetInnerHTML={{ __html: selectedDetail.title }}></div>
                    </div>

                    <div className="form-group active border-0">
                        <label className="form-label">type here</label>
                        {/* <div className="form-control height-inherit" >{selectedDetail.description}</div> */}
                        <div className="form-control height-inherit" dangerouslySetInnerHTML={{ __html: selectedDetail.description }}></div>
                    </div>
                </div>
            </div>
        </>
    }

    const handeleNewNotification = () => {
        history.push(`/${UrlSlugs.settings_notificationSendNotification}`);
    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if (parseInt(userLoginDetailTemp.account_type) !== 4) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        } else {

            // RETURN to activation page
            if (userLoginDetailTemp.hasOwnProperty('company_detail')) {
                if (parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0) === 0) {
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;
                }
            }
        }

        setUserLoginDetail(userLoginDetailTemp);
        getCompanytNoticeList(userLoginDetailTemp);

        dispatch(pageTitle(`${TitlesLabels.navigations.notificationManagement}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="notification-management dashboad-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className={`${Object.keys(selectedDetail).length === 0 ? 'current' : 'cursor-pointer'}`} onClick={() => setSelectedDetail({})}>{TitlesLabels.navigations.notificationManagement}</div>
                            {Object.keys(selectedDetail).length > 0 && <div className="current">{TitlesLabels.navigations.notificationDetail}</div>}

                        </li>
                        <li className="btn-option">
                            <Link to={`/${UrlSlugs.settings_notificationFollowers}`} className="btn-icon btn-group-people" title={TitlesLabels.general.followers}></Link>
                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                                    <div className="btn-icon btn-dots-vertical" title={TitlesLabels.general.notificationOption}></div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-medium">
                                    <div className="py-2 px-4 dropdown-item font-gotham-book fs-18 fs-md-18 " role="button" onClick={() => handleClickUpdateCompanyFollowStatusConfirmation()}>
                                        {
                                            Object.entries(userLoginDetail).length > 0 &&
                                                parseInt(userLoginDetail.company_detail.follow_status ?? 0) === 1 ? 'deactivate' : 'activate'
                                        }
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>


            <div className="main-content">
                {
                    Object.entries(userLoginDetail).length > 0 &&
                    parseInt(userLoginDetail.company_detail.follow_status ?? 0) === 1 &&
                    <div className="content-subnav-wrap">
                        <div className="container py-2 d-flex align-items-center px-5">
                            <div onClick={() => handeleNewNotification()} className="btn-new-chat  btn-new-notification btn-icon-wrap cursor-pointer fs-16 border-radius px-2" style={{ width: 230 }}>add new notification</div>

                            <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                                </div>
                                {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                            </div>
                        </div>
                    </div>
                }

                <div className="container pt-4  px-5 pb-5">

                    {Object.keys(selectedDetail).length > 0 ? selectedDetailHtml() : listingHtml()}


                </div>
            </div>
        </div>
    )
}

export default NotificationManagement;